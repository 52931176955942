import React, { Component } from "react";
import { connect } from "react-redux";
import { Table } from "react-bootstrap";

import Requests from "../../utilities/requests";
import Lang from "../language/language";
import Search from "../search/search";
import AddUser from "./addUser";
import EditUser from "./editUser";
import {PERMISSION_LABELS} from "./userPermissions";
import CustomerSelect from "../customerSelect/customerSelect";
import DefaultSpinner from "../spinner/defaultSpinner";
import SidePanel from "../sidePanel/sidePanel";

import "./users.css";

class Users extends Component {
	constructor() {
		super();

		this.state = {
			editOpen: false,
			editUser: undefined,

			filteredUsers: undefined,
			activeRequests: 0,
		};

		this.getFilteredUsers = this.getFilteredUsers.bind(this);
		this.formatRights = this.formatRights.bind(this);
		this.openEdit = this.openEdit.bind(this);
		this.closeEdit = this.closeEdit.bind(this);
	}

	componentDidUpdate(prevState) {
		if (this.props.selectedCustomerId !== prevState.selectedCustomerId) {
			this.setState({activeRequests: this.state.activeRequests + 1}, () => {
				// Requestin users
				Requests.getUsers({customerId: this.props.selectedCustomerId})
				.then((data) => {
					this.setState({activeRequests: this.state.activeRequests - 1}, () => {
						if (data.status === "success" && data.users !== undefined) {
							const ids = Object.keys(data.users);
							if (ids.length > 0) {
								let requestCount = ids.length;
								this.setState({activeRequests: this.state.activeRequests + 1}, () => {
									ids.forEach((id) => {
										// Requesting data for users
										Requests.getUserData({userId: id})
										.then((uData) => {
											requestCount--;
											if (requestCount < 1) { 
												this.setState({activeRequests: this.state.activeRequests - 1}, () => {
													if (uData.status === "success") {
														let userData = {
															customerId: uData.customerId,
															userName: uData.userName,
															rights: uData.rights,
															notifSms: uData.notifSms,
															notifDeviceToken: uData.notifDeviceToken,
															notifEmail: uData.notifEmail,
														}
														this.props.addUserData({id, data: userData});
													}
												})
											} else {
												if (uData.status === "success") {
													let userData = {
														customerId: uData.customerId,
														userName: uData.userName,
														rights: uData.rights,
														notifSms: uData.notifSms,
														notifDeviceToken: uData.notifDeviceToken,
														notifEmail: uData.notifEmail,
													}
													this.props.addUserData({id, data: userData});
												}
											}
										})
										.catch((err) => {
											console.log("Error, while getting user data.");
											console.log(err);
										})
									})	
								});
							}
							this.props.setVal("users", data.users);
						} else {
							console.log("Failed to get users.")
						}
					})
				})
				.catch((err) => {
					console.log("Error, while getting users.");
					console.log(err)
				});
			})
		}
	}
	
	componentDidMount() {
		if (this.props.selectedCustomerId) {
			this.setState({activeRequests: this.state.activeRequests + 1}, () => {
				// Requestin users
				Requests.getUsers({customerId: this.props.selectedCustomerId})
				.then((data) => {
					this.setState({activeRequests: this.state.activeRequests - 1}, () => {
						if (data.status === "success" && data.users !== undefined) {
							const ids = Object.keys(data.users);
							if (ids.length > 0) {
								let requestCount = ids.length;
								this.setState({activeRequests: this.state.activeRequests + 1}, () => {
									ids.forEach((id) => {
										// Requesting data for users
										Requests.getUserData({userId: id})
										.then((uData) => {
											requestCount--;
											if (requestCount < 1) { 
												this.setState({activeRequests: this.state.activeRequests - 1}, () => {
													if (uData.status === "success") {
														let userData = {
															customerId: uData.customerId,
															userName: uData.userName,
															rights: uData.rights,
															notifSms: uData.notifSms,
															notifDeviceToken: uData.notifDeviceToken,
															notifEmail: uData.notifEmail,
														}
														this.props.addUserData({id, data: userData});
													}
												})
											} else {
												if (uData.status === "success") {
													let userData = {
														customerId: uData.customerId,
														userName: uData.userName,
														rights: uData.rights,
														notifSms: uData.notifSms,
														notifDeviceToken: uData.notifDeviceToken,
														notifEmail: uData.notifEmail,
													}
													this.props.addUserData({id, data: userData});
												}
											}
										})
										.catch((err) => {
											console.log("Error, while getting user data.");
											console.log(err);
										})
									})	
								});
							}
							this.props.setVal("users", data.users);
						} else {
							console.log("Failed to get users.")
						}
					})
				})
				.catch((err) => {
					console.log("Error, while getting users.");
					console.log(err)
				});
			})
		}
	}

	getFilteredUsers(filteredUsers) {
		this.setState({filteredUsers});
	}

	formatRights(rights) {
		return rights.reduce((res, right, index) => {
			if (index !== 0)
				res.push(", ");
			res.push(PERMISSION_LABELS[right] ? PERMISSION_LABELS[right] : right);
			return res;
		}, []);
	}

	openEdit({id}) {
		this.setState({editOpen: true, editUser: id});
	}

	closeEdit() {
		this.setState({editOpen: false})
	}

	getItems(users, userData) {
		if (users !== undefined) {
			let ids = Object.keys(users);
			ids.sort((a, b) => {
				if (users[a].toLowerCase() < users[b].toLowerCase())
					return -1;

				if (users[a].toLowerCase() > users[b].toLowerCase())
					return 1;

				return 0;
			});

			return ids.map((id) => {
				const usersData = userData !== undefined && userData[id] !== undefined ? userData[id] : {};
				return (
					<tr key={"user_" + id} onClick={() => this.openEdit({id})}>
						<td>{users[id]}</td>
						<td>{usersData.rights ? this.formatRights(usersData.rights) : "-"}</td>
						<td>{usersData.notifSms ? usersData.notifSms : "-"}</td>
						<td>{usersData.notifEmail ? usersData.notifEmail : "-"}</td>
					</tr>
				);
			})
		}

		return null;
	}
	
	render() {
		return (
			<div className="users">
				<SidePanel>
					<CustomerSelect />
					<Search returnFiltered={this.getFilteredUsers} items={this.props.users}/>
					<AddUser disabled={this.state.editOpen}/>
				</SidePanel>
				<div className="main-panel">
					<h3 className="title"><Lang en="Users" fi="Käyttäjät" sv="Användare"/></h3>
					<EditUser isOpen={this.state.editOpen} close={this.closeEdit} userId={this.state.editUser}/>
					{ 	this.state.activeRequests > 0 ?
						<DefaultSpinner show/>
						:
						<Table className="users-table" striped bordered hover>
							<thead>
								<tr>
									<th><Lang en="E-mail" fi="Sähköposti" sv="E-post"/></th>
									<th><Lang en="Rights" fi="Oikeudet" sv="Rättigheter"/></th>
									<th><Lang en="SMS notification number" fi="SMS ilmoitus numero" sv="SMS meddelande nummer"/></th>
									<th><Lang en="Notification E-mail" fi="Ilmoitus sähköposti" sv="Meddelande E-post"/></th>
								</tr>
							</thead>
							<tbody>
								{this.getItems(this.state.filteredUsers ? this.state.filteredUsers : this.props.users, this.props.userData)}
							</tbody>
						</Table>
					}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { users, userData, customerId, selectedCustomerId } = state;
	return { users, userData, customerId, selectedCustomerId };
}

const mapDispatchToProps = (dispatch) => {
	return {
		setVal: (prop, val) => dispatch({type: "SET_VAL", prop, val}),
		addUserData: (val) => dispatch({type: "ADD_USER_DATA", val}),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);