import React, {Component} from "react";
import { connect } from "react-redux";
import {Modal, Button, Form} from "react-bootstrap";

import Lang, {getLangString} from "../language/language";
import Requests from "../../utilities/requests";
import {notificationsRef} from "../notifications";
import UserPremissions from "./userPermissions";

import "./editUser.css";

class EditUser extends Component {
	constructor() {
		super();

		this.state = {
			edits: {},

			deleteMenu: false,
			deleteUserEmail: "",
		}

		this.toggleDeleteMenu = this.toggleDeleteMenu.bind(this);
		this.submit = this.submit.bind(this);
		this.submitDelete = this.submitDelete.bind(this);
		this.permissionChange = this.permissionChange.bind(this);
		this.updateText = this.updateText.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	componentDidUpdate(prevState) {
		if (this.props.isOpen && this.props.isOpen !== prevState.isOpen) {
			if (this.props.userData && this.props.userData[this.props.userId])
				this.setState({edits: {
					rights: this.props.userData[this.props.userId].rights,
					notifSms: emptyStringIfNull(this.props.userData[this.props.userId].notifSms),
					notifDeviceToken: emptyStringIfNull(this.props.userData[this.props.userId].notifDeviceToken),
					notifEmail: emptyStringIfNull(this.props.userData[this.props.userId].notifEmail),
				}});
		}
	}

	toggleDeleteMenu() {
		this.setState({deleteMenu: !this.state.deleteMenu});
	}

	submit(e) {
		e.preventDefault();
		this.props.close();
		const requestValues = {
			userId: this.props.userId,
			rights: this.state.edits.rights,
			notifSms: nullIfEmpty(this.state.edits.notifSms),
			notifDeviceToken: null,
			notifEmail: nullIfEmpty(this.state.edits.notifEmail),
		};

		Requests.updateUser(requestValues)
			.then((data) => {
				if (data && data.status === "success") {
					if (notificationsRef && notificationsRef.current)
						notificationsRef.current.addNotification({
							message: <Lang en="User data updated" fi="Käyttäjän tiedot päivitetty" sv="Användarens information updaterades"/>,
							level: "success"
						})
					const id = this.props.userId;
					// Requesting data for user
					Requests.getUserData({userId: id})
						.then((uData) => {
							if (uData.status === "success") {
								let userData = {
									customerId: uData.customerId,
									userName: uData.userName,
									rights: uData.rights,
									notifSms: uData.notifSms,
									notifDeviceToken: uData.notifDeviceToken,
									notifEmail: uData.notifEmail,
								}
								this.props.addUserData({id, data: userData});
							}
						})
						.catch((err) => {
							console.log("Error, while getting user data.");
							console.log(err);
						})
				} else { 
					
					if (notificationsRef && notificationsRef.current)
						notificationsRef.current.addNotification({
							message: <Lang en="Failed to update user infromation" fi="Käyttäjän tietojen päivitys epäonnisui" sv="Uppdateringen av användarens information misslyckades"/>,
							level: "warning"
						})
					else
						console.log("Failed to update user.");
				}
			});
		this.setState({edits: {}});
	}

	submitDelete(e) {
		e.preventDefault();
		if (this.props.users && this.props.users[this.props.userId] && this.state.deleteUserEmail === this.props.users[this.props.userId]) {
			Requests.deleteUser({userId: this.props.userId})
				.then((data) => {
					if (data.status === "success") {
						if (notificationsRef && notificationsRef.current)
							notificationsRef.current.addNotification({
								message: <Lang en="User deleted." fi="Käyttäjä poistettu." sv="Användaren borttagen."/>,
								level: "success"
							})
					} else {
						if (notificationsRef && notificationsRef.current)
							notificationsRef.current.addNotification({
								message: <Lang en="Failed to delete user." fi="Käyttäjän poisto epäonnistui." sv="Kunde inte ta bort användaren."/>,
								level: "warning"
							})
					}
				})
			this.props.close();
		} else {
			if (notificationsRef && notificationsRef.current)
					notificationsRef.current.addNotification({
						message: <Lang en="Could not delete user. Missing data." fi="Käyttäjää ei voitu poistaa. Puutteelliset tiedot." sv="Användaren kunde inte tas bort. Information fattas."/>,
						level: "warning"
					})
		}
	}

	permissionChange({rights}) {
		let edits = JSON.parse(JSON.stringify(this.state.edits));
		edits.rights = rights;
		this.setState({edits});
	}

	getUserRights(userId, userData) {
		if (userId !== undefined && userData) {
			if (userData[userId] && userData[userId].rights) {
				return userData[userId].rights;
			}
		}
		return [];
	}

	updateText(key, string) {
		let edits = JSON.parse(JSON.stringify(this.state.edits));
		edits[key] = string;
		this.setState({edits});
	}

	closeModal() {
		this.setState({edits: {}}, () => {
			this.props.close();
		});
	}

	render() {
		return (
			<Modal show={this.props.isOpen} onHide={this.closeModal} className="edit-modal">
				<Modal.Header closeButton>
					<Modal.Title><Lang en="Edit user" fi="Muokkaa käyttäjää" sv="Redigera användare"/></Modal.Title>
				</Modal.Header>
				<Form onSubmit={this.state.deleteMenu ? this.submitDelete : this.submit}>
					<Modal.Body>

						<div className="edit-user-title">{ this.props.users && this.props.users[this.props.userId] ? this.props.users[this.props.userId] : ""}</div>

						{	this.state.deleteMenu || Object.keys(this.state.edits).length < 1 ? null :
							<div className="edits">
								<Form.Label><Lang fi="SMS ilmoitus numero" en="SMS notification number" sv="SMS meddelande nummer"/></Form.Label>
								<Form.Control
									type="tel"
									placeholder={getLangString(this.props.lang, {fi: "SMS ilmoitus numero", en: "SMS notification number", sv: "SMS meddelande nummer"})}
									value={this.state.edits.notifSms}
									onChange={(e) => this.updateText("notifSms", e.target.value)}
								/>

								<Form.Label className="edit-label notification-email"><Lang fi="Ilmoitus Sähköposti" en="NotificationE-mail" sv="Meddelande E-post"/></Form.Label>
								<Form.Control 
									type="email"
									placeholder={getLangString(this.props.lang, {fi: "Ilmoitus Sähköposti", en: "NotificationE-mail", sv: "Meddelande E-post"})}
									value={this.state.edits.notifEmail}
									onChange={(e) => this.updateText("notifEmail", e.target.value)}
								/>

								<UserPremissions onChange={this.permissionChange} editRights={this.state.edits.rights}/>
							</div>
						}

						{	this.state.deleteMenu ?
							<div className="delete-menu">
								<Form.Label><Lang fi="Käyttäjän sähköposti" en="Users e-mail" sv="Andändarens e-post"/></Form.Label>
								<Form.Control 
									type="email"
									placeholder={getLangString(this.props.lang, {fi: "Käyttäjän sähköposti", en: "Users e-mail", sv: "Andändarens e-post"})}
									value={this.state.deleteUserEmail}
									onChange={(e) => this.setState({deleteUserEmail: e.target.value})}
								/>
							</div>
							: null
						}
					</Modal.Body>
					<Modal.Footer>
						<Button className="mr-auto" variant={this.state.deleteMenu ? "danger" : "outline-danger"} onClick={this.toggleDeleteMenu}><Lang fi="Poista" en="Delete" sv="Ta bort"/></Button>
						<Button variant="secondary" onClick={this.closeModal}><Lang en="Cancel" fi="Peruuta" sv="Ångra" /></Button>
						<Button type="submit" variant="primary">OK</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		);
	}
}

function mapStateToProps(state) {
	const { lang, users, userData, selectedCustomerId } = state;
	return { lang, users, userData, selectedCustomerId };
}

const mapDispatchToProps = (dispatch) => {
	return {
		setVal: (prop, val) => dispatch({type: "SET_VAL", prop, val}),
		addUserData: (val) => dispatch({type: "ADD_USER_DATA", val}),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);


const nullIfEmpty = (input) => {
	if (input === undefined || input === "")
		return null;
	return input;
}

const emptyStringIfNull = (input) => {
	if (input === undefined || input === null)
		return "";
	return input;
}