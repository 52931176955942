import React, { Component } from "react";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import moment from "moment";

import isDefined from "../../utilities/isDefined";
import {getStringFromLang} from "../language/language";
import {QUANTITY_TRANSLATIONS} from "../language/translations";

import "./chartBox.css";

import * as Plotly from 'plotly.js/dist/plotly';
import * as dictFin from 'plotly.js/lib/locales/fi.js';
import * as dictSwe from 'plotly.js/lib/locales/sv.js';
import createPlotlyComponent from 'react-plotly.js/factory';

const Plot = createPlotlyComponent(Plotly);
Plotly.register(dictFin);
Plotly.register(dictSwe);

class ChartBox extends Component {
	constructor() {
		super();

		this.plotRef = React.createRef();
	}

	getUnit({devId, measIndex, deviceData}) {
		if (isDefined({devId, measIndex, deviceData}) && deviceData[devId] && deviceData[devId].units_short && deviceData[devId].units_short[measIndex]) {
			return deviceData[devId].units_short[measIndex] === "C" ? "°C" : deviceData[devId].units_short[measIndex];
		}
		return "";
	}

	getTitle({devId, measIndex, deviceData, lang}) {
		if (isDefined({devId, measIndex, deviceData}) && deviceData[devId] && deviceData[devId].quantities && deviceData[devId].quantities[measIndex]) {
			return QUANTITY_TRANSLATIONS[deviceData[devId].quantities[measIndex]] ? getStringFromLang(QUANTITY_TRANSLATIONS[deviceData[devId].quantities[measIndex]], lang) : deviceData[devId].quantities[measIndex];
		}
		return "";
	}

	getDatasets({devId, measIndex, measurements}) {
		let values = {x: [], y: []};
		if (isDefined({devId, measIndex, measurements}) && measurements[devId]) {
			values.x = measurements[devId].t.map(t => {
				return new Date(t * 1000);
			});
			values.y = measurements[devId].vals.map(x => {
				return x[measIndex];
			});
		}

		const dataset = Object.assign({
			visible: true,
			type: 'scatter',
			mode: 'lines',
			fill: 'tozeroy',
			line: {
				width: 2,
				shape: "spline",
			},
		}, values);

		return [dataset];
	}

	render() {
		if (this.props.deviceId && this.props.measIndex) {
			const dataset = this.getDatasets({devId: this.props.deviceId, measIndex: this.props.measIndex, measurements: this.props.dailyView_measurements});
			return (
				<div className="chart-box">
					<Plot
						ref={this.plotRef}
						useResizeHandler={true}
						data={dataset}
						layout={{
							height: undefined,
							width: undefined,
							margin: {
								t: 50,
							},
							title: {
								text: this.getTitle({devId: this.props.deviceId, measIndex: this.props.measIndex, deviceData: this.props.dailyView_deviceData, lang: this.props.lang}),
								yref: "paper",
							},
							yaxis: {
								hoverformat: ".2f",
								title: {
									text: this.getUnit({devId: this.props.deviceId, measIndex: this.props.measIndex, deviceData: this.props.dailyView_deviceData})
								}
							},
							hovermode: "closest",
							legend: false,
							autosize: true
						}}
						config={{
							displayModeBar: isMobile ? false : undefined,
							modeBarButtonsToRemove: ["toImage", "sendDataToCloud"],
							modeBarButtonsToAdd: [[{
								name: 'Image',
								icon: Plotly.Icons.camera,
								click: function (gd) {
									Plotly.downloadImage(gd, {
										filename: 'farmiaisti_' + moment().format("YYYYMMDD_HHmmss"),
										format: 'png',
										width: gd._fullLayout.width,
										height: gd._fullLayout.height
									})
								}
							}]],
							locale: this.props.lang,
							displaylogo: false,
						}}
						style={{width: "100%", height: "100%"}}
					/>
				</div>
			);
		}
		return null;
	}
}

function mapStateToProps(state) {
	const { dailyView_devices, dailyView_deviceData, dailyView_measurements, lang } = state;
	return { dailyView_devices, dailyView_deviceData, dailyView_measurements, lang };
}

const mapDispatchToProps = (dispatch) => {
	return {
		setVal: (prop, val) => dispatch({type: "SET_VAL", prop, val}),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartBox);
