import React, { Component } from "react";
import { connect } from "react-redux";
import { Table } from "react-bootstrap";

import Lang from "../language/language";
import {notificationsRef} from "../notifications";
import Requests from "../../utilities/requests";
import CustomerSelect from "../customerSelect/customerSelect";
import Search from "../search/search";
import AddDevice from "./addDevice";
import EditDevice from "./editDevice";
import DefaultSpinner from "../spinner/defaultSpinner";
import SidePanel from "../sidePanel/sidePanel";

import "./devices.css";

class Devices extends Component {
	constructor() {
		super();
		this.state = {
			devices: {},
			filteredDevices: {},

			activeRequests: 0,
		}
		
		this.getFilteredDevices = this.getFilteredDevices.bind(this);
		this.openEdit = this.openEdit.bind(this);
		this.closeEdit = this.closeEdit.bind(this);
	}

	componentDidMount() {
		if ( this.props.selectedCustomerId !== undefined || this.props.customerId !== undefined ) {
			const customerId = this.props.selectedCustomerId !== undefined ? this.props.selectedCustomerId : this.props.customerId;
			this.setState({activeRequests: this.state.activeRequests + 1}, () => {
				Requests.getDevices({customerId})
				.then((data) => {
					this.setState({activeRequests: this.state.activeRequests - 1}, () => {
						if (data.status === "success" && data.devices) {
							let devices = {};
							if (Array.isArray(data.devices)) {
								data.devices.forEach((device) => {
									Object.assign(devices, device);
								});
							}
							this.setState({devices, filteredDevices: devices})
						} else {
							if (notificationsRef && notificationsRef.current)
							notificationsRef.current.addNotification({
								message: <Lang en="Could not get devices" fi="Laitteita ei voitu hakea" sv="Kunde inte hämta enheter"/>,
								level: "warning"
							})
						}
					});
				})
				.catch(err => {
					console.warn("Devices fetch error: ", err)
				});
			});
		}
	}

	componentDidUpdate(prevState) {
		if (
			this.props.selectedCustomerId !== prevState.selectedCustomerId ||
			(this.props.selectedCustomerId === undefined && Object.keys(this.props.devices).length < 1 && this.props.customerId !== prevState.customerId)
		) {
			const customerId = this.props.selectedCustomerId !== undefined ? this.props.selectedCustomerId : this.props.customerId;
			this.setState({activeRequests: this.state.activeRequests + 1}, () => {
				Requests.getDevices({customerId})
					.then((data) => {
						this.setState({activeRequests: this.state.activeRequests - 1}, () => {
							if (data.status === "success" && data.devices) {
								let devices = {};
								if (Array.isArray(data.devices)) {
									data.devices.forEach((device) => {
										Object.assign(devices, device);
									});
								}
								console.log(devices)
								this.setState({devices, filteredDevices: devices})
							} else {
								if (notificationsRef && notificationsRef.current)
								notificationsRef.current.addNotification({
									message: <Lang en="Could not get devices" fi="Laitteita ei voitu hakea" sv="Kunde inte hämta enheter"/>,
									level: "warning"
								})
							}
						});
					})
					.catch(err => {
						console.warn("Devices fetch error: ", err)
					});
			});
			
		}
	}

	getFilteredDevices(filteredDevices) {
		this.setState({filteredDevices});
	}

	getItems(devices) {
		if (devices !== undefined) {
			let ids = Object.keys(devices);
			ids.sort((a, b) => {
				if (devices[a].toLowerCase() < devices[b].toLowerCase())
					return -1;

				if (devices[a].toLowerCase() > devices[b].toLowerCase())
					return 1;

				return 0;
			});

			return ids.map((id) => {
				return (
					<tr key={"user_" + id} onClick={() => this.openEdit({id})}>
						<td>{devices[id]}</td>
						<td>{id}</td>
					</tr>
				);
			})
		}

		return null;
	}

	openEdit({id}) {
		this.setState({editOpen: true, editDevice: id});
	}

	closeEdit() {
		this.setState({editOpen: false});
	}

	render() {
		return (
			<div className="devices">
				<SidePanel>
					<CustomerSelect />
					<Search returnFiltered={this.getFilteredDevices} items={this.state.devices}/>
					<AddDevice />
				</SidePanel>
				<div className="main-panel">
					<h3 className="title"><Lang en="Devices" fi="Laitteet" sv="Enheter"/></h3>
					<EditDevice isOpen={this.state.editOpen} close={this.closeEdit} deviceId={this.state.editDevice} devices={this.state.devices}/>
					{ 	this.state.activeRequests > 0 ?
						<DefaultSpinner show/>
						:
						<Table className="users-table" striped bordered hover>
							<thead>
								<tr>
									<th><Lang en="Description" fi="Kuvaus" sv="Beskrivning"/></th>
									<th><Lang en="ID" fi="ID" sv="ID"/></th>
								</tr>
							</thead>
							<tbody>
								{this.getItems(this.state.filteredDevices !== undefined ? this.state.filteredDevices : this.state.devices)}
							</tbody>
						</Table>
					}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { customers, selectedCustomerId, devices } = state;
	return { customers, selectedCustomerId, devices };
}

const mapDispatchToProps = (dispatch) => {
	return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Devices);