import React, { Component } from "react";
import { connect } from "react-redux";
import {Form} from "react-bootstrap";

import Lang from "../language/language";

import "./userPermissions.css";

export default class UserPremissions extends Component {
	render() {
		return (
			<div className="user-permissions">
				<div className="subtitle"><Lang en="Permissions" fi="Oikeudet" sv="Rättigheter"/></div>
				<PermissionCheckBox id="customers" editRights={this.props.editRights} onChange={this.props.onChange}/>
				<PermissionCheckBox id="global_devices" granters={["customers"]} editRights={this.props.editRights} onChange={this.props.onChange}/>
				<PermissionCheckBox id="global_users" granters={["customers"]} editRights={this.props.editRights} onChange={this.props.onChange}/>
				<PermissionCheckBox id="devices" granters={["users", "global_users"]} editRights={this.props.editRights} onChange={this.props.onChange}/>
				<PermissionCheckBox id="users" granters={["customers", "users", "global_users"]} editRights={this.props.editRights} onChange={this.props.onChange}/>
				<PermissionCheckBox id="notifications" granters={["users", "global_users"]} editRights={this.props.editRights} onChange={this.props.onChange}/>
				<PermissionCheckBox id="notes" granters={["users", "global_users"]} editRights={this.props.editRights} onChange={this.props.onChange}/>
			</div>
		);
	}
}

class _PermissionCheckBox extends Component {
	constructor() {
		super();
		this.onChange = this.onChange.bind(this);
	}

	findPermission(id, rights) {
		return Array.isArray(rights) && rights.findIndex(x => x === id) > -1;
	}

	auth(req, rights) {
		if (Array.isArray(req) && Array.isArray(rights)) {
			for(let i = 0; i < req.length; ++i) {
				if ( rights.findIndex(x => x === req[i]) > -1 )
					return true;
			}
		}
		return false;
	}

	onChange(id) {
		if (this.props.editRights && this.props.onChange) {
			let newRights = JSON.parse(JSON.stringify(this.props.editRights));
			const index = this.props.editRights.findIndex(x => x === id);
			if (index > -1)	{
				newRights.splice(index, 1);
				this.props.onChange({rights: newRights});
			} else {
				newRights.push(id);
				this.props.onChange({rights: newRights});
			}
		}
	}

	render() {
		if (this.props.granters && this.auth(this.props.granters, this.props.rights)) {
			return (
				<Form.Check
					type="checkbox"
					label={PERMISSION_LABELS[this.props.id] !== undefined ? PERMISSION_LABELS[this.props.id] : this.props.id}
					checked={this.findPermission(this.props.id, this.props.editRights)}
					onChange={() => this.onChange(this.props.id)}
				/>
			);
		}
		return null;
	}
}

function mapStateToProps(state) {
	const {rights} = state;
	return {rights};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setVal: (prop, val) => dispatch({type: "SET_VAL", prop, val})
	};
}

const PermissionCheckBox = connect(mapStateToProps, mapDispatchToProps)(_PermissionCheckBox);

export const PERMISSION_LABELS = {
	customers: <Lang key="customers" en="Customers" fi="Asiakkaat" sv="Kunder"/>,
	global_devices: <Lang key="global_devices" en="Global devices" fi="Globaalit laitteet" sv="Globala enheter"/>,
	global_users: <Lang key="global_users" en="Global users" fi="Globaalit käyttäjät" sv="Globala användare" />,
	devices: <Lang key="devices" en="Devices" fi="Laitteet" sv="Enheter"/>,
	users: <Lang key="users" en="Users" fi="Käyttäjät" sv="Användare"/>,
	notifications: <Lang key="notifications" en="Notifications" fi="Ilmoitukset" sv="Anmälningar"/>,
	notes: <Lang key="notes" en="Notes" fi="Muistiinpanot" sv="Anteckningar"/>,
}