import React, {Component} from "react";
import { connect } from "react-redux";
import {Modal, Button, Form} from "react-bootstrap";

import Lang, {getLangString} from "../language/language";
import Requests from "../../utilities/requests";
import {notificationsRef} from "../notifications";

import "./addDevice.css";

class AddDevice extends Component {
	constructor() {
		super();

		this.state = {
			modalOpen: false,
			newDeviceId: "",
			newDescription: "",
			newCorrectionValues: null,
			colors: null,
			location: null,
			newLatitude: "60.388485",
			newLongitude: "23.106666",
		}

		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.submit = this.submit.bind(this);
		this.handleLocationChange = this.handleLocationChange.bind(this);
	}

	openModal() {
		this.setState({modalOpen: true});
	}

	closeModal() {
		this.setState({modalOpen: false});
	}

	submit(e) {
		e.preventDefault();
		this.closeModal();
		const requestValues = {
			deviceId: this.state.newDeviceId,
			description: this.state.newDescription,
			customerId: this.props.selectedCustomerId !== undefined ? this.props.selectedCustomerId : this.props.customerId,
			correctionValues: this.state.newCorrectionValues,
			colors: this.state.colors,
			location: (this.state.newLatitude !== "" ? this.state.newLatitude : "60.388485") + "," + (this.state.newLongitude !== "" ? this.state.newLongitude : "23.106666"),
		};
		
		Requests.addNewDevice(requestValues)
			.then((data) => {
				if (data && data.status === "success") {
					const id = requestValues.deviceId;
					// Requesting data for device
					Requests.getDeviceData({deviceId: id})
						.then((dData) => {
							if (dData.status === "success") {
								if (notificationsRef && notificationsRef.current)
									notificationsRef.current.addNotification({
										message: <Lang en="Device added" fi="Laite lisätty" sv="Enheten tillades"/>,
										level: "success"
									})

								let deviceData = {
									deviceId: id,
									description: dData.description,
									customerId: dData.customerId,
									correctionValues: dData.correctionValues,
									colors: dData.colors,
									location: dData.location,
									quantities: dData.quantities,
									units: dData.units,
									units_short: dData.units_short,
								}
								this.props.addDeviceData({id, data: deviceData});
							}
						})
						.catch((err) => {
							console.log("Error, while adding device.");
							console.log(err);
						})
				} else {
					if (notificationsRef && notificationsRef.current)
						notificationsRef.current.addNotification({
							message: <Lang en="Failed to add device" fi="Laiteen lisäys epäonnistui" sv="Kunde inte tillägga enheten"/>,
							level: "warning"
						})
					else
						console.log("Failed to add new device.");
				}
			});
	}

	handleLocationChange(e, variable) {
		let text = e.target.value.replace(",", ".");
		const substr = text.substring(text.length - 1, text.length);
		if (text === "" || substr.match(new RegExp("[0-9]", "g")) || substr === ".")
			this.setState({[variable]: text});
	}

	render() {
		return (
			<div className="add-device-wrapper">
				<Button
					block
					className="control-button"
					variant="secondary"
					onClick={this.openModal}
					disabled={this.state.modalOpen || this.props.disabled}
				>
					<Lang en="Add device" fi="Lisää laite" sv="Lägg till enhet" />
				</Button>
				<Modal show={this.state.modalOpen} onHide={this.closeModal}>
					<form
						onSubmit={this.submit}
						className="add-device-modal-form"
					>
						<Modal.Header closeButton>
							<Modal.Title><Lang en="Add device" fi="Lisää laite" sv="Lägg till enhet"/></Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form.Label><Lang en="Device ID" fi="Laitteen ID" sv="Enhetens ID"/></Form.Label>
							<Form.Control 
								type="text"
								placeholder={getLangString(this.props.lang, {en: "Device ID", fi: "Laitteen ID", sv: "Enhetens ID"})}
								value={this.state.newDeviceId}
								onChange={(e) => this.setState({newDeviceId: e.target.value})}
							/>

							<Form.Label><Lang en="Description" fi="Kuvaus" sv="Beskrivning"/></Form.Label>
							<Form.Control 
								type="text"
								placeholder={getLangString(this.props.lang, {en: "Description", fi: "Kuvaus", sv: "Beskrivning"})}
								value={this.state.newDescription}
								onChange={(e) => this.setState({newDescription: e.target.value})}
							/>

							<div className="location">
								<div className="latitude">
									<Form.Label><Lang en="Latitude" fi="Leveysaste" sv="Latitud"/></Form.Label>
									<Form.Control 
										type="text"
										placeholder={getLangString(this.props.lang, {en: "Latitude", fi: "Leveysaste", sv: "Latitud"})}
										value={this.state.newLatitude}
										onChange={(e) => this.handleLocationChange(e, "newLatitude")}
									/>
								</div>
								<div className="longitude">
									<Form.Label><Lang en="Longitude" fi="Pituusaste" sv="Longitud"/></Form.Label>
									<Form.Control 
										type="text"
										placeholder={getLangString(this.props.lang, {en: "Longitude", fi: "Pituusaste", sv: "Longitud"})}
										value={this.state.newLongitude}
										onChange={(e) => this.handleLocationChange(e, "newLongitude")}
									/>
								</div>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.closeModal}><Lang en="Cancel" fi="Peruuta" sv="Ångra" /></Button>
							<Button variant="dark" type="submit">OK</Button>
						</Modal.Footer>
					</form>
				</Modal>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { lang, devices, deviceData, selectedCustomerId } = state;
	return { lang, devices, deviceData, selectedCustomerId };
}

const mapDispatchToProps = (dispatch) => {
	return {
		addDeviceData: (val) => dispatch({type: "ADD_DEVICE_DATA", val}),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AddDevice);


// const nullIfEmpty = (input) => {
// 	if (input === undefined || input === "")
// 		return null;

// 	return input;
// }