import React, {Component} from "react";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import moment from "moment";
import {withRouter} from "react-router-dom";
import { Button } from "react-bootstrap";

import Requests from "../../utilities/requests";
import Lang, {getStringFromLang} from "../language/language";
import isDefined from "../../utilities/isDefined";
import {QUANTITY_TRANSLATIONS} from "../language/translations";
import {notificationsRef} from "../notifications";
import {MSToS, getLastOclock} from "../../utilities/time";
import DefaultSpinner from "../spinner/defaultSpinner";

import "./deviceDailyView.css";

import * as Plotly from 'plotly.js/dist/plotly';
import * as dictFin from 'plotly.js/lib/locales/fi.js';
import * as dictSwe from 'plotly.js/lib/locales/sv.js';
import createPlotlyComponent from 'react-plotly.js/factory';

const Plot = createPlotlyComponent(Plotly);
Plotly.register(dictFin);
Plotly.register(dictSwe);

class DeviceDailyView extends Component {
	constructor() {
		super();

		this.state = {
			activeRequests: 0,
		}

		this.backwards = this.backwards.bind(this);
	}

	componentDidMount() {
		if (this.props.deviceDailyView_devId) {
			/* Getting measurements */
			const requestValues = {
				deviceIds: [this.props.deviceDailyView_devId],
				amount: null,
				start: MSToS(getLastOclock(22)),
				end: MSToS(moment().valueOf()),
			}

			this.setState({activeRequests: this.state.activeRequests + 1}, () => {
				Requests.getMeasurements(requestValues)
				.then((data) => {
					this.setState({activeRequests: this.state.activeRequests - 1}, () => {
						if (data.status === "success") {
							this.props.addTabletViewMeasurements(data.measurements);
						} else {
							if (notificationsRef && notificationsRef.current)
								notificationsRef.current.addNotification({
									message: <Lang en="Failed to get measurements" fi="Mittausten haku epäonnistui" sv="Kunde inte hämta mätningar"/>,
									level: "warning"
								})
						}
					})
				})
				.catch((err) => {
					console.warn("Measurments fetch error: ", err)
				});
			});
		}
	}

	getUnit({devId, measIndex, deviceData}) {
		if (isDefined({devId, measIndex, deviceData}) && deviceData[devId] && deviceData[devId].units_short && deviceData[devId].units_short[measIndex]) {
			return deviceData[devId].units_short[measIndex] === "C" ? "°C" : deviceData[devId].units_short[measIndex];
		}
		return "";
	}

	getTitle({devId, measIndex, deviceData, lang}) {
		if (isDefined({devId, measIndex, deviceData}) && deviceData[devId] && deviceData[devId].quantities && deviceData[devId].quantities[measIndex]) {
			return QUANTITY_TRANSLATIONS[deviceData[devId].quantities[measIndex]] ? getStringFromLang(QUANTITY_TRANSLATIONS[deviceData[devId].quantities[measIndex]], lang) : deviceData[devId].quantities[measIndex];
		}
		return "";
	}

	getDatasets({devId, measIndex, measurements}) {
		let values = {x: [], y: []};
		if (isDefined({devId, measIndex, measurements}) && measurements[devId]) {
			values.x = measurements[devId].t.map(t => {
				return new Date(t * 1000);
			});
			values.y = measurements[devId].vals.map(x => {
				return x[measIndex];
			});
		}

		const dataset = Object.assign({
			visible: true,
			type: 'scatter',
			mode: 'lines',
			fill: 'tozeroy',
			line: {
				width: 2,
				shape: "spline",
			},
		}, values);

		return [dataset];
	}

	backwards() {
		this.props.history.push("/home");
	}

	render() {
		let content = this.state.activeRequests > 0 ? <DefaultSpinner show/> : (
			<>
				<div className="controls"><Button variant="secondary" onClick={this.backwards}><Lang en="Back" fi="Takaisin" sv="Tillbaka"/></Button></div>
				<div className="plotly-wrapper">
					<Plot
						useResizeHandler={true}
						data={this.getDatasets({devId: this.props.deviceDailyView_devId, measIndex: this.props.deviceDailyView_measIndex, measurements: this.props.tabletView_measurements})}
						layout={{
							margin: {
								t: 50,
							},
							title: {
								text: this.getTitle({devId: this.props.deviceDailyView_devId, measIndex: this.props.deviceDailyView_measIndex, deviceData: this.props.tabletView_deviceData, lang: this.props.lang}),
								yref: "paper",
							},
							yaxis: {
								hoverformat: ".2f",
								title: {
									text: this.getUnit({devId: this.props.deviceDailyView_devId, measIndex: this.props.deviceDailyView_measIndex, deviceData: this.props.tabletView_deviceData})
								}
							},
							hovermode: "closest",
							legend: false,
							autosize: true
						}}
						config={{
							displayModeBar: isMobile ? false : undefined,
							modeBarButtonsToRemove: ["toImage", "sendDataToCloud"],
							modeBarButtonsToAdd: [[{
								name: 'Image',
								icon: Plotly.Icons.camera,
								click: function (gd) {
									Plotly.downloadImage(gd, {
										filename: 'farmiaisti_' + moment().format("YYYYMMDD_HHmmss"),
										format: 'png',
										width: gd._fullLayout.width,
										height: gd._fullLayout.height
									})
								}
							}]],
							locale: this.props.lang,
							displaylogo: false,
						}}
						style={{width: "100%", height: "100%"}}
					/>
				</div>
			</>
		);
		return (
			<div className="device-daily-view">
				{content}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { deviceDailyView_devId, deviceDailyView_measIndex, tabletView_deviceData, tabletView_measurements, lang } = state;
	return { deviceDailyView_devId, deviceDailyView_measIndex, tabletView_deviceData, tabletView_measurements, lang };
}

const mapDispatchToProps = (dispatch) => {
	return {
		setVal: (prop, val) => dispatch({type: "SET_VAL", prop, val}),
		addTabletViewMeasurements: (val) => dispatch({type: "ADD_TABLETVIEW_MEASUREMENTS", val}),
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeviceDailyView));