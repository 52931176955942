import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Button, Form, Table } from "react-bootstrap";
import DefaultSpinner from "../spinner/defaultSpinner";
import Lang, { getLangString } from "../language/language";
import Requests from "../../utilities/requests";
//import { notificationsRef } from "../notifications";
import moment from "moment";
import { MSToS } from "../../utilities/time";
import { CirclePicker } from "react-color";

import "./manageAlerts.css";

//Due to possibly confusing and misleading variable names etc with the page notificationsRef and
//user notification system, I am calling the user notification system alerts.

//TODO ensure alert adding and deleting works first. Work on modifying later
class ManageAlerts extends Component {
	constructor() {
		super();
		this.state = {

			//New alert form data:
			newA_description: "",
			newA_deviceId: undefined,
			newA_valIndex: undefined,
			newA_value: 0,
			newA_direction: "up",
			newA_tolerance: 600,
			newA_color: "#234000",

			//Edit alert form data:
			modA_alertId: undefined, //Selected alert id
			modA_description: "", //TODO replace values with current values
			modA_deviceId: undefined,
			modA_valIndex: undefined,
			modA_value: 0,
			modA_direction: "up",
			modA_tolerance: 600,
			modA_color: "#234000",

			//Data from API
			userDevices: undefined, //device ids and names
			deviceData: undefined, //device details
			deviceAlerts: undefined, //alert listing
			alertsData: undefined, //alert details
			refinedAlertData: undefined, //table data

			activeRequests: 0,

			//Status tracking
			loading: true, //true when the data is currently loading, false when ready
			createAlertOpen: false,
			editAlertOpen: false,
		}

		this.openCreateAlert = this.openCreateAlert.bind(this);
		this.closeCreateAlert = this.closeCreateAlert.bind(this);

		this.openEditAlert = this.openEditAlert.bind(this);
		this.closeEditAlert = this.closeEditAlert.bind(this);

		//this.submitNew = this.submitNew.bind(this);
		//this.submitModif = this.submitModif.bind(this);
	}

	componentDidUpdate(prevState) {
		
	}
   
	componentDidMount() {
		if(this.props.customerId !== undefined && this.props.userId !== undefined) {
			//console.log("Current user id: ", this.props.userId);

			this.setState({activeRequests: this.state.activeRequests + 1}, () => {
			Requests.getDevices({customerId: this.props.customerId}) //Getting user devices
			.then((data) => {
				this.setState({activeRequests: this.state.activeRequests - 1}, () => {
					let devices = {};
					if (data.status === "success") {
						data.devices.forEach((device) => {
							Object.assign(devices, device);
						});
						this.setState({userDevices: devices});
						
						const deviceIds = Object.keys(devices);
						let allDeviceData = {};
						let allDeviceAlerts = {};
						let all_alert_data = {};
						let refinedData = {};

						deviceIds.forEach((devId) => {
							this.setState({activeRequests: this.state.activeRequests + 1}, () => {
								Requests.getDeviceData({deviceId: devId}) //Getting data from the devices
								.then((dev_data) => {
									this.setState({activeRequests: this.state.activeRequests - 1})
									if (dev_data.status === "success") {
										let deviceData = {
											customerId: dev_data.customerId,
											description: dev_data.description,
											quantities: dev_data.quantities,
											units: dev_data.units,
											units_short: dev_data.units_short,
										}
										allDeviceData[devId] = deviceData;
									}
								})
							})
							
							this.setState({activeRequests: this.state.activeRequests + 1}, () => {
								Requests.getDeviceAlerts({deviceId: devId}) //Getting alerts for each device one by one
								.then((alerts) => {
									this.setState({activeRequests: this.state.activeRequests - 1})
									allDeviceAlerts[devId] = alerts.notifRules;
									let rules = alerts.notifRules;
									rules.forEach(alert_entry => {
										let alert_ids = Object.keys(alert_entry);
										alert_ids.forEach(alert_id => {
											this.setState({activeRequests: this.state.activeRequests + 1}, () => {
												Requests.getAlert({notifRuleId: alert_id}) //Getting alert detail by its ID
												.then((alert_data) => {
													all_alert_data[alert_id] = alert_data;
													
													let tableDataEntry = { //Better format for the tables and management
														color: alert_data.color,
														desc: alert_data.description,
														deviceId: alert_data.deviceId,
														//deviceName: this.getDeviceName(alert_data.deviceId), //usually ends up as undefined
														direction: alert_data.direction, //"up" or "down"
														tolerance: alert_data.tolerance,
														userIds: alert_data.userIds, //users recieving the alert, array
														valIndex: alert_data.valIndex, //index number of measurement
														quantity: this.getQuantityFromIndex(alert_data.deviceId, alert_data.valIndex), //measurement type needed
														unit: this.getUnitFromIndex(alert_data.deviceId, alert_data.valIndex), //short unit needed
														value: alert_data.value,
													};
													
													refinedData[alert_id] = tableDataEntry;
													this.setState({activeRequests: this.state.activeRequests - 1})
												})
											})
										});
									});
									
								})
							})
						})
						this.setState({deviceData: allDeviceData}); //device meas types and units needed
						this.setState({deviceAlerts: allDeviceAlerts}); //alert listings for devices
						this.setState({alertsData: all_alert_data}); //alert details
						this.setState({refinedAlertData: refinedData}); //Table data

					}
				})
			})
			})
			
			this.setState({loading: false}); //everything needed is loaded at this point
		}
	}

//	addTestAlerts() { //Testing alert addition. works
//		let data1 = {
//			deviceId: "0004A30B00E8DCA1",
//			description: "test alert 1",
//			valIndex: 1,
//			value: 15,
//			direction: "up",
//			tolerance: 900,
//			userIds: [this.props.userId],
//			color: "#277123"
//		};
//		this.addDevAlert(data1);
//
//		let data2 = {
//			deviceId: "0004A30B00E8DCA1",
//			description: "test alert 2",
//			valIndex: 2,
//			value: 60,
//			direction: "up",
//			tolerance: 900,
//			userIds: [this.props.userId],
//			color: "#217123"
//		};
//		this.addDevAlert(data2);
//
//		let data3 = {
//			deviceId: "0004A30B00E8DCA1",
//			description: "test alert 3",
//			valIndex: 3,
//			value: 30,
//			direction: "up",
//			tolerance: 900,
//			userIds: [this.props.userId],
//			color: "#217123"
//		};
//		this.addDevAlert(data3);
//	}

	getDevAlerts(devices) { //Getting alerts for devices
		//console.log("dev alert input devices: ", devices);
		
		if(devices !== undefined) {
			let alertsData = {};
			devices.forEach(function(idEntry)  { //Going through all ids
				//console.log(idEntry);
				
				Requests.getDeviceAlerts({deviceId: idEntry})
				.then((data) => {
					//console.log("Alert req data: ", data);
					if (data.status === "success") {
						alertsData[idEntry] = data.notifRules; //adding entries to 
						//console.log(idEntry);
						//console.log(alertsData);
						
					}
				})
				.catch((err) => {
					console.warn("Error, while getting alerts.");
					console.warn(err);
				});
			});
			console.log("alertsData", alertsData);
			
			return(alertsData);
		}
		else {
			return(undefined);
		}
	}

	getMeasData(devices) { //Getting measurement data for devices (for meas types and units)
		if(devices !== undefined) {
			let measData = {};
			devices.forEach(function(idEntry)  { //Going through all ids
				const req = {
					deviceIds: idEntry,
					amount: 10,
					start: MSToS(moment.now()),
					end: MSToS(moment().subtract(2, "hours")),
				}

				Requests.getMeasurements(req)
				.then((data) => {
					//console.log("Meas req data: ", data);
					if (data.status === "success") {
						measData[idEntry] = data.measurements; //adding entries
						//console.log(idEntry);
						//console.log(measData);
						
					}
				})
				.catch((err) => {
					console.warn("Error, while getting measurements.");
					console.warn(err);
				});
			});
			console.log("measData", measData);
			
			return(measData);
		}
		else {
			return(undefined);
		}
	}

	addDevAlert(reqData) {
		if(reqData !== undefined) {
			this.setState({activeRequests: this.state.activeRequests + 1}, () => {
				Requests.addAlert(reqData)
				.then((data) => {
					this.setState({activeRequests: this.state.activeRequests - 1}, () => {
						//console.log("add data from server", data);
						
						if (data.status === "success") {
							let alertId = data.notifRuleId;
							let tableData = this.state.refinedAlertData;

							//console.log("reqData", reqData);
							
							tableData[alertId] = {};
							tableData[alertId].desc = reqData.description;
							tableData[alertId].direction = reqData.direction;
							tableData[alertId].tolerance = reqData.tolerance;
							tableData[alertId].color = reqData.color;
							tableData[alertId].value = reqData.value;
							tableData[alertId].deviceId = reqData.deviceId;
							tableData[alertId].deviceName = this.getDeviceName(reqData.deviceId);
							tableData[alertId].userIds = [this.props.userId];
							tableData[alertId].valIndex = reqData.valIndex;
							tableData[alertId].quantity = this.getQuantityFromIndex(reqData.deviceId, reqData.valIndex);
							tableData[alertId].unit = this.getUnitFromIndex(reqData.deviceId, reqData.valIndex);
							//console.log("tableData[alertId]", tableData[alertId]);

							//alternative using state
							//tableData[alertId].desc = this.state.newA_description;
							//tableData[alertId].direction = this.state.newA_direction;
							//tableData[alertId].tolerance = this.state.newA_tolerance;
							//tableData[alertId].color = this.state.newA_color;
							//tableData[alertId].value = this.state.newA_value;
							//tableData[alertId].deviceId = this.state.newA_deviceId;
							//tableData[alertId].deviceName = this.getDeviceName(this.state.newA_deviceId);
							//tableData[alertId].userIds = [this.props.userId];
							//tableData[alertId].valIndex = this.state.newA_valIndex;
							//tableData[alertId].quantity = this.getQuantityFromIndex(this.state.newA_deviceId, this.state.newA_valIndex);
							//tableData[alertId].unit = this.getUnitFromIndex(this.state.newA_deviceId, this.state.newA_valIndex);
							//console.log("tableData[alertId]", tableData[alertId]);
							
							this.setState({refinedAlertData: tableData});
							
							this.setState({createAlertOpen: false});
							return(true);
						}
						else {
							console.log("failed to add an alert");
						}
					});
				});
			});
		}
		else {
			return(undefined);
		}
	}

	delDevAlert(alertId) { //Removing a alert
		//let alertId = this.state.modA_alertId; //"undefined" for some reason
		let reqData = {notifRuleId: alertId};
		if(reqData !== undefined) {
			this.setState({activeRequests: this.state.activeRequests + 1}, () => {
				Requests.deleteAlert(reqData)
				.then((data) => {
					this.setState({activeRequests: this.state.activeRequests - 1}, () => {
						if (data.status === "success") {
							let tableData = this.state.refinedAlertData;
							delete tableData[alertId]; //removing the alert from table
							this.setState({refinedAlertData: tableData});
							//return(data.notifRuleId)
						}
						else {
							console.log("failed to remove an alert")
						}
					});
				});
			});
			
		//this.setState({modA_alertId: undefined});
		//this.setState({modA_deviceId: undefined});
		//this.setState({modA_valIndex: undefined});

		this.setState({editAlertOpen: false});
		}
		else {
			return(undefined);
		}
	}

	modifyDevAlert(reqData) { //Modifying alert

		if(reqData !== undefined) {
			this.setState({activeRequests: this.state.activeRequests + 1}, () => {
				Requests.modifyAlert(reqData)
				.then((data) => {
					this.setState({activeRequests: this.state.activeRequests - 1}, () => {
						if (data.status === "success") {
							
							let tableData = this.state.refinedAlertData;
							
							tableData[reqData.notifRuleId].desc = reqData.description;
							tableData[reqData.notifRuleId].direction = reqData.direction;
							tableData[reqData.notifRuleId].tolerance = reqData.tolerance;
							tableData[reqData.notifRuleId].color = reqData.color;
							tableData[reqData.notifRuleId].value = reqData.value;
							this.setState({refinedAlertData: tableData});
							
							this.setState({editAlertOpen: false})
							
							return(true)
						}
						else {
							console.log("failed to add an alert")
						}
					});
				});
			});
		}
		else {
			return(undefined);
		}
	}

	getQuantityFromIndex(deviceId, valIndex) { //Measurement type for wanted device
		if(this.state.deviceData !== undefined && deviceId !== undefined && valIndex !== undefined) {
			let device_data = this.state.deviceData;
			return(device_data[deviceId].quantities[valIndex])
		}
		else {
			return(undefined)
		}
	}

	getUnitFromIndex(deviceId, valIndex) { //Unit for wanted device
		if(this.state.deviceData !== undefined && deviceId !== undefined && valIndex !== undefined) {
			let device_data = this.state.deviceData;
			return(device_data[deviceId].units_short[valIndex])
		}
		else {
			return(undefined)
		}
	}

	getIndexFromQuantity(deviceId, quantity) { //Get the index number for quantity from measurement type for device
		if(this.state.deviceData !== undefined && deviceId !== undefined && quantity !== undefined) {
			let device_data = this.state.deviceData;
			let devQuantities = device_data[deviceId].quantities;
			let indexCounter = 0;
			devQuantities.forEach(quantEntry => {
				if(quantEntry === quantity) {
					return(indexCounter);
				}
				else {
					indexCounter = indexCounter + 1;
				}
			});
			return(undefined) //not found
		}
		else {
			return(undefined)
		}
	}
	
	openCreateAlert() { //opens alert creation modal
		this.setState({createAlertOpen: true});
	}

	closeCreateAlert() {
		this.setState({createAlertOpen: false});

		//TODO empty the filled data here? or keep for faster adding similar alerts?
	}

//	submitNew(e) { //new alert submit
//		 e.preventDefault();
//		 this.closeCreateAlert();
//		 let reqData = {
//			userIds: [this.props.userId],
//			description: this.state.newA_description,
//			deviceId: this.state.newA_deviceId,
//			valIndex: this.state.newA_valIndex,
//			value: this.state.newA_value,
//			direction: this.state.newA_direction,
//			tolerance: this.state.newA_tolerance,
//			color: this.state.newA_color,
//		};
//		Requests.addAlert(reqData)
//			 .then((data) => {
//				 if (data && data.status === "success") {
//					 if (notificationsRef && notificationsRef.current)
//						 notificationsRef.current.addNotification({
//							 message: "Notification rule added",
//							 level: "success"
//						 })
// 
//					 // Ensure it is added
//					 Requests.getDeviceAlerts()
//						 .then((dData) => {
//							 console.log(dData);
//						 })
//			 }
//			 else {
//				
//				 }
//			 });
//	 }

	openEditAlert(alertId, tableData) { //opens alert edit modal
		this.setState({modA_alertId: alertId});
		this.setState({modA_deviceId: tableData.deviceId});
		this.setState({modA_valIndex: tableData.valIndex});

		//Fill the current values to the form for convenience
		this.setState({modA_description: tableData.desc});
		this.setState({modA_color: tableData.color});
		this.setState({modA_tolerance: tableData.tolerance});
		this.setState({modA_direction: tableData.direction});
		this.setState({modA_value: tableData.value});

		//console.log("tableData in edit", tableData);
		
		this.setState({editAlertOpen: true});
	}

	closeEditAlert() {
		this.setState({modA_alertId: undefined});
		this.setState({modA_deviceId: undefined});
		this.setState({modA_valIndex: undefined});

		this.setState({modA_description: undefined});
		this.setState({modA_color: undefined});
		this.setState({modA_tolerance: undefined});
		this.setState({modA_direction: undefined});
		this.setState({modA_value: undefined});

		this.setState({editAlertOpen: false});
	}

//	submitModif(e) { //alert modification submit
//	  e.preventDefault();
//	  //TODO handle modification submission
//	}

	extractDevIds(devices) {
		let keys = Object.keys(devices);
		this.setState({userDeviceIds: keys});
		return(keys);
	}

	getDeviceName(devId) { //Device naming
		if(this.state.userDevices !== undefined && devId !== undefined) {
			let deviceData = this.state.userDevices;
			return(deviceData[devId]);
				
		}
		else {
			return(undefined);
		}
	}

	getDeviceNameOld(devId) { //Device naming
		if(this.state.userDevices !== undefined && devId !== undefined) {
			let deviceData = this.state.userDevices;
			let devIds = Object.keys(deviceData);
			devIds.forEach(function(idEntry)  { //Searching for the id entry
				if(idEntry === devId) { //if entry matches the input id
					return(deviceData[idEntry]);
				}
			});
		}
		else {
			return(undefined);
		}
	}

	getDeviceIds() {
		if(this.state.userDevices !== undefined) {
			return(Object.keys(this.state.userDevices));
		}
		else {
			return(undefined);
		}
	}

	getAlertDetails(alertData) { //Alert details
		console.log(alertData);
		
		if(alertData !== undefined) {
			let a_keys = Object.keys(alertData)
			console.log(a_keys);
			

			a_keys.forEach((key) => {
				console.log(key);
				console.log(alertData[key]);
				
				if(alertData[key] !== undefined) {
					console.log("entries exist");
					
					alertData[key].forEach((alertId) => {
						console.log(alertId);
		
					});
				}
				
				//Object.assign(devices, device);
			});
			return(alertData);
		}
		else {
			return({})
		}
	}

	getDeviceOptions() { //Devices listed for form selection
		if(this.state.userDevices !== undefined) {
			let devices = this.state.userDevices;
			let dev_keys = Object.keys(devices);
			let options = [];
			let num = 0;
			dev_keys.forEach(id => {
				if(num === 0) { //selecting first one immediately
					//this.setState({newA_deviceId: id}) //crash
					options.push(
						<option key={"device_blank"}> {"Select a device"} </option> //adding a blank option first
					);
				}

				options.push(
					<option key={"device_" + id} value={id}> {this.getDeviceName(id)} {" - "} {id} </option>
				);
				num = num + 1;
				//<option key={"device_" + id}> {this.getDeviceName(id)} + " - " + {id} </option>
			});
			return(
				<Form.Control 
					as="select"
					placeholder={getLangString(this.props.lang, {fi: "Valitse laite", en: "Select device", sv: "Välj enhet"})}
					value={this.state.newA_deviceId}
					onChange={(e) => this.setState({newA_deviceId: e.target.value})}
				>
					{options}
				</Form.Control>
				
			);
		}
		else {
			return(
				<Form.Control 
					as="select"
					placeholder={getLangString(this.props.lang, {fi: "Valitse laite", en: "Select device", sv: "Välj enhet"})}
					value={this.state.newA_deviceId}
					onChange={(e) => this.setState({newA_deviceId: e.target.value})}
				>
					<option key={"device_loading"}> Loading devices </option>
				</Form.Control>
			)
		}
	}

	getQuantOptions() { //Measurement types from selected device listed for form selection
		let options = [];
		let deviceId = this.state.newA_deviceId;
		let deviceData = this.state.deviceData;
		let num = 0;

		if(deviceData !== undefined && deviceId !== undefined) {
			if(deviceData[deviceId] !== undefined) {
				let quantities = deviceData[deviceId].quantities;
				
				options.push(
					<option key={"default"} value={undefined}> {"Select measurement type"} </option>
				);
				quantities.forEach(entry => {
					options.push(
						<option key={"quant_" + num + "_" + entry} value={num}> {entry} </option>
					);
					num = num + 1;
				});
				return(
					<Form.Control 
						as="select"
						placeholder={"Select one"}
						value={this.state.newA_valIndex}
						onChange={(e) => this.setState({newA_valIndex: e.target.value})}
					>
						{options}
					</Form.Control>
				);
			}
			else {
				return(
					<Form.Control 
						as="select"
						placeholder={"Select device"}
						value={this.state.newA_valIndex}
						onChange={(e) => this.setState({newA_valIndex: e.target.value})}
					>
						<option key={"quant_loading"}> Select a device above </option>
					</Form.Control>
				)
			}

		}
		else {
			return(
				<Form.Control 
					as="select"
					placeholder={"Select one"}
					value={this.state.newA_valIndex}
					onChange={(e) => this.setState({newA_valIndex: e.target.value})}
				>
					<option key={"quant_loading"}> Select a device above </option>
				</Form.Control>
			);
		}
	}

	getAlertsTable() { //Table rows from data
		let tableData = this.state.refinedAlertData;
		let rows = [];
		
		if (tableData !== undefined) {
		let alertIds = Object.keys(tableData); //Alert ids
		alertIds.forEach(alert_id => {
			//let deviceId = tableData[alert_id].deviceId;
			//let valIndex = tableData[alert_id].valIndex;
			rows.push(
				//TODO user selection and table column
				//TODO more user-friendly interface elements, naming , ...
				<tr key={"alert_" + alert_id} onClick={() => this.openEditAlert(alert_id, tableData[alert_id])}>
					<td>{this.getDeviceName(tableData[alert_id].deviceId)} <br/> {tableData[alert_id].deviceId}</td>
					<th>{tableData[alert_id].quantity + " ("+ tableData[alert_id].unit + ")"}</th>
					<td>{tableData[alert_id].desc ? tableData[alert_id].desc : "-"}</td>
					<td>{tableData[alert_id].direction ? tableData[alert_id].direction : "-"}</td>
					<td>{tableData[alert_id].value}</td>
					<td>{tableData[alert_id].tolerance ? tableData[alert_id].tolerance : "-"}</td>
					<td> 
						<span
							className="colored_dot_span"
							style={{
								
								height: "25px",
								width: "25px",
								display: "inline-block",
							}}
						>
							<div
								className="colored_dot"
								style={{
									color: tableData[alert_id].color,
									backgroundColor: tableData[alert_id].color,
									borderRadius: "50%",
									height: "100%",
									width: "100%",
									position: "relative",
									outline: "none",
								}}
							/>
						</span>
						{ " " + tableData[alert_id].color}
					</td>
				</tr>
			)
			});
			return(rows)
		}
		else if(tableData === {}) {
			rows.push(
				<tr key={"no_data"}>
					<td>{"No alerts"}</td>
					<th>{"-"}</th>
					<th>{"-"}</th>
					<th>{"-"}</th>
					<th>{"-"}</th>
					<th>{"-"}</th>
					<th>{"-"}</th>
				</tr>
			)
			return(rows)
		}
		else {
			return(undefined);
		}
	}

	render() {
		
		//console.log("this.props.deviceData", this.props.deviceData);
		//console.log("render userDevices: ", this.state.userDevices);
		//console.log("render deviceAlerts: ", this.state.deviceAlerts);
		//console.log("render alertsData",this.state.alertsData);
		//console.log("render deviceData: ", this.state.deviceData);
		//console.log("render refinedAlertData: ", this.state.refinedAlertData);
		
		if(this.state.loading === true) {
			return (
			<div className="manage-alerts">
				<DefaultSpinner show={this.state.activeRequests > 0}/>
			</div>);
		}
		else {
		return(
			<div className="manage-alerts">
			<div className="content-wrapper">
				<Button
					className="add-alert-button"
					variant="secondary"
					onClick={this.openCreateAlert}
					disabled={this.state.createAlertOpen || this.props.disabled}
				>
					<Lang en="Add alert" fi="Lisää hälytys" sv="Lägg till en varning" />
				</Button>
				<Table className="notifications-table" striped bordered hover variant="dark">
					<thead>
						<tr>
							<th><Lang en="Device" fi="Laite" sv="Anordning"/></th>
							<th>Measurement type</th>
							<th>Description</th>
							<th>Limit type</th>
							<th>Alert value</th>
							<th>Tolerance</th>
							<th>Color</th>
						</tr>
					</thead>
					<tbody>
						{this.getAlertsTable()}
					</tbody>
				</Table>
				<DefaultSpinner show={this.state.activeRequests > 0}/>

		{/* Create new alerts modal */}
		<Modal show={this.state.createAlertOpen} onHide={this.closeCreateAlert} className="add-alert-modal">
				<Form
					//onSubmit={this.submitNew}
					className="add-alert-modal-form"
				>
					<Modal.Header closeButton>
						<Modal.Title><Lang en="Add alert" fi="Lisää hälytys" sv="Lägg till larm"/></Modal.Title>
					</Modal.Header>

					<Modal.Body>
					<Form.Label><Lang fi="Laite" en="Device" sv="Anordning"/></Form.Label>
					{this.getDeviceOptions()}
					
					<Form.Label>Measurement type</Form.Label>
					{this.getQuantOptions()}

					<Form.Label>Description</Form.Label>
					<Form.Control 
						type="text"
						placeholder={"Notification description"}
						value={this.state.newA_description}
						onChange={(e) => this.setState({newA_description: e.target.value})}
					/>

					<Form.Label>Limit direction and value</Form.Label>
					<Form.Control
						as="select"
						placeholder={"Alert type"}
						value={this.state.newA_direction}
						onChange={(e) => this.setState({newA_direction: e.target.value})}
					>
						<option>up</option>
						<option>down</option>
					</Form.Control>
					<Form.Control 
						type="text"
						placeholder={"Limit value number"}
						value={this.state.newA_value}
						onChange={(e) => this.setState({newA_value: e.target.value})}
					/>

					<Form.Label>Tolerance</Form.Label>
					<Form.Control 
						type="text"
						placeholder={"Tolerance value in seconds"}
						value={this.state.newA_tolerance}
						onChange={(e) => this.setState({newA_tolerance: e.target.value})}
					/>

					<Form.Label>Color</Form.Label>
					<Form.Control 
						type="text"
						placeholder={"Color hex e.g. #277000"}
						value={this.state.newA_color}
						onChange={(e) => this.setState({newA_color: e.target.value})}
					/>
					<CirclePicker 
						width="410px"
						colors={['#D9E3F0', '#F47373', '#697689', '#37D67A', '#2CCCE4', '#555555', '#dce775', '#ff8a65', '#ba68c8', '#4D4D4D', '#999999', '#FFFFFF', '#F44E3B', '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF', '#333333', '#808080', '#cccccc', '#D33115', '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF', '#000000', '#666666', '#B3B3B3', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1', '#653294', '#AB149E']}
						//triangle="top"
						onChange={ (e) => this.setState({newA_color: e.hex}) }
					/>
					</Modal.Body>

					<Modal.Footer>
						<Button variant="secondary" onClick={this.closeCreateAlert}><Lang en="Cancel" fi="Peruuta" sv="Ångra" /></Button>
						<Button variant="dark" onClick={() => this.addDevAlert({
							userIds: [this.props.userId],
							description: this.state.newA_description,
							deviceId: this.state.newA_deviceId,
							valIndex: Number(this.state.newA_valIndex),
							value: Number(this.state.newA_value),
							direction: this.state.newA_direction,
							tolerance: Number(this.state.newA_tolerance),
							color: this.state.newA_color
						})}>
							OK
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>


			{/* Edit selected alert modal */}
			<Modal show={this.state.editAlertOpen} onHide={this.closeEditAlert} className="edit-alert-modal">
				<Form
					//onSubmit={this.submitModif}
					className="edit-alert-modal-form"
				>
					<Modal.Header closeButton>
						<Modal.Title>Edit alert</Modal.Title>
					</Modal.Header>
					<Modal.Body>

					<Form.Label>Description</Form.Label>
					<Form.Control 
						type="text"
						placeholder={"Notification description"}
						value={this.state.modA_description}
						onChange={(e) => this.setState({modA_description: e.target.value})}
					/>

					<Form.Label>Limit direction and value</Form.Label>
					<Form.Control
						as="select"
						placeholder={"Alert type"}
						value={this.state.modA_direction}
						onChange={(e) => this.setState({modA_direction: e.target.value})}
					>
						<option>up</option>
						<option>down</option>
					</Form.Control>
					<Form.Control 
						type="text"
						placeholder={"Limit value number"}
						value={this.state.modA_value}
						onChange={(e) => this.setState({modA_value: e.target.value})}
					/>

					<Form.Label>Tolerance</Form.Label>
					<Form.Control 
						type="text"
						placeholder={"Tolerance value in seconds"}
						value={this.state.modA_tolerance}
						onChange={(e) => this.setState({modA_tolerance: e.target.value})}
					/>

					<Form.Label>Color</Form.Label>
					<Form.Control 
						type="text"
						placeholder={"Color hex e.g. #277000"}
						value={this.state.modA_color}
						onChange={(e) => this.setState({modA_color: e.target.value})}
					/>
					<CirclePicker
						width="410px"
						colors={['#D9E3F0', '#F47373', '#697689', '#37D67A', '#2CCCE4', '#555555', '#dce775', '#ff8a65', '#ba68c8', '#4D4D4D', '#999999', '#FFFFFF', '#F44E3B', '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF', '#333333', '#808080', '#cccccc', '#D33115', '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF', '#000000', '#666666', '#B3B3B3', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1', '#653294', '#AB149E']}
						//triangle="top"
						onChange={ (e) => this.setState({modA_color: e.hex}) }
					/>
					
					</Modal.Body>
					<Modal.Footer>
						<Button variant="danger" onClick={() => this.delDevAlert(this.state.modA_alertId)}>Delete</Button>
						<Button variant="secondary" onClick={this.closeEditAlert}><Lang en="Cancel" fi="Peruuta" sv="Ångra" /></Button>
						<Button variant="dark" onClick={() => this.modifyDevAlert({
							notifRuleId: Number(this.state.modA_alertId),
							userIds: [this.props.userId],
							deviceId: this.state.modA_deviceId,
							description: this.state.modA_description,
							valIndex: Number(this.state.modA_valIndex),
							value: Number(this.state.modA_value),
							direction: this.state.modA_direction,
							tolerance: Number(this.state.modA_tolerance),
							color: this.state.modA_color,
						})}>
							OK
						</Button>
					</Modal.Footer>
					</Form>
			</Modal>
			</div>
			</div>
			
		);
		}
	}
}

function mapStateToProps(state) {
	const { lang, userId, customerId, deviceData } = state;
	return { lang, userId, customerId, deviceData };
}

const mapDispatchToProps = (dispatch) => {
	return {
		setVal: (prop, val) => dispatch({type: "SET_VAL", prop, val}),
		//addAlertData: (val) => dispatch({type: "ADD_ALERT_DATA", val}),
		//editAlertData: (val) => dispatch({type: "EDIT_ALERT_DATA", val}),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageAlerts);