import React, {Component} from "react";

export default class Check extends Component {
	render() {
		return (
			<div
				className="check"
				style={{color: this.props.check ? "#039e24" : "#aa0c01", fontWeight: this.props.check ? "bold" : ""}}
			>
				{this.props.children}
			</div>
		);
	}
}