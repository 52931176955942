import React, {Component} from "react";
import { connect } from "react-redux";
import {Table} from "react-bootstrap";

import Requests from "../../utilities/requests";
import Lang from "../language/language";
import {notificationsRef} from "../notifications";
import Search from "../search/search";
import AddCustomer from "./addCustomer";
import EditCustomer from "./editCustomer";
import DefaultSpinner from "../spinner/defaultSpinner";
import SidePanel from "../sidePanel/sidePanel";

import "./customers.css";

class Customers extends Component {
	constructor() {
		super();

		this.state = {
			filteredCustomers: undefined,
		}

		this.activeRequests = 1;
		this.openEdit = this.openEdit.bind(this);
		this.closeEdit = this.closeEdit.bind(this);
		this.getFilteredCustomers = this.getFilteredCustomers.bind(this);
	}

	componentDidMount() {
		// Requestin customers
		Requests.getCustomers()
			.then((data) => {
				this.activeRequests--;
				if (data.status === "success" && data.customers !== undefined) {
					this.props.setVal("customers", data.customers);
				} else {
					if (notificationsRef && notificationsRef.current)
						notificationsRef.current.addNotification({
							message: <Lang en="Failed to get customers" fi="Asiakkaiden haku epäonnistui" sv="Kunde inte hämta kunder"/>,
							level: "warning"
						})
					else
						console.log("Failed to get customers.")
				}
			})
			.catch((err) => {
				console.log("Error, while getting customers.");
				console.log(err)
			});
	}

	componentWillUnmount() {
		this.activeRequests = 1;
	}

	openEdit({id}) {
		this.setState({editOpen: true, editCustomer: id});
	}

	closeEdit() {
		this.setState({editOpen: false})
	}

	getItems(customers) {
		if (customers !== undefined) {
			let ids = Object.keys(customers);
			ids.sort((a, b) => {
				if (customers[a].toLowerCase() < customers[b].toLowerCase())
					return -1;

				if (customers[a].toLowerCase() > customers[b].toLowerCase())
					return 1;

				return 0;
			});

			return ids.map((id) => {
				return (
					<tr key={"user_" + id} onClick={() => this.openEdit({id})}>
						<td>{customers[id]}</td>
						<td>{id}</td>
					</tr>
				);
			})
		}

		return null;
	}

	getFilteredCustomers(filteredCustomers) {
		this.setState({filteredCustomers});
	}

	render() {
		return (
			<div className="customers">
				<SidePanel>
					<Search returnFiltered={this.getFilteredCustomers} items={this.props.customers}/>
					<AddCustomer />
				</SidePanel>
				<div className="main-panel">
					<h3 className="title"><Lang en="Customers" fi="Asiakkaat" sv="Kunderna"/></h3>
					<EditCustomer isOpen={this.state.editOpen} close={this.closeEdit} customerId={this.state.editCustomer}/>
					{ 	this.activeRequests > 0 ?
						<DefaultSpinner show/>
						:
						<Table className="users-table" striped bordered hover>
							<thead>
								<tr>
									<th><Lang en="Name" fi="Nimi" sv="Namn"/></th>
									<th><Lang en="ID" fi="ID" sv="ID"/></th>
								</tr>
							</thead>
							<tbody>
								{this.getItems(this.state.filteredCustomers ? this.state.filteredCustomers : this.props.customers)}
							</tbody>
						</Table>
					}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { customers, customerData } = state;
	return { customers, customerData };
}

const mapDispatchToProps = (dispatch) => {
	return {
		setVal: (prop, val) => dispatch({type: "SET_VAL", prop, val}),
		addCustomerData: (val) => dispatch({type: "ADD_CUSTOMER_DATA", val}),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Customers);