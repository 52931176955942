import React, { Component } from 'react';
import { connect } from "react-redux";
import {Route, Redirect, withRouter} from 'react-router-dom';


export const PrivateRoute = ({auth, component: Component, ...rest }) => {
	return (
		<Route {...rest} render={props => (
			auth() ? (
				<Component {...props}/>
			) : (
				<Redirect to="/"/>
			)
		)}/>
	)
}


class _RootRedirect extends Component {
	render() {
		if (this.props.token)
			return <Redirect to={this.props.home} />
		else
			return <Redirect to={this.props.login} />
	}
}

export const RootRedirect = withRouter(connect(mapStateToProps)(_RootRedirect));



class _InvalidPathRedirect extends Component {
	render() {
		return <Redirect to="/"/>
	}
}

export const InvalidPathRedirect = withRouter(connect(mapStateToProps)(_InvalidPathRedirect));


function mapStateToProps(state) {
	const { token } = state;
	return { token };
}