import React, { Component } from 'react';
import './App.css';

import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { Provider } from "react-redux";

import {auth} from "./utilities/auth";
import {PrivateRoute, RootRedirect, InvalidPathRedirect} from "./components/routing";
import {initSite} from "./components/siteConfig"
import Login from "./components/login/loginWithCarousel";
// import Home from "./components/home";
import Home from "./components/home/home";
import DeviceDailyView from "./components/deviceDaily/deviceDailyView";
import NavigationBar from "./components/navigation/navigationbar";
import PageWrapper from "./components/pageWrapper";
import store from "./utilities/redux";
import Notifications from './components/notifications';
import Placeholder from './components/placeholder/placeholder';
import SetPassword from "./components/passwordManagement/setPassword";
import ChangePassword from "./components/passwordManagement/changePassword";
import Users from "./components/users/users";
import Customers from "./components/customers/customers";
import Devices from "./components/devices/devices";
import ManageAlerts from "./components/alerts/manageAlerts";
import Analytics from "./components/analytics/analytics";
import DailyView from "./components/dailyView/dailyView";
import WS from "./components/ws";

initSite();

class App extends Component {
  render() {
    return (
      <div className="App">
        <Router>
          <Provider store={store}>
            <Notifications />
            <NavigationBar />
            <WS />

            <PageWrapper>
              <Switch>
                <Route exact path="/" component={(props) =>
                  <RootRedirect login="/login" home="/home" />
                }/>

                <Route path="/login" component={Login}/>
                <Route path="/placeholder" component={Placeholder}/>
                <Route path="/setPassword" component={(props) =>
                  <SetPassword {...props}
                    notificationSystem={this.notificationSystem}
                  />
                }/>

                {<PrivateRoute auth={() => store.getState().token} path="/password" component={(props) =>
                  <ChangePassword {...props}
                    notificationSystem={this.notificationSystem}
                  />
                }/>}

                {<PrivateRoute auth={() => store.getState().token && auth(["customers", "users"], store.getState().rights)} path="/users" component={(props) =>
                  <Users {...props}
                    notificationSystem={this.notificationSystem}
                  />
                }/>}

                {<PrivateRoute auth={() => store.getState().token && auth(["customers"], store.getState().rights)} path="/customers" component={(props) =>
                  <Customers {...props}
                    notificationSystem={this.notificationSystem}
                  />
                }/>}

                {<PrivateRoute auth={() => store.getState().token && auth(["devices", "global_devices"], store.getState().rights)} path="/devices" component={(props) =>
                  <Devices {...props}
                    notificationSystem={this.notificationSystem}
                  />
                }/>}

                {<PrivateRoute auth={() => store.getState().token} path="/analytics" component={(props) =>
                  <Analytics {...props}
                    notificationSystem={this.notificationSystem}
                  />
                }/>}

                <PrivateRoute auth={() => store.getState().token} path="/home" component={(props) =>
                  <Home {...props}
                    notificationSystem={this.notificationSystem}
                  />
                }/>

                <PrivateRoute auth={() => store.getState().token} path="/daily-view" component={(props) =>
                  <DailyView {...props}
                    notificationSystem={this.notificationSystem}
                  />
                }/>

                <PrivateRoute auth={() => store.getState().token} path="/device-daily-view" component={(props) =>
                  <DeviceDailyView {...props}
                    notificationSystem={this.notificationSystem}
                  />
                }/>

                {/* All sites */}
                {<PrivateRoute auth={() => store.getState().token} path="/alerts" component={(props) =>
                  <ManageAlerts {...props}
                    notificationSystem={this.notificationSystem}
                  />
                }/>}

                <Route component={InvalidPathRedirect}/>
              </Switch>
            </PageWrapper>
          </Provider>
        </Router>
      </div>
    );
  }
}

export default App;
