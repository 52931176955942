import React, { Component } from "react";
import { connect } from "react-redux";
import {Modal, Button, Form} from "react-bootstrap";

import Lang, {getLangString} from "../language/language";
import Requests from "../../utilities/requests";
import {notificationsRef} from "../notifications";
import Contacts, { parseContacts, contactsString } from "./contacts";
import DefaultSpinner from "../spinner/defaultSpinner";

import "./editCustomer.css";

class EditCustomer extends Component {
	constructor() {
		super();

		this.state = {
			edits: {},

			deleteMenu: false,
			deleteCustomerName: "",
		}
		
		this.toggleLoginAllowed = this.toggleLoginAllowed.bind(this);
		this.updateText = this.updateText.bind(this);
		this.toggleDeleteMenu = this.toggleDeleteMenu.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.submitDelete = this.submitDelete.bind(this);
		this.submit = this.submit.bind(this);
		this.contactsUpdate = this.contactsUpdate.bind(this);
	}

	componentDidUpdate(prevState) {
		if (this.props.isOpen && this.props.isOpen !== prevState.isOpen) {
			const customerId = this.props.customerId;
			// Requesting data for customer
			Requests.getCustomerData({customerId})
			.then((cData) => {
				if (cData.status === "success") {
					let customerData = {
						customerId,
						customerName: cData.customerName,
						loginAllowed: cData.loginAllowed,
						contacts: cData.contacts !== null ? cData.contacts : "",
					}

					this.setState({edits: {
						customerName: customerData.customerName,
						loginAllowed: customerData.loginAllowed,
						contacts: parseContacts(customerData.contacts),
					}}, () => {
						this.props.addCustomerData({id: customerId, data: customerData});
					})
				}
			})
			.catch((err) => {
				console.log("Error, while getting customer data.");
				console.log(err);
			})
		}
	}

	toggleLoginAllowed() {
		let edits = JSON.parse(JSON.stringify(this.state.edits));
		edits.loginAllowed = !this.state.edits.loginAllowed;
		this.setState({edits});
	}

	updateText(key, string) {
		let edits = JSON.parse(JSON.stringify(this.state.edits));
		edits[key] = string;
		this.setState({edits});
	}

	toggleDeleteMenu() {
		this.setState({deleteMenu: !this.state.deleteMenu});
	}

	closeModal() {
		this.setState({edits: {}}, () => {
			this.props.close()
		})
	}

	submitDelete(e) {
		e.preventDefault();
		if (this.props.customers && this.props.customers[this.props.customerId] && this.state.deleteCustomerName === this.props.customers[this.props.customerId]) {
			Requests.deleteCustomer({customerId: this.props.customerId})
				.then((data) => {
					if (data.status === "success") {
						if (notificationsRef && notificationsRef.current)
							notificationsRef.current.addNotification({
								message: <Lang en="Customer deleted." fi="Asiakas poistettu." sv="Kuden borttagen."/>,
								level: "success"
							})

						// Requestin customers
						Requests.getCustomers()
						.then((data) => {
							if (data.status === "success" && data.customers !== undefined) {
								this.props.setVal("customers", data.customers);
							} else {
								if (notificationsRef && notificationsRef.current)
									notificationsRef.current.addNotification({
										message: <Lang en="Failed to get customers" fi="Asiakkaiden haku epäonnistui" sv="Kunde inte hämta kunder"/>,
										level: "warning"
									})
								else
									console.log("Failed to get customers.")
							}
						})
						.catch((err) => {
							console.log("Error, while getting customers.");
							console.log(err)
						});
					} else {
						if (notificationsRef && notificationsRef.current)
							notificationsRef.current.addNotification({
								message: <Lang en="Failed to delete customer." fi="Asiakkaan poisto epäonnistui." sv="Kunde inte ta bort kunden."/>,
								level: "warning"
							})
					}
				})
			this.props.close();
		} else {
			if (notificationsRef && notificationsRef.current)
					notificationsRef.current.addNotification({
						message: <Lang en="Could not delete customer. Missing data." fi="Asiakasta ei voitu poistaa. Puutteelliset tiedot." sv="Kunden kunde inte tas bort. Information fattas."/>,
						level: "warning"
					})
		}
	}

	submit(e) {
		e.preventDefault();
		const requestValues = {
			customerId: this.props.customerId,
			name: this.state.edits.customerName,
			loginAllowed: this.state.edits.loginAllowed ? 1 : 0,
			contacts: contactsString(this.state.edits.contacts),
		}
		this.setState({edits: {}}, () => {
			Requests.updateCustomer(requestValues)
				.then((data) => {
					if (data && data.status === "success") {
						if (notificationsRef && notificationsRef.current)
							notificationsRef.current.addNotification({
								message: <Lang en="Customer data updated" fi="Asiakkaan tiedot päivitetty" sv="Kundens information updaterades"/>,
								level: "success"
							})

						// Requestin customers
						Requests.getCustomers()
						.then((data) => {
							if (data.status === "success" && data.customers !== undefined) {
								this.props.setVal("customers", data.customers);
							} else {
								if (notificationsRef && notificationsRef.current)
									notificationsRef.current.addNotification({
										message: <Lang en="Failed to get customers" fi="Asiakkaiden haku epäonnistui" sv="Kunde inte hämta kunder"/>,
										level: "warning"
									})
								else
									console.log("Failed to get customers.")
							}
						})
						.catch((err) => {
							console.log("Error, while getting customers.");
							console.log(err)
						});
					} else { 
						if (notificationsRef && notificationsRef.current)
							notificationsRef.current.addNotification({
								message: <Lang en="Failed to update customer infromation" fi="Asiakkaan tietojen päivitys epäonnisui" sv="Uppdateringen av kundens information misslyckades"/>,
								level: "warning"
							})
						else
							console.log("Failed to update user.");
					}
				})
				.catch((err) => {
					console.log("Error, while updating customer data.");
					console.log(err);
				})
				this.closeModal();
		});
	}

	contactsUpdate(contacts) {
		let edits = JSON.parse(JSON.stringify(this.state.edits));
		edits.contacts = contacts;
		this.setState({edits});
	}

	render() {
		return (
			<Modal show={this.props.isOpen} onHide={this.closeModal} className="edit-modal">
				<Modal.Header closeButton>
					<Modal.Title><Lang en="Edit customer" fi="Muokkaa asiakasta" sv="Redigera kund"/></Modal.Title>
				</Modal.Header>
				<Form onSubmit={this.state.deleteMenu ? this.submitDelete : this.submit}>
					<Modal.Body>

						<div className="edit-customer-title">{ this.props.customers && this.props.customers[this.props.customerId] ? this.props.customers[this.props.customerId] : ""}</div>

						{/* Options */}
						{	!this.state.deleteMenu && Object.keys(this.state.edits).length > 0 ?
							<>
								<Form.Label><Lang fi="Asiakkaan nimi" en="Customer name" sv="Kundens namn"/></Form.Label>
								<Form.Control 
									type="text"
									placeholder={getLangString(this.props.lang, {fi: "Asiakkaan nimi", en: "Customer name", sv: "Kundens namn"})}
									value={this.state.edits.customerName}
									onChange={(e) => this.updateText("customerName", e.target.value)}
								/>

								<Form.Check
									className="login-allowed-checkbox"
									type="checkbox"
									label={<Lang en="Login allowed" fi="Saa kirjautua" sv="Får logga in"/>}
									checked={this.state.edits.loginAllowed}
									onChange={this.toggleLoginAllowed}
								/>
								
								<Contacts contacts={this.state.edits.contacts} updateAncestor={this.contactsUpdate} />
							</> : null
						}

						{/* Spinner */}
						<DefaultSpinner show={!this.state.deleteMenu && Object.keys(this.state.edits).length < 1} />

						{/* Delete menu */}
						{	this.state.deleteMenu ?
							<div className="delete-menu">
								<Form.Label><Lang fi="Asiakkaan nimi" en="Customer name" sv="Kundens namn"/></Form.Label>
								<Form.Control
									type="text"
									placeholder={getLangString(this.props.lang, {fi: "Asiakkaan nimi", en: "Customer name", sv: "Kundens namn"})}
									value={this.state.deleteCustomerName}
									onChange={(e) => this.setState({deleteCustomerName: e.target.value})}
								/>
							</div>
							: null
						}
					</Modal.Body>
					<Modal.Footer>
						<Button className="mr-auto" variant={this.state.deleteMenu ? "danger" : "outline-danger"} onClick={this.toggleDeleteMenu}><Lang fi="Poista" en="Delete" sv="Ta bort"/></Button>
						<Button variant="secondary" onClick={this.closeModal}><Lang en="Cancel" fi="Peruuta" sv="Ångra" /></Button>
						<Button type="submit" variant="primary">OK</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		);
	}
}

function mapStateToProps(state) {
	const { customers, customerData } = state;
	return { customers, customerData };
}

const mapDispatchToProps = (dispatch) => {
	return {
		setVal: (prop, val) => dispatch({type: "SET_VAL", prop, val}),
		addCustomerData: (val) => dispatch({type: "ADD_CUSTOMER_DATA", val}),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCustomer);