import React, {Component} from "react";
import { connect } from "react-redux";
import {Dropdown} from "react-bootstrap";
import {LANGUAGE_CODES, LANGUAGE_CODE_TRANSLATIONS, getLangString} from "./language";

import "../../css/languageSelect.css";

class LanguageSelect extends Component {
	constructor() {
		super();

		this.handelSelect = this.handelSelect.bind(this);
		this.getItems = this.getItems.bind(this);
	}

	handelSelect(code) {
		this.props.setLang(code);
	}

	getItems() {
		return LANGUAGE_CODES.map((code) => {
			return (
				<Dropdown.Item
					eventKey={code}
					key={"language-select-item_" + code}
					active={this.props.lang === code}
					onSelect={this.handelSelect}
				>
					{LANGUAGE_CODE_TRANSLATIONS[code] ? LANGUAGE_CODE_TRANSLATIONS[code] : code}
				</Dropdown.Item>
			);
		});
	}

	render() {
		return (
			<Dropdown className="language-select-dropdown">
				<Dropdown.Toggle
					as={"div"}
					id="language-dropdown"
					key="nav-lang-dropdown"
				>
					{this.props.lang && LANGUAGE_CODE_TRANSLATIONS[this.props.lang] ? LANGUAGE_CODE_TRANSLATIONS[this.props.lang] : getLangString(this.props.lang, {fi: "Kieli", en: "Language", sv: "Språk"})}
				</Dropdown.Toggle>
				
				<Dropdown.Menu>
					{this.getItems()}
				</Dropdown.Menu>
			</Dropdown>
		);
	}
}

function mapStateToProps(state) {
	const { lang } = state;
	return { lang };
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLang: (val) => dispatch({type: "SET_LANG", val})
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelect);