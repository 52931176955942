import React, { Component } from "react";
import NotificationSystem from "react-notification-system";

import "../css/notifications.css";

const STYLE = {
	NotificationItem: { // Override the notification item
		DefaultStyle: { // Applied to every notification, regardless of the notification level
			borderRadius: "3px",
		}
	}
}

// Stores the notification system ref and returns the component
export default class Notifications extends Component {
	render() {
		notificationsRef = React.createRef();
		return (
			<NotificationSystem ref={notificationsRef} style={STYLE}/>
		);
	};
}

export let notificationsRef = undefined;

// Validates color input, returns color or default value
const getAlertColor = (color) => {
	let out = "";
	if (color &&
		(color === "orange" || color === "red" || color === "blue")
	) {
		out = color;
	}
	return out;
}

// Returns alert body
export const alertBody = ({message, color}) => {
	return (
		<div
			className={"notification-body wipe " + getAlertColor(color)}
			style={{color: "black", margin: 0}}
		>
			{message}
		</div>
	);
}