import React, { Component } from 'react';
import { connect } from "react-redux";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

import {auth} from "../../utilities/auth";
import { NavItem } from "./navItem";
import { NavDropdownRedirectItem } from "./navDropdownRedirectItem";
import Lang from "../language/language";
import LanguageSelect from "../language/languageSelect";
import Logout from "./logout";

import "../../css/navigationbar.css";
import { isSite } from '../siteConfig';

class NavigationBar extends Component {
	render() {
		if (this.props.token)
			return (
				<Navbar
					bg="dark"
					variant="dark"
					fixed="top"
					collapseOnSelect
					className="navigationBar justify-content-between"
				>
					<Navbar.Brand>
						<Nav>
							<NavItem to={"/"}>
								{getBrandImage()}
							</NavItem>
						</Nav>
					</Navbar.Brand>
					<Navbar.Toggle />
					<Navbar.Collapse>
						<Nav>
							<NavItem className="link-mimic" to={"/home"}>
								<Lang
									en="Summary"
									fi="Tiivistelmä"
									sv="Sammandrag"
								/>
							</NavItem>
							<NavItem className="link-mimic" to={"/daily-view"}>
								<Lang
									en="Daily view"
									fi="Päivänäkymä"
									sv="Dagsvy"
								/>
							</NavItem>
							<NavItem className="link-mimic" to={"/analytics"}>
								<Lang
									en="Analytics"
									fi="Analyysi"
									sv="Analys"
								/>
							</NavItem>
							<NavItem disabled={!auth(["devices"], this.props.rights)} className="link-mimic" to={"/alerts"}>
								<Lang
									en="Notifications"
									fi="Ilmoitukset"
									sv="Aviseringar"
								/>
							</NavItem>
							<NavItem disabled={!auth(["customers"], this.props.rights)} className="link-mimic" to={"/customers"}>
								<Lang
									en="Customers"
									fi="Asiakkaat"
									sv="Kunderna"
								/>
							</NavItem>
							<NavItem disabled={!auth(["customers", "users"], this.props.rights)} className="link-mimic" to={"/users"}>
								<Lang
									en="Users"
									fi="Käyttäjät"
									sv="Anvädare"
								/>
							</NavItem>
							<NavItem disabled={!auth(["devices", "global_devices"], this.props.rights)} className="link-mimic" to={"/devices"}>
								<Lang
									en="Devices"
									fi="Laitteet"
									sv="Enheter"
								/>
							</NavItem>
							<NavDropdown title={this.props.userEmail ? this.props.userEmail : ""} id="user-dropdown">
								<NavDropdownRedirectItem className="dropdownitem" to={"/password"}>
									<Lang
										en="Change password"
										fi="Vaihda salasana"
										sv="Byt lösenord"
									/>
								</NavDropdownRedirectItem>
								<LanguageSelect />
								<Logout/>
							</NavDropdown>
						</Nav>
					</Navbar.Collapse>
				</Navbar>
			);
		else
			return null;
	}
}

function mapStateToProps(state) {
	const { rights, userEmail, token, lang } = state;
	return { rights, userEmail, token, lang };
}

const mapDispatchToProps = (dispatch) => {
	return {
		setVal: (prop, val) => dispatch({type: "SET_VAL", prop, val})
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);


const getBrandImage = () => {
	if (isSite("farm"))
		return <img className="brand-icon" alt="Datasense-icon" src="farm/farmi_favicon.png" />;
	return <img className="brand-icon" alt="Datasense-icon" src="default/ds_192x192.png" />;
}