import React, {Component} from "react";
import { connect } from "react-redux";

// Component that selects correct language string, based on lang value in store, and renders a Component
class Lang extends Component {
	getString(props) {
		if (this.props.lang !== undefined && this.props[this.props.lang] !== undefined)
			return this.props[this.props.lang];
		else if (this.props.en !== undefined)
			return this.props.en;
			
		console.warn("Lang: possible missing props.");
		return "";
	}	

	render() {
		const string = this.getString(this.props);
		if (!this.props.string) {
			return (
				<>{string}</>
			)
		}
		return string;
	}
}

function mapStateToProps(state) {
	const { lang } = state;
	return { lang };
}

const mapDispatchToProps = (dispatch) => {
	return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Lang);

export const getStringFromLang = (Lang, lang) => {
	if (Lang && lang && Lang.props && Lang.props[lang])
		return Lang.props[lang];
	return "";
}


// Function for when string in specified language is needed instead of Component
export const getLangString = (lang, strings) => {
	if (lang && strings) {
		if (strings[lang] !== undefined)
			return strings[lang];
		else if (strings.en !== undefined)
			return strings.en;
	}
		
	return "";
}

export const LANGUAGE_CODES = ["fi", "en", "sv"];

export const LANGUAGE_CODE_TRANSLATIONS = {
	fi: "Suomi",
	sv: "Svenska",
	en: "English",
}