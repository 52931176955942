import React, {Component} from "react";
import { Form, Button} from "react-bootstrap";
import { connect } from "react-redux";

import Lang, { getLangString } from "../language/language";
import isDefined from "../../utilities/isDefined";

import "./contacts.css";

class Contacts extends Component {
	constructor() {
		super();

		this.updateContact = this.updateContact.bind(this);
		this.addContact = this.addContact.bind(this);
		this.removeContact = this.removeContact.bind(this);
	}

	updateContact(prop, index, e) {
		if (isDefined({prop, index}) && this.props.updateAncestor) {
			let contacts = JSON.parse(JSON.stringify(this.props.contacts));
			contacts[index][prop] = e.target.value;
			this.props.updateAncestor(contacts);
		}
	}

	addContact() {
		if (this.props.updateAncestor) {
			let contacts = JSON.parse(JSON.stringify(this.props.contacts));
			contacts.push(new Contact())
			this.props.updateAncestor(contacts)
		}
	}

	removeContact(index) {
		if (this.props.updateAncestor) {
			let contacts = JSON.parse(JSON.stringify(this.props.contacts));
			contacts.splice(index, 1);
			this.props.updateAncestor(contacts)
		}
	}

	getContacts(contacts) {
		return contacts.map((contact, i) => {
			return (
				<div className="contact" key={"contact_" + i}>
					<Form.Control 
						type="text"
						placeholder={getLangString(this.props.lang, {en:"Name", fi:"Nimi", sv:"Namn"})}
						value={contact.name}
						onChange={(e) => {this.updateContact("name", i, e)}}
					/>
					<Form.Control 
						type="email"
						placeholder={getLangString(this.props.lang, {en:"E-mail", fi:"Sähköposti", sv:"E-post"})}
						value={contact.email}
						onChange={(e) => {this.updateContact("email", i, e)}}
					/>
					<Form.Control
						type="tel"
						placeholder={getLangString(this.props.lang, {en:"Tel.", fi:"Puh.", sv:"Tel."})}
						value={contact.phone}
						onChange={(e) => {this.updateContact("phone", i, e)}}
					/>
					<Form.Control 
						className="txtarea"
						as="textarea"
						placeholder={getLangString(this.props.lang, {en:"Notes", fi:"Muistiinpanot", sv:"Anteckningar"})}
						value={contact.notes}
						onChange={(e) => {this.updateContact("notes", i, e)}}
					/>
					<Button onClick={() => this.removeContact(i)} variant="outline-danger"><Lang en="Remove" fi="Poista" sv="Ta bort"/></Button>
				</div>
			);
		});
	}

	render() {
		if (this.props.contacts) {
			return (
				<div className="contacts">
					{this.getContacts(this.props.contacts)}
					<Button size="sm" block variant="secondary" onClick={this.addContact}><Lang en="Add contact" fi="Lisää yhteyshenkilö" sv="Tillägg kontakt"/></Button>
				</div>
			);
		}
		return null;
	}
}

function mapStateToProps(state) {
	const { lang } = state;
	return { lang };
}

const mapDispatchToProps = (dispatch) => {
	return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);


class Contact {
	constructor() {
		this.name = "";
		this.email = "";
		this.phone = "";
		this.notes = "";
	}
}

export const parseContacts = (string) => {
	if (string === "" || string === null) {
		return [new Contact()];
	} else {
		try {
			return JSON.parse(string);
		} catch (err) {
			let newContact = new Contact();
			newContact.notes = string;
			return [newContact];
		}
	}
}

export const contactsString = (contacts) => {
	if (typeof contacts === "string") {
		if (contacts === "")
			return null;
		else
			return contacts;
	} else {
		return JSON.stringify(contacts);
	}
}