import React, { Component } from 'react';

import "./placeholder.css";

export default class Placeholder extends Component {
	render() {
		return (
			<div className="placeholder">
				<div className="content">
					<h3>Senseportal</h3>
					<div>Something here soon</div>
				</div>
			</div>
		);
	}
}