import React, {Component} from "react";
import { connect } from "react-redux";
import moment from "moment";
import { isMobile } from "react-device-detect";

import { notificationsRef } from "../notifications";
import Requests from "../../utilities/requests";
import {MSToS} from "../../utilities/time";
import Lang, {getStringFromLang} from "../language/language";
import isDefined from "../../utilities/isDefined";
import {QUANTITY_TRANSLATIONS} from "../language/translations";
import DeviceSelect from "../deviceSelect/deviceSelect";
import TimeSelector from "../timeSelector/timeSelector";
import DefaultSpinner from "../spinner/defaultSpinner";
import SidePanel from "../sidePanel/sidePanel";

import "./analytics.css";

import * as Plotly from 'plotly.js/dist/plotly';
import * as dictFin from 'plotly.js/lib/locales/fi.js';
import * as dictSwe from 'plotly.js/lib/locales/sv.js';
import createPlotlyComponent from 'react-plotly.js/factory';

const Plot = createPlotlyComponent(Plotly);
Plotly.register(dictFin);
Plotly.register(dictSwe);

class Analytics extends Component {
	constructor() {
		super();

		this.state = {
			activeRequests: 0,
			selectedDeviceId: undefined,
		}

		this.getDatasets = this.getDatasets.bind(this);
	}

	componentDidMount() {
		if (this.props.customerId) {
			this.setState({activeRequests: this.state.activeRequests + 1}, () => {
				Requests.getDevices({customerId: this.props.customerId})
				.then((data) => {
					this.setState({activeRequests: this.state.activeRequests - 1}, () => {
						if (data.status === "success") {
							let devices = {};
							data.devices.forEach((device) => {
								Object.assign(devices, device);
							});
		
							const ids = Object.keys(devices);
							let requestCount = ids.length;
								this.setState({activeRequests: this.state.activeRequests + 1}, () => {
									ids.forEach((id) => {
										// Requesting data for device
										Requests.getDeviceData({deviceId: id})
										.then((dData) => {
											requestCount--;
											if (requestCount < 1) {
												this.setState({activeRequests: this.state.activeRequests - 1}, () => {
													if (dData.status === "success") {
														let deviceData = {
															customerId: dData.customerId,
															description: dData.description,
															correctionValues: dData.correctionValues,
															colors: dData.colors,
															location: dData.location,
															quantities: dData.quantities,
															units: dData.units,
															units_short: dData.units_short,
														}

														this.props.addDeviceData({deviceId: id, data: deviceData});	
													}
												});
											} else {
												if (dData.status === "success") {
													let deviceData = {
														customerId: dData.customerId,
														description: dData.description,
														correctionValues: dData.correctionValues,
														colors: dData.colors,
														location: dData.location,
														quantities: dData.quantities,
														units: dData.units,
														units_short: dData.units_short,
													}
			
													this.props.addDeviceData({deviceId: id, data: deviceData});					
												}
											}
											
										})
										.catch((err) => {
											console.log("Error, while getting device data.");
											console.log(err);
										})
									})
								});
							
								this.props.setVal("analyticsView_devices", devices);
						} else {
							if (notificationsRef && notificationsRef.current)
								notificationsRef.current.addNotification({
									message: <Lang en="Failed to get devices" fi="Laitteiden haku epäonnistui" sv="Kunde inte hämta enheter"/>,
									level: "warning"
								})
						}
					})
				})
				.catch(err => {
					console.warn("Devices fetch error: ", err)
				});
			});
		}
	}

	getUnit({devId, measIndex, deviceData}) {
		if (isDefined({devId, measIndex, deviceData}) && deviceData[devId] && deviceData[devId].units_short && deviceData[devId].units_short[measIndex]) {
			return deviceData[devId].units_short[measIndex] === "C" ? "°C" : deviceData[devId].units_short[measIndex];
		}
		return "";
	}

	getTitle({devId, measIndex, deviceData, lang}) {
		if (isDefined({devId, measIndex, deviceData}) && deviceData[devId] && deviceData[devId].quantities && deviceData[devId].quantities[measIndex]) {
			return QUANTITY_TRANSLATIONS[deviceData[devId].quantities[measIndex]] ? getStringFromLang(QUANTITY_TRANSLATIONS[deviceData[devId].quantities[measIndex]], lang) : deviceData[devId].quantities[measIndex];
		}
		return "";
	}

	getDatasets({deviceIds, measurements, deviceData}) {
		let datasets = [];
		if (isDefined({deviceIds})) {
			Object.keys(deviceIds).forEach(devId => {
				if (isDefined({devId, measurements, deviceData}) && measurements[devId] && deviceData[devId] && deviceData[devId].quantities) {
					const times = measurements[devId].t.map(t => {
						return new Date(t * 1000);
					});
		
					let newDatasets = [];
					measurements[devId].vals.forEach(meas => {
						newDatasets = deviceData[devId].quantities.reduce((res, quantity, i) => {
							if (res[i] === undefined) {
								res[i] = {
									// legendgroup: devId,
									visible: true,
									type: 'scattergl',
									mode: 'lines',
									fill: 'none',
									line: {
										width: 2,
										shape: "spline",
									},
									name: (deviceData[devId] && deviceData[devId].description ? deviceData[devId].description : devId) + " " + getStringFromLang(QUANTITY_TRANSLATIONS[quantity], this.props.lang) + " [" + (deviceData[devId].units_short && deviceData[devId].units_short[i] ? deviceData[devId].units_short[i] : "") + "]",
									text: (deviceData[devId] && deviceData[devId].description ? deviceData[devId].description : devId) + " " + getStringFromLang(QUANTITY_TRANSLATIONS[quantity], this.props.lang) + " [" + (deviceData[devId].units_short && deviceData[devId].units_short[i] ? deviceData[devId].units_short[i] : "") + "]",
									hoverinfo: "x+y+text",
									x: times,
									y: []
								}
							}
							res[i].y.push(meas[i]);
							return res;
						}, newDatasets);
					});

					if (newDatasets.length > 0) {
						datasets = datasets.concat(newDatasets);
					}
				}
			})
		}
		
		return datasets;
	}

	componentDidUpdate(prevProps) {
		if (
			this.props.selectedDeviceIds !== undefined && Object.keys(this.props.selectedDeviceIds).length > 0 &&
			(
				prevProps.selectedDeviceIds === undefined ||
				Object.keys(prevProps.selectedDeviceIds).length !== Object.keys(this.props.selectedDeviceIds).length ||
				prevProps.selectedTimeInterval.start !== this.props.selectedTimeInterval.start ||
				prevProps.selectedTimeInterval.end !== this.props.selectedTimeInterval.end
			)
		) {
			/* Getting measurements */
			const requestValues = {
				deviceIds: Object.keys(this.props.selectedDeviceIds),
				amount: null,
				start: MSToS(this.props.selectedTimeInterval.start),
				end: MSToS(this.props.selectedTimeInterval.end),
			}

			this.setState({activeRequests: this.state.activeRequests + 1}, () => {
				Requests.getMeasurements(requestValues)
				.then((data) => {
					this.setState({activeRequests: this.state.activeRequests - 1}, () => {
						if (data.status === "success") {
							this.props.setVal("analyticsView_measurements", data.measurements);
						} else {
							if (notificationsRef && notificationsRef.current)
								notificationsRef.current.addNotification({
									message: <Lang en="Failed to get measurements" fi="Mittausten haku epäonnistui" sv="Kunde inte hämta mätningar"/>,
									level: "warning"
								})
						}
					})
				})
				.catch((err) => {
					console.warn("Measurments fetch error: ", err)
				});
			});
		}
	}

	render() {
		return (
			<div className="analytics">
				<SidePanel>
					<TimeSelector />
					<DeviceSelect devices={this.props.analyticsView_devices} clearOnMount/>
				</SidePanel>
				<div className="main-panel">
					<h3 className="title"><Lang en="Analytics" fi="Analyysi" sv="Analyys"/></h3>
					<DefaultSpinner show={this.state.activeRequests > 0} />
					{ Object.keys(this.props.selectedDeviceIds).length < 1 || this.activeRequests > 0 ? null :
						<div className="plotly-wrapper">
							<Plot
								useResizeHandler={true}
								data={this.getDatasets({deviceIds: this.props.selectedDeviceIds, measurements: this.props.analyticsView_measurements, deviceData: this.props.analyticsView_deviceData})}
								layout={{
									margin: {
										t: 50,
									},
									title: {
										text: "",
										yref: "paper",
									},
									yaxis: {
										hoverformat: ".2f",
										title: {
											text: "",
										}
									},
									hovermode: "closest",
									showlegend: true,
									legend: {
										orientation: "h",
										y: -0.20,
									},
									autosize: true
								}}
								config={{
									displayModeBar: isMobile ? false : true,
									modeBarButtonsToRemove: ["toImage", "sendDataToCloud"],
									modeBarButtonsToAdd: [[{
										name: 'Image',
										icon: Plotly.Icons.camera,
										click: function (gd) {
											Plotly.downloadImage(gd, {
												filename: 'farmiaisti_' + moment().format("YYYYMMDD_HHmmss"),
												format: 'png',
												width: gd._fullLayout.width,
												height: gd._fullLayout.height
											})
										}
									}]],
									locale: this.props.lang,
									displaylogo: false,
								}}
								style={{width: "100%", height: "100%"}}
							/>
						</div>
					}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { analyticsView_devices, analyticsView_deviceData, analyticsView_measurements, customerId, selectedDeviceIds, selectedTimeInterval, lang } = state;
	return { analyticsView_devices, analyticsView_deviceData, analyticsView_measurements, customerId, selectedDeviceIds, selectedTimeInterval, lang };
}

const mapDispatchToProps = (dispatch) => {
	return {
		setVal: (prop, val) => dispatch({type: "SET_VAL", prop, val}),
		addDeviceData: ({deviceId, data}) => dispatch({type: "ADD_VIEW_DEVICE_DATA", propPrefix: "analyticsView_", deviceId, data}),
		addAnalyticsViewDeviceProfile: (val) => dispatch({type: "ADD_ANALYTICSVIEW_DEVICE_PROFILE_DATA", val}),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
