import React from "react";

import Lang from "./language";

export const QUANTITY_TRANSLATIONS = {
	humidity: <Lang en="Humidity" fi="Kosteus" sv="Fuktighet"/>,
	battery_voltage: <Lang en="Voltage" fi="Jännite" sv="Spänning"/>,
	temperature: <Lang en="Temperature" fi="Lämpötila" sv="Temperatur"/>,
	pressure: <Lang en="Pressure" fi="Paine" sv="Tryck"/>,
	frequency: <Lang en="Frequency" fi="Taajuus" sv="Frekvens"/>,
	resistance: <Lang en="Resistance" fi="Vastus" sv="Resistans"/>,
}