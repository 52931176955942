import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import { connect } from "react-redux";
import {Form, Button} from "react-bootstrap";
import queryString from "query-string";
import DefaultSpinner from "../spinner/defaultSpinner";
import { IoIosCheckmarkCircleOutline, IoIosWarning } from "react-icons/io";

import Lang, {getLangString} from "../language/language";
import {notificationsRef} from "../notifications";
import Requests from "../../utilities/requests";
import Check from "./check";

import "./setPassword.css";

class SetPassword extends Component {
	constructor() {
		super();

		this.state = {
			time: 5,
			redirecting: false,
			firstPass: "",
			verificationPass: "",
			status: null,
			activeRequest: 0,
		}

		this.countDown = this.countDown.bind(this);
		this.submit = this.submit.bind(this);
	}

	countDown() {
		if (this.state.time > 0) {
			this.setState({time: this.state.time - 1 }, () => {
				setTimeout(this.countDown, 1000);
			})
		} else {
			this.props.history.push("/");
		}
	}

	submit(e, token, email) {
		e.preventDefault();
		if (this.state.firstPass.length >= 8 && /\d/.test(this.state.firstPass) && this.state.firstPass === this.state.verificationPass) {
			this.setState({activeRequest: this.state.activeRequest + 1}, () => {
				Requests.setPassword({password: this.state.firstPass, token})
				.then((data) => {
					this.setState({activeRequest: this.state.activeRequest - 1}, () => {
						if (data && data.status === "success") {
							this.setState({redirecting: true, status: "success"}, () => {
								setTimeout(this.countDown, 1000);
								this.props.setVal("userEmail", email);
							});
						} else {
							if (notificationsRef && notificationsRef.current)
								notificationsRef.current.addNotification({
									message: <Lang en="Failed to set password." fi="Salasanan asetus epäonnistui." sv="Kunde inte inställa lösenordet."/>,
									level: "error"
								})
						}
					})
				})
				.catch((err) => {
					console.log("Error, while setting password.");
					console.log(err);
					this.setState({activeRequest: this.state.activeRequest - 1});
					if (notificationsRef && notificationsRef.current)
							notificationsRef.current.addNotification({
								message: <Lang en="Something went wrong. Please refresh the page" fi="Jokin meni väärin. Virkistä sivu." sv="Något gick fel. Uppdater sidan."/>,
								level: "error"
							})
				});
			});			
		} else {
			this.setState({status: "fail"}, () => {
				if (notificationsRef && notificationsRef.current)
					notificationsRef.current.addNotification({
						message: <Lang en="Failed, please check the requirements." fi="Epäonnistui, tarkista vaatimukset." sv="Misslyckades, granska kraven."/>,
						level: "warning"
					})
			})
		}
	}

	getStyle(status) {
		if (status === "fail") {
			return {backgroundColor: "#ddafaf"}
		} else if (status === "success") {
			return {backgroundColor: "#3daa44"}
		}
		return {};
	}

	render() {
		const queryParams = queryString.parse(this.props.location.search);
		return (
			<div className="set-password">
				<div className="content-wrapper">
					<h3 className="title">
						{queryParams.verification ? <Lang en="User activation" fi="Käyttäjän aktivointi" sv="Aktivering av andändare"/> : <Lang en="Password reset" fi="Salasanan palautus" sv="Återställning av lösenord"/>}
					</h3>
					{queryParams.email ? <div className="email">{queryParams.email}</div> : null}
					{ this.state.status === "success" ? 
						<div className="success">
							<div className="icon-wrapper"><IoIosCheckmarkCircleOutline color="#3daa44" size="50px" classname="checkmark status-icon" /></div>
							<Lang en="Success" fi="Onnistui" sv="Lyckades"/>
						</div> : null
					}
					{ this.state.redirecting ? 
						<div className="redirecting">
							<Lang en="Redirecting in " fi="Ohjataan " sv="Leder till inloggning om "/>{this.state.time}<Lang en=" seconds" fi=" sekunnin kuluttua" sv="sekunder"/>
						</div> : null
					}
					{ this.state.status !== "success" ?
						<>
							<DefaultSpinner show={this.state.activeRequest > 0} />
							{this.state.status === "fail" && this.state.activeRequest < 1 ? <div className="icon-wrapper"><IoIosWarning className="warningicon status-icon" color="rgb(255, 174, 0)" size="50px" /></div> : null}
							<div className="checks">
								<Check check={this.state.firstPass.length >= 8}><Lang en="At least 8 characters" fi="Vähintään 8 merkkiä" sv="Mist 8 tecken"/></Check>
								<Check check={/\d/.test(this.state.firstPass)}><Lang en="Contains numbers" fi="Sisältää numeroita" sv="Innhåller nummer"/></Check>
								<Check check={this.state.firstPass.length > 0 && this.state.firstPass === this.state.verificationPass}><Lang en="Passwords matche" fi="Salasanat täsmäävät" sv="Lösenorden matchar"/></Check>
							</div>
							<form
								onSubmit={(e) => this.submit(e, queryParams.token, queryParams.email)}
							>
								<Form.Label><Lang fi="Salasana" en="Password" sv="Lösenord"/></Form.Label>
								<Form.Control 
									type="password"
									placeholder={getLangString(this.props.lang, {fi: "Salasana", en: "Password", sv: "Lösenord"})}
									value={this.state.firstPass}
									onChange={(e) => this.setState({firstPass: e.target.value})}
								/>

								<Form.Label><Lang fi="Vahvistus" en="Verification" sv="Bekräftelse"/></Form.Label>
								<Form.Control 
									type="password"
									placeholder={getLangString(this.props.lang, {fi: "Vahvistus", en: "Verification", sv: "Bekräftelse"})}
									value={this.state.verificationPass}
									onChange={(e) => this.setState({verificationPass: e.target.value})}
								/>
								<div className="btn-wrapper">
									<Button type="submit">OK</Button>
								</div>
							</form>
						</> : null
					}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { userEmail, lang } = state;
	return { userEmail, lang };
}

const mapDispatchToProps = (dispatch) => {
	return {
		setVal: (prop, val) => dispatch({type: "SET_VAL", prop, val})
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SetPassword));