import { Component } from "react";
import { connect } from "react-redux";
import store from "../utilities/redux";
import uuid from "uuid/v4";

import isDefined from "../utilities/isDefined";

const WScon = {
	ws: undefined,
	reconnectCounter: 0,
	reopening: false,
	subscribedForMeasurements: false,
	subDeviceIds: {},
}

// Creating connection and setting up handlers
const openWS = (WScon) => {
	const HOST = window.location.hostname.includes("localhost") ? "devsenseportal.datasense.fi" : window.location.hostname;
	WScon.ws = new WebSocket("wss://" + HOST + "/wsapi/");

	WScon.ws.onerror = onError;
	WScon.ws.onopen = onOpen.bind(this, WScon);
	WScon.ws.onclose = onClose.bind(this, WScon);
	WScon.ws.onmessage = onMessage;
}

const onOpen = () => {
	console.log("WS open");
}

const onMessage = (e) => {
	let msg = {};
	if (e && e.data) {
		msg = JSON.parse(e.data)
	}

	if (msg.status === "success") {
		switch(msg.api) {
			case "/api/version/":
				msgVersion(msg);
				break;
			case "/api/subscribe_for_measurements/":
				msgSubForMeas(msg);
				break;
			case "/api/unsubscribe_from_measurements/":
				msgUnsubFromMeas(msg);
				break;
			default:				
				console.log("Unknown WS msg: ", msg.api);
		}
	} else if (msg.status === undefined) {
		switch(msg.api) {
			case "measurements":
				msgMeas(msg);
				break;
			default:
				console.warn("Unknown WS message: ", msg);
		}
	}
}

const onClose = (WScon, e) => {
	console.log('Closed socket', e.code)
	if(WScon.reconnectCounter < 10){
		let exec = () =>{
			console.log('reopening...', WScon.ws.readyState);
			openWS(WScon);
		}
		WScon.reopening = setTimeout(exec.bind(this), 1000);
	} else if (WScon.reconnectCounter > 10 && WScon.reconnectCounter < 20) {
		let exec = () =>{
			console.log('(>10)reopening...', WS.readyState);
			openWS(WScon);
		}
		WScon.reopening = setTimeout(exec.bind(this), 5000);
	} else {
		let exec = () =>{
			console.log('(>20)reopening...', WScon.ws.readyState);
			openWS(WScon);
		}
		WScon.reopening = setTimeout(exec.bind(this), 20000);
	}
}

const onError = (e) => {
	console.log('WebSocket error: ', e);
}

class WS extends Component {
	componentDidUpdate(prevProps) {
		if ( prevProps.token !== this.props.token ) {
			if (WScon.ws) {
				if (!this.props.token) {
					WScon.ws.onclose =  undefined;
					WScon.ws.close();
					if (WScon.reopening)
						clearTimeout(WScon.reopening);
				}
			} else if (this.props.token) {
				openWS(WScon);
			} else {
				if (WScon.reopening) {
					WScon.ws.onclose =  undefined;
					clearTimeout(WScon.reopening);
				}
			}
		}
	}

	render() {
		return null;
	}
}

function mapStateToProps(state) {
	const { token } = state;
	return { token };
}

const mapDispatchToProps = (dispatch) => {
	return {
		setVal: (prop, val) => dispatch({type: "SET_VAL", prop, val}),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(WS);



const sendMsg = (msg) => {
	if(WScon.ws){
		if(WScon.ws.readyState === WScon.ws.OPEN) {
			msg.request_id = uuid();
			WScon.ws.send(JSON.stringify(msg));
		} else if(WScon.ws.readyState === WScon.ws.CONNECTING) {
			let exec = () =>{
				sendMsg(msg);
			}
			setTimeout(exec.bind(this), 500);
		} else if(WScon.ws.readyState === WScon.ws.CLOSED || WScon.ws.readyState === WScon.ws.CLOSING) {
			let exec = () =>{
				sendMsg(msg);
			}
			setTimeout(exec.bind(this), 500);
		}
	} else {
		let exec = () =>{
			sendMsg(msg);
		}
		setTimeout(exec.bind(this), 500);
	}
}


export const subscribeForMeasurements = ({deviceIds}) => {
	if ( isDefined({deviceIds}) ) {
		let newDevs = deviceIds.reduce((res, id) => {
			if (WScon.subDeviceIds[id] === undefined)
				res[id] = true;
			return res;
		}, {});

		if ( Object.keys(newDevs).length > 0 ) {
			Object.assign(WScon.subDeviceIds, newDevs);
			const payload = {api: "/api/subscribe_for_measurements/", deviceIds: Object.keys(newDevs), token: store.getState().token};
			sendMsg(payload);
		}
	}
}

const msgSubForMeas = (msg) => {
	if (msg.status === "success") {
		WScon.subscribedForMeasurements = true;
		console.log("Subscribed for measurements");
	}
}

const msgMeas = (msg) => {
	if (msg.timestamp && msg.deviceId && msg.measurements) {
		store.dispatch({type: "ADD_MEASUREMENT", prop: "tabletView_measurements", measurements: msg.measurements, timestamp: msg.timestamp, deviceId: msg.deviceId});
		store.dispatch({type: "ADD_MEASUREMENT", prop: "dailyView_measurements", measurements: msg.measurements, timestamp: msg.timestamp, deviceId: msg.deviceId});
	}
}

export const unsubscribeFromMeasurements = () => {
	if ( Object.keys(WScon.subDeviceIds).length > 0 ) {
		const payload = {api: "/api/unsubscribe_from_measurements/", deviceIds: Object.keys(WScon.subDeviceIds), token: store.getState().token};
		sendMsg(payload);
	}
}

const msgUnsubFromMeas = (msg) => {
	if (msg.status === "success") {
		WScon.subDeviceIds = {};
		console.log("Unsubscribed from measurements");
	}
}


export const apiVersion = () => {
	const payload = {api: "/api/version/"};
	sendMsg(payload);
}

const msgVersion = (msg) => {
	if (msg.version) {
		console.log("api version: ", msg.version);
	} else {
		console.warn("Something went wrong with ws api version request.");
	}
}