import isDefined from "./isDefined";
import store from "./redux";

const logServerError = (data) => {
	if (data.status === "error" && data.msg !== undefined)
		console.log("Server error message: ", data.msg);
}

const login = ({username, password}) => {
	return new Promise((resolve, reject) => {
		if (isDefined({username, password})) {
			const payload = JSON.stringify({username, password});
			fetch('../api/login/', {
				'method': 'post',
				'headers': {
					'accept': 'application/json',
					'Content-Type': 'application/json',
				},
				'body': payload
			})
			.then(res => res.json())
			.then(data => {
				logServerError(data);
				resolve(data);
			}).catch((err) => {
				console.log("Login failed.");
				console.log("Error: ", err);
				reject({status: "promise_error"});
			});
		} else {
			reject({status: "missing_data"});
		}
	})
	.catch((err) => {
		console.log("Promise error: ", err);
	})
}

const getUsers = ({customerId}) => {
	return new Promise((resolve, reject) => {
		if (isDefined({customerId})) {
			const payload = JSON.stringify({customerId, token: store.getState().token})
			fetch("../api/get_users/", {
				'method': 'post',
				'headers': {
					'accept': 'application/json',
					'Content-Type': 'application/json',
				},
				'body': payload
			})
			.then(res => res.json())
			.then(data => {
				logServerError(data);
				resolve(data);
			}).catch((err) => {
				console.log("Getting users failed.");
				console.log("Error: ", err);
				reject({status: "promise_error"});
			});
		} else {
			reject({status: "missing_data"});
		}
	})
	.catch((err) => {
		console.log("Promise error: ", err);
	});
}

const getUserData = ({userId}) => {
	return new Promise((resolve, reject) => {
		if (isDefined({userId})) {
			const payload = JSON.stringify({userId, token: store.getState().token})
			fetch("../api/get_user/", {
				'method': 'post',
				'headers': {
					'accept': 'application/json',
					'Content-Type': 'application/json',
				},
				'body': payload
			})
			.then(res => res.json())
			.then(data => {
				logServerError(data);
				resolve(data);
			}).catch((err) => {
				console.log("Getting user data failed.");
				console.log("Error: ", err);
				reject({status: "promise_error"});
			});
		} else {
			reject({status: "missing_data"});
		}
	})
	.catch((err) => {
		console.log("Promise error: ", err);
	});
}

const addNewUser = ({customerId, userName, rights, notifSms, notifDeviceToken, notifEmail, hostname}) => {
	return new Promise((resolve, reject) => {
		if (isDefined({customerId, userName, rights, notifSms, notifDeviceToken, notifEmail, hostname})) {
			const payload = JSON.stringify({customerId, userName, rights, notifSms, notifDeviceToken, notifEmail, hostname, token: store.getState().token})
			fetch("../api/new_user/", {
				'method': 'post',
				'headers': {
					'accept': 'application/json',
					'Content-Type': 'application/json',
				},
				'body': payload
			})
			.then(res => res.json())
			.then(data => {
				logServerError(data);
				resolve(data);
			}).catch((err) => {
				console.log("Adding new user failed.");
				console.log("Error: ", err);
				reject({status: "promise_error"});
			});
		} else {
			reject({status: "missing_data"});
		}
	})
	.catch((err) => {
		console.log("Promise error: ", err);
	});
}

const setPassword = ({password, token}) => {
	return new Promise((resolve, reject) => {
		if (isDefined({password, token})) {
			const payload = JSON.stringify({password, token});
			fetch("../api/set_password/", {
				'method': 'post',
				'headers': {
					'accept': 'application/json',
					'Content-Type': 'application/json',
				},
				'body': payload
			})
			.then(res => res.json())
			.then(data => {
				logServerError(data);
				resolve(data);
			}).catch((err) => {
				console.log("Failed to set password.");
				console.log("Error: ", err);
				reject({status: "promise_error"});
			});
		} else {
			reject({status: "missing_data"});
		}
	})
	.catch((err) => {
		console.log("Promise error: ", err);
	});
}

const changePassword = ({oldPassword, newPassword}) => {
	return new Promise((resolve, reject) => {
		if (isDefined({oldPassword, newPassword})) {
			const payload = JSON.stringify({oldPassword, newPassword, token: store.getState().token})
			fetch("../api/change_password/", {
				'method': 'post',
				'headers': {
					'accept': 'application/json',
					'Content-Type': 'application/json',
				},
				'body': payload
			})
			.then(res => res.json())
			.then(data => {
				logServerError(data);
				resolve(data);
			}).catch((err) => {
				console.log("Failed to set password.");
				console.log("Error: ", err);
				reject({status: "promise_error"});
			});
		} else {
			reject({status: "missing_data"});
		}
	})
	.catch((err) => {
		console.log("Promise error: ", err);
	});
}

const deleteUser = ({userId}) => {
	return new Promise((resolve, reject) => {
		if (isDefined({userId})) {
			const payload = JSON.stringify({userId, token: store.getState().token});
			fetch("../api/delete_user/", {
				'method': 'post',
				'headers': {
					'accept': 'application/json',
					'Content-Type': 'application/json',
				},
				'body': payload
			})
			.then(res => res.json())
			.then(data => {
				logServerError(data);
				resolve(data);
			}).catch((err) => {
				console.log("Failed to delete user.");
				console.log("Error: ", err);
				reject({status: "promise_error"});
			});
		} else {
			reject({status: "missing_data"});
		}
	})
	.catch((err) => {
		console.log("Promise error: ", err);
	});
}

const updateUser = ({userId, rights, notifSms, notifDeviceToken, notifEmail}) => {
	return new Promise((resolve, reject) => {
		if (isDefined({userId, rights, notifSms, notifDeviceToken, notifEmail})) {
			const payload = JSON.stringify({userId, rights, notifSms, notifDeviceToken, notifEmail, token: store.getState().token})
			fetch("../api/update_user/", {
				'method': 'post',
				'headers': {
					'accept': 'application/json',
					'Content-Type': 'application/json',
				},
				'body': payload
			})
			.then(res => res.json())
			.then(data => {
				logServerError(data);
				resolve(data);
			}).catch((err) => {
				console.log("Failed to update user.");
				console.log("Error: ", err);
				reject({status: "promise_error"});
			});
		} else {
			reject({status: "missing_data"});
		}
	})
	.catch((err) => {
		console.log("Promise error: ", err);
	});
}

const getCustomers = () => {
	return new Promise((resolve, reject) => {
		const payload = JSON.stringify({token: store.getState().token})
		fetch("../api/get_customers/", {
			'method': 'post',
			'headers': {
				'accept': 'application/json',
				'Content-Type': 'application/json',
			},
			'body': payload
		})
		.then(res => res.json())
		.then(data => {
			logServerError(data);
			resolve(data);
		}).catch((err) => {
			console.log("Getting customers failed.");
			console.log("Error: ", err);
			reject({status: "promise_error"});
		});
	})
	.catch((err) => {
		console.log("Promise error: ", err);
	});
}

const getCustomerData = ({customerId}) => {
	return new Promise((resolve, reject) => {
		if (isDefined({customerId})) {
			const payload = JSON.stringify({customerId, token: store.getState().token})
			fetch("../api/get_customer/", {
				'method': 'post',
				'headers': {
					'accept': 'application/json',
					'Content-Type': 'application/json',
				},
				'body': payload
			})
			.then(res => res.json())
			.then(data => {
				logServerError(data);
				resolve(data);
			}).catch((err) => {
				console.log("Getting customer data failed.");
				console.log("Error: ", err);
				reject({status: "promise_error"});
			});
		} else {
			reject({status: "missing_data"});
		}
	})
	.catch((err) => {
		console.log("Promise error: ", err);
	});
}

const addNewCustomer = ({name, loginAllowed, contacts}) => {
	return new Promise((resolve, reject) => {
		if (isDefined({name, loginAllowed, contacts})) {
			const payload = JSON.stringify({name, loginAllowed, contacts, token: store.getState().token})
			fetch("../api/new_customer/", {
				'method': 'post',
				'headers': {
					'accept': 'application/json',
					'Content-Type': 'application/json',
				},
				'body': payload
			})
			.then(res => res.json())
			.then(data => {
				logServerError(data);
				resolve(data);
			}).catch((err) => {
				console.log("Creating new customer failed.");
				console.log("Error: ", err);
				reject({status: "promise_error"});
			});
		} else {
			reject({status: "missing_data"});
		}
	})
	.catch((err) => {
		console.log("Promise error: ", err);
	});
}

const deleteCustomer = ({customerId}) => {
	return new Promise((resolve, reject) => {
		if (isDefined({customerId})) {
			const payload = JSON.stringify({customerId, token: store.getState().token})
			fetch("../api/delete_customer/", {
				'method': 'post',
				'headers': {
					'accept': 'application/json',
					'Content-Type': 'application/json',
				},
				'body': payload
			})
			.then(res => res.json())
			.then(data => {
				logServerError(data);
				resolve(data);
			}).catch((err) => {
				console.log("Failed to delete customer.");
				console.log("Error: ", err);
				reject({status: "promise_error"});
			});
		} else {
			reject({status: "missing_data"});
		}
	})
	.catch((err) => {
		console.log("Promise error: ", err);
	});
}

const updateCustomer = ({customerId, name, loginAllowed, contacts}) => {
	return new Promise((resolve, reject) => {
		if (isDefined({customerId, name, loginAllowed, contacts})) {
			const payload = JSON.stringify({customerId, name, loginAllowed, contacts, token: store.getState().token})
			fetch("../api/update_customer/", {
				'method': 'post',
				'headers': {
					'accept': 'application/json',
					'Content-Type': 'application/json',
				},
				'body': payload
			})
			.then(res => res.json())
			.then(data => {
				logServerError(data);
				resolve(data);
			}).catch((err) => {
				console.log("Failed to update user.");
				console.log("Error: ", err);
				reject({status: "promise_error"});
			});
		} else {
			reject({status: "missing_data"});
		}
	})
	.catch((err) => {
		console.log("Promise error: ", err);
	});
}

const getDevices = ({customerId}) => {
	return new Promise((resolve, reject) => {
		if (isDefined({customerId})) {
			const payload = JSON.stringify({customerId, token: store.getState().token});
			fetch("../api/get_devices/", {
				'method': 'post',
				'headers': {
					'accept': 'application/json',
					'Content-Type': 'application/json',
				},
				'body': payload
			})
			.then(res => res.json())
			.then(data => {
				logServerError(data);
				resolve(data);
			})
			.catch((err) => {
				console.log("Failed to get devices.");
				console.log("Error: ", err);
				reject({status: "promise_error"});
			});
		} else {
			reject({status: "missing_data"});
		}
	})
	.catch((err) => {
		console.log("Promise error: ", err);
	});
}

const addNewDevice = ({deviceId, description, customerId, correctionValues, colors, location}) => {
	return new Promise((resolve, reject) => {
		if (isDefined({deviceId, description, customerId, correctionValues, colors, location})) {
			const payload = JSON.stringify({deviceId, description, customerId, correctionValues, colors, location, token: store.getState().token});
			fetch("../api/new_device/", {
				'method': 'post',
				'headers': {
					'accept': 'application/json',
					'Content-Type': 'application/json',
				},
				'body': payload
			})
			.then(res => res.json())
			.then(data => {
				logServerError(data);
				resolve(data);
			})
			.catch((err) => {
				console.log("Failed to get devices.");
				console.log("Error: ", err);
				reject({status: "promise_error"});
			});
		} else {
			reject({status: "missing_data"});
		}
	})
	.catch((err) => {
		console.log("Promise error: ", err);
	});
}

const getDeviceData = ({deviceId}) => {
	return new Promise((resolve, reject) => {
		if (isDefined({deviceId})) {
			const payload = JSON.stringify({deviceId, token: store.getState().token});
			fetch("../api/get_device/", {
				'method': 'post',
				'headers': {
					'accept': 'application/json',
					'Content-Type': 'application/json',
				},
				'body': payload
			})
			.then(res => res.json())
			.then(data => {
				logServerError(data);
				resolve(data);
			})
			.catch((err) => {
				console.log("Failed to get devices.");
				console.log("Error: ", err);
				reject({status: "promise_error"});
			});
		} else {
			reject({status: "missing_data"});
		}
	})
	.catch((err) => {
		console.log("Promise error: ", err);
	});
}

const deleteDevice = ({deviceId}) => {
	return new Promise((resolve, reject) => {
		if (isDefined({deviceId})) {
			const payload = JSON.stringify({deviceId, token: store.getState().token});
			fetch("../api/delete_device/", {
				'method': 'post',
				'headers': {
					'accept': 'application/json',
					'Content-Type': 'application/json',
				},
				'body': payload
			})
			.then(res => res.json())
			.then(data => {
				logServerError(data);
				resolve(data);
			})
			.catch((err) => {
				console.log("Failed to delete device.");
				console.log("Error: ", err);
				reject({status: "promise_error"});
			});
		} else {
			reject({status: "missing_data"});
		}
	})
	.catch((err) => {
		console.log("Promise error: ", err);
	});
}

const updateDevice = ({deviceId, customerId, description, correctionValues, colors, location}) => {
	return new Promise((resolve, reject) => {
		if (isDefined({deviceId, customerId, description, correctionValues, colors, location})) {
			const payload = JSON.stringify({deviceId, customerId, description, correctionValues, colors, location, token: store.getState().token});
			fetch("../api/update_device/", {
				'method': 'post',
				'headers': {
					'accept': 'application/json',
					'Content-Type': 'application/json',
				},
				'body': payload
			})
			.then(res => res.json())
			.then(data => {
				logServerError(data);
				resolve(data);
			})
			.catch((err) => {
				console.log("Failed to update device.");
				console.log("Error: ", err);
				reject({status: "promise_error"});
			});
		} else {
			reject({status: "missing_data"});
		}
	})
	.catch((err) => {
		console.log("Promise error: ", err);
	});
}

const getMeasurements = ({deviceIds, amount, start, end}) => {
	return new Promise((resolve, reject) => {
		if (isDefined({deviceIds, amount, start, end})) {
			const payload = JSON.stringify({deviceIds, amount, start, end, token: store.getState().token});
			fetch("../api/get_measurements/", {
				'method': 'post',
				'headers': {
					'accept': 'application/json',
					'Content-Type': 'application/json',
				},
				'body': payload
			})
			.then(res => res.json())
			.then(data => {
				logServerError(data);
				resolve(data);
			})
			.catch((err) => {
				console.log("Failed to get measurements.");
				console.log("Error: ", err);
				reject({status: "promise_error"});
			});
		} else {
			reject({status: "missing_data"});
		}
	})
	.catch((err) => {
		console.log("Promise error: ", err);
	});
}

const getLatestMeasurements = ({deviceIds}) => {
	return new Promise((resolve, reject) => {
		if (isDefined({deviceIds})) {
			const payload = JSON.stringify({deviceIds, token: store.getState().token});
			// console.log("get_measurements payload: ", payload);
			fetch("../api/get_latest_measurements/", {
				'method': 'post',
				'headers': {
					'accept': 'application/json',
					'Content-Type': 'application/json',
				},
				'body': payload
			})
			.then(res => res.json())
			.then(data => {
				logServerError(data);
				resolve(data);
			})
			.catch((err) => {
				console.log("Failed to get measurements.");
				console.log("Error: ", err);
				reject({status: "promise_error"});
			});
		} else {
			reject({status: "missing_data"});
		}
	})
	.catch((err) => {
		console.log("Promise error: ", err);
	});
}


const getDeviceAlerts = (reqData) => {
	return new Promise((resolve, reject) => {
		if (isDefined(reqData)) {
			reqData.token = store.getState().token;
			const payload = JSON.stringify(reqData);
			//console.log("alerts req payload: ", payload);
			fetch("../api/get_notification_rules/", {
				'method': 'post',
				'headers': {
					'accept': 'application/json',
					'Content-Type': 'application/json',
				},
				'body': payload
			})
			.then(res => res.json())
			.then(data => {
				logServerError(data);
				resolve(data);
			})
			.catch((err) => {
				console.log("Failed to get measurements.");
				console.log("Error: ", err);
				reject({status: "promise_error"});
			});
		} else {
			reject({status: "missing_data"});
		}
	})
	.catch((err) => {
		console.log("Promise error: ", err);
	});
}


const addAlert = (reqData) => {
	return new Promise((resolve, reject) => {
		if (isDefined(reqData)) {
			reqData.token = store.getState().token; //adding token to request data
			console.log("addAlert data",reqData);
			const payload = JSON.stringify(reqData);
			console.log("alert add payload: ", payload);

			fetch("../api/new_notification_rule/", {
				'method': 'post',
				'headers': {
					'accept': 'application/json',
					'Content-Type': 'application/json',
				},
				'body': payload
			})
			.then(res => res.json())
			.then(data => {
				logServerError(data);
				resolve(data);
			})
			.catch((err) => {
				console.log("Failed to get measurements.");
				console.log("Error: ", err);
				reject({status: "promise_error"});
			});
		} else {
			reject({status: "missing_data"});
		}
	})
	.catch((err) => {
		console.log("Promise error: ", err);
	});
}

const getAlert = (reqData) => {
	return new Promise((resolve, reject) => {
		if (isDefined(reqData)) {
			reqData.token = store.getState().token;
			const payload = JSON.stringify(reqData);
			//console.log("alert req payload: ", payload);
			fetch("../api/get_notification_rule/", {
				'method': 'post',
				'headers': {
					'accept': 'application/json',
					'Content-Type': 'application/json',
				},
				'body': payload
			})
			.then(res => res.json())
			.then(data => {
				logServerError(data);
				resolve(data);
			})
			.catch((err) => {
				console.log("Failed to get measurements.");
				console.log("Error: ", err);
				reject({status: "promise_error"});
			});
		} else {
			reject({status: "missing_data"});
		}
	})
	.catch((err) => {
		console.log("Promise error: ", err);
	});
}

const modifyAlert = (reqData) => {
	return new Promise((resolve, reject) => {
		if (isDefined(reqData)) {
			reqData.token = store.getState().token;
			const payload = JSON.stringify(reqData);
			console.log("alert mod payload: ", payload);
			fetch("../api/update_notification_rule/", {
				'method': 'post',
				'headers': {
					'accept': 'application/json',
					'Content-Type': 'application/json',
				},
				'body': payload
			})
			.then(res => res.json())
			.then(data => {
				logServerError(data);
				resolve(data);
			})
			.catch((err) => {
				console.log("Failed to get measurements.");
				console.log("Error: ", err);
				reject({status: "promise_error"});
			});
		} else {
			reject({status: "missing_data"});
		}
	})
	.catch((err) => {
		console.log("Promise error: ", err);
	});
}

const deleteAlert = (reqData) => {
	return new Promise((resolve, reject) => {
		if (isDefined(reqData)) {
			reqData.token = store.getState().token;
			const payload = JSON.stringify(reqData);
			console.log("alert del payload: ", payload);
			fetch("../api/delete_notification_rule/", {
				'method': 'post',
				'headers': {
					'accept': 'application/json',
					'Content-Type': 'application/json',
				},
				'body': payload
			})
			.then(res => res.json())
			.then(data => {
				logServerError(data);
				resolve(data);
			})
			.catch((err) => {
				console.log("Failed to get measurements.");
				console.log("Error: ", err);
				reject({status: "promise_error"});
			});
		} else {
			reject({status: "missing_data"});
		}
	})
	.catch((err) => {
		console.log("Promise error: ", err);
	});
}

const Requests = {
	login,

	getUsers,
	getUserData,
	addNewUser,
	deleteUser,
	updateUser,

	setPassword,
	changePassword,

	getCustomers,
	getCustomerData,
	addNewCustomer,
	deleteCustomer,
	updateCustomer,

	getDevices,
	addNewDevice,
	getDeviceData,
	deleteDevice,
	updateDevice,
	getMeasurements,
	getLatestMeasurements,

	//alerts/notifications
	getDeviceAlerts,
	addAlert,
	getAlert,
	modifyAlert,
	deleteAlert,

}

export default Requests;