// Utility for validating object variables
const isDefined = (obj, silent) => {
	const keys = Object.keys(obj);
	const undef = keys.filter((key) => {
		if (obj[key] === undefined) {
			return true;
		}
		return false;
	});

	if (!silent && undef.length > 0) {
		console.log("Undefined object variables: ", undef);
	}
	return undef.length < 1;
};

export default isDefined;