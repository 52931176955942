import React, {Component} from "react";
import { connect } from "react-redux";

import Lang from "../language/language";
import Requests from "../../utilities/requests";
import Tablets from "./tablet";
import { notificationsRef } from "../notifications";
import {subscribeForMeasurements} from "../ws";
import DefaultSpinner from "../spinner/defaultSpinner";

import "./home.css"

class Home extends Component {
	constructor() {
		super();

		this.state = {
			activeRequests: 0,
		}
	}

	profileInjection(devId) {
		switch(devId) {
			// case "0004A30B00E8DE86":
			// 	return "twostretch";
			// case "479B558000240048":
			// 	return "three";
			default:
				return undefined;
		}
	}

	componentDidMount() {
		if (this.props.customerId) {
			this.setState({activeRequests: this.state.activeRequests + 1}, () => {
				Requests.getDevices({customerId: this.props.customerId})
				.then((data) => {
					this.setState({activeRequests: this.state.activeRequests - 1}, () => {
						if (data.status === "success") {
							let devices = {};
							data.devices.forEach((device) => {
								Object.assign(devices, device);
							});
		
							const ids = Object.keys(devices);
							// apiVersion();
							subscribeForMeasurements({deviceIds: ids});
							let requestCount = ids.length;
								this.setState({activeRequests: this.state.activeRequests + 1}, () => {
									ids.forEach((id) => {
										// Requesting data for device
										Requests.getDeviceData({deviceId: id})
										.then((dData) => {
											requestCount--;
											if (requestCount < 1) {
												this.setState({activeRequests: this.state.activeRequests - 1}, () => {
													if (dData.status === "success") {
														let deviceData = {
															customerId: dData.customerId,
															description: dData.description,
															correctionValues: dData.correctionValues,
															colors: dData.colors,
															location: dData.location,
															quantities: dData.quantities,
															units: dData.units,
															units_short: dData.units_short,
															profile: this.profileInjection(id),
														}

														this.props.addDeviceData({deviceId: id, data: deviceData});	
													}
												});
											} else {
												if (dData.status === "success") {
													let deviceData = {
														customerId: dData.customerId,
														description: dData.description,
														correctionValues: dData.correctionValues,
														colors: dData.colors,
														location: dData.location,
														quantities: dData.quantities,
														units: dData.units,
														units_short: dData.units_short,
														profile: this.profileInjection(id),
													}
			
													this.props.addDeviceData({deviceId: id, data: deviceData});					
												}
											}
											
										})
										.catch((err) => {
											console.log("Error, while getting device data.");
											console.log(err);
										})
									})
								});
							
								this.props.setVal("tabletView_devices", devices);
						} else {
							if (notificationsRef && notificationsRef.current)
								notificationsRef.current.addNotification({
									message: <Lang en="Failed to get devices" fi="Laitteiden haku epäonnistui" sv="Kunde inte hämta enheter"/>,
									level: "warning"
								})
						}
					})
				})
				.catch(err => {
					console.warn("Devices fetch error: ", err)
				});
			});
		}
	}

	render() {
		return (
			<div className="home farm">
				<DefaultSpinner show={this.state.activeRequests > 0} />
				{this.state.activeRequests > 0 ? null : 
					<div className="content">
						<h3 className="title"><Lang en="Summary" fi="Tiivistelmä" sv="Sammandrag"/></h3>
						<Tablets />
					</div>
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { tabletView_devices, tabletView_deviceData, tabletView_measurements, customerId } = state;
	return { tabletView_devices, tabletView_deviceData, tabletView_measurements, customerId };
}

const mapDispatchToProps = (dispatch) => {
	return {
		setVal: (prop, val) => dispatch({type: "SET_VAL", prop, val}),
		addDeviceData: ({deviceId, data}) => dispatch({type: "ADD_VIEW_DEVICE_DATA", propPrefix: "tabletView_", deviceId, data}),
		addTabletViewDeviceProfile: (val) => dispatch({type: "ADD_TABLETVIEW_DEVICE_PROFILE_DATA", val}),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);


