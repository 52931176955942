const sites = {
	farm: {
		active: false,
		title: "Farmiaisti",
		favicon: "farm/farmi_favicon.png",
		manifest: "farm/manifest.json",
		themeColor: "#37c871",
	},
	vx: {
		active: false,
		title: "VX",
		favicon: "default/ds_32x32.png",
		manifest: "vx/manifest.json",
	},
	iaq: {
		active: false,
		title: "IAQ",
		favicon: "default/ds_32x32.png",
		manifest: "iaq/manifest.json",
	}
}

let currentSite = "default";

export const initSite = () => {
	if (window.location.origin.includes("vx.datasense.fi") || window.location.origin.includes("devvx.datasense.fi")) {
		sites.vx.active = true;
	} else if (window.location.origin.includes("farmiaisti.datasense.fi") || window.location.origin.includes("devfarmiaisti.datasense.fi")) {
		sites.farm.active = true;
	} else if (window.location.origin.includes("iaq.datasense.fi") || window.location.origin.includes("deviaq.datasense.fi")) {
		sites.iaq.active = true;
	}

	const keys = Object.keys(sites);
	for (let i = 0; i < keys.length; ++i) {
		if (sites[keys[i]].active) {
			// Setting title
			if (sites[keys[i]].title)
				document.title = sites[keys[i]].title;
			// Setting favicon
			if (sites[keys[i]].favicon)
				document.getElementById("faviconlink").href = sites[keys[i]].favicon;
			// Setting manifest
			if (sites[keys[i]].manifest)
				document.getElementById("manifestlink").href = sites[keys[i]].manifest;
			// Setting theme color
			if (sites[keys[i]].themeColor)
				document.querySelector("meta[name=theme-color]").setAttribute("content", sites[keys[i]].themeColor);
			// Setting site
			currentSite = keys[i];
		
			break;
		}
	}
}

// Function to check if site is active
export const isSite = (string) => {
	if (typeof string === "string")
		return currentSite === string;

	return false;
}

export const siteVersion = 21;