import moment from "moment";

export const getLastOclock = (hour) => {
	if (hour && hour >= 0 && hour < 24) {
		if (moment().hours() >= hour) {
			return moment().hours(hour).minutes(0).seconds(0).milliseconds(0).valueOf();
		}
		
		return moment().subtract(24, "hours").hours(hour).minutes(0).seconds(0).milliseconds(0).valueOf();
	}
	console.warn("Invalid hour input for getting last o'clock");
}

export const MSToS = (ms) => {
	if (ms)
		return parseInt(ms / 1000, 10);
	return null;
}