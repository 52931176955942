import React, {Component} from "react";
import {withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import {NavDropdown} from "react-bootstrap";
import Lang from "../language/language";
import {unsubscribeFromMeasurements} from "../ws";

class _Logout extends Component {
	constructor() {
		super();

		this.handleClick = this.handleClick.bind(this);
	}

	handleClick() {
		unsubscribeFromMeasurements();
		this.props.disLogout();
		this.props.history.push("/");
	}

	render() {
		return (
			<NavDropdown.Item onClick={this.handleClick}>
				<Lang
					en="Logout"
					fi="Kirjaudu ulos"
					sv="Logga ut"
				/>
			</NavDropdown.Item>
		);
	}
}

function mapStateToProps(state) {
	const { token } = state;
	return { token };
}

const mapDispatchToProps = (dispatch) => {
	return {
		disLogout: () => dispatch({type: "LOGOUT"})
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(_Logout));