import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import {getLangString} from "../language/language";

import "./search.css"

class Search extends Component {
	constructor() {
		super();

		this.onChange = this.onChange.bind(this);
	}

	componentWillUnmount() {
		this.props.setVal("searchText", "");
	}

	onChange(e) {
		this.props.setVal("searchText", e.target.value);
		if (this.props.items && this.props.returnFiltered) {
			const keys = Object.keys(this.props.items);
			const pattern = new RegExp(e.target.value, "i");
			const validKeys = keys.filter(key => pattern.test(this.props.items[key]) || pattern.test(key));
			this.props.returnFiltered(validKeys.reduce((res, key) => {
					res[key] = this.props.items[key];
					return res;
				}, {})
			);
		}
	}

	render() {
		if (this.props.items) {
			return (
				<Form.Control 
					className="search"
					type="text"
					placeholder={getLangString(this.props.lang, this.props.placeholder ? this.props.placeholder : {fi: "Suodatus", en: "Filter", sv: "Filter"})}
					value={this.props.searchText}
					onChange={this.onChange}
				/>
			);
		}

		return null;
	}
}

function mapStateToProps(state) {
	const { lang } = state;
	return { lang };
}

const mapDispatchToProps = (dispatch) => {
	return {
		setVal: (prop, val) => dispatch({type: "SET_VAL", prop, val})
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);