import React, {Component} from "react";
import { connect } from "react-redux";
import {Modal, Button, Form} from "react-bootstrap";

import Lang, {getLangString} from "../language/language";
import Requests from "../../utilities/requests";
import {notificationsRef} from "../notifications";
import DefaultSpinner from "../spinner/defaultSpinner";

import "./editDevice.css";

class EditDevice extends Component {
	constructor() {
		super();

		this.state = {
			edits: {},
			deleteID: "",
			activeRequests: 0,
		}

		this.submit = this.submit.bind(this);
		this.submitDelete = this.submitDelete.bind(this);
		this.updateText = this.updateText.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.handleLocationChange = this.handleLocationChange.bind(this);
		this.toggleDeleteMenu = this.toggleDeleteMenu.bind(this);
	}

	componentDidUpdate(prevState) {
		if (this.props.isOpen && this.props.isOpen !== prevState.isOpen) {
			const deviceId = this.props.deviceId;
			this.setState({activeRequests: this.state.activeRequests + 1}, () => {
				// Requesting data for customer
				Requests.getDeviceData({deviceId})
				.then((dData) => {
					this.setState({activeRequests: this.state.activeRequests - 1}, () => {
						if (dData.status === "success") {
							let loc = dData.location ? dData.location.split(",") : ["60.388485","23.106666"];
							let deviceData = {
								customerId: dData.customerId,
								description: dData.description,
								correctionValues: dData.correctionValues,
								colors: dData.colors,
								lat: loc[0] !== undefined ? loc[0] : "60.388485",
								long: loc[1] !== undefined ? loc[1] : "23.106666",
								quantities: dData.quantities,
								units: dData.units,
								units_short: dData.units_short,
							}

							this.setState({edits: {
								description: deviceData.description,
								lat: deviceData.lat,
								long: deviceData.long,
							}}, () => {
								this.props.addDeviceData({id: deviceId, data: deviceData});
							})
						}
					})
				})
				.catch((err) => {
					console.log("Error, while getting device data.");
					console.log(err);
				});
			});
			
		}
	}

	submit(e) {
		e.preventDefault();
		this.props.close();
		const requestValues = {
			deviceId: this.props.deviceId,
			customerId: this.props.selectedCustomerId ? this.props.selectedCustomerId : this.props.customerId,
			correctionValues: null,
			colors: null,
			description: this.state.edits.description,
			location: (this.state.edits.lat !== "" ? this.state.edits.lat : "60.388485") + "," + (this.state.edits.long !== "" ? this.state.edits.long : "23.106666"),
		};

		Requests.updateDevice(requestValues)
			.then((data) => {
				if (data && data.status === "success") {
					if (notificationsRef && notificationsRef.current)
						notificationsRef.current.addNotification({
							message: <Lang en="Device data updated" fi="Laitteen tiedot päivitetty" sv="Enhetens information updaterades"/>,
							level: "success"
						})
					const id = this.props.deviceId;
					// Requesting data for device
					Requests.getDeviceData({deviceId: id})
						.then((dData) => {
							if (dData.status === "success") {
								let deviceData = {
									customerId: dData.customerId,
									description: dData.description,
									correctionValues: dData.correctionValues,
									colors: dData.colors,
									location: dData.location,
									quantities: dData.quantities,
									units: dData.units,
									units_short: dData.units_short,
								}
								this.props.addDeviceData({id, data: deviceData});
							}
						})
						.catch((err) => {
							console.log("Error, while getting device data.");
							console.log(err);
						})
				} else { 
					
					if (notificationsRef && notificationsRef.current)
						notificationsRef.current.addNotification({
							message: <Lang en="Failed to update device infromation" fi="Laitteen tietojen päivitys epäonnisui" sv="Uppdateringen av enhtens information misslyckades"/>,
							level: "warning"
						})
					else
						console.log("Failed to update device.");
				}
			});
		this.setState({edits: {}});
	}

	submitDelete(e) {
		e.preventDefault();
		if (this.props.deviceId && this.state.deleteID === this.props.deviceId) {
			Requests.deleteDevice({deviceId: this.props.deviceId})
				.then((data) => {
					if (data.status === "success") {
						if (notificationsRef && notificationsRef.current)
							notificationsRef.current.addNotification({
								message: <Lang en="Device deleted." fi="Laite poistettu." sv="Enheten borttagen."/>,
								level: "success"
							})
					} else {
						if (notificationsRef && notificationsRef.current)
							notificationsRef.current.addNotification({
								message: <Lang en="Failed to delete device." fi="Laitteen poisto epäonnistui." sv="Kunde inte ta bort enheten."/>,
								level: "warning"
							})
					}
				})
			this.props.close();
		} else {
			if (notificationsRef && notificationsRef.current)
					notificationsRef.current.addNotification({
						message: <Lang en="Could not delete device. Missing data." fi="Laitetta ei voitu poistaa. Puutteelliset tiedot." sv="Enheten kunde inte tas bort. Information fattas."/>,
						level: "warning"
					})
		}
	}

	closeModal() {
		this.setState({edits: {}}, () => {
			this.props.close();
		});
	}

	updateText(key, string) {
		let edits = JSON.parse(JSON.stringify(this.state.edits));
		edits[key] = string;
		this.setState({edits});
	}

	handleLocationChange(e, variable) {
		let text = e.target.value.replace(",", ".");
		const substr = text.substring(text.length - 1, text.length);
		if (text === "" || substr.match(new RegExp("[0-9]", "g")) || substr === ".") {
			let edits = JSON.parse(JSON.stringify(this.state.edits));
			edits[variable] = text;
			this.setState({edits});
		}
	}

	toggleDeleteMenu() {
		this.setState({deleteMenu: !this.state.deleteMenu});
	}

	render() {
		return (
			<Modal show={this.props.isOpen} onHide={this.closeModal} className="edit-modal">
				<Modal.Header closeButton>
					<Modal.Title><Lang en="Edit device" fi="Muokkaa laitetta" sv="Redigera enhet"/></Modal.Title>
				</Modal.Header>
				<Form onSubmit={this.state.deleteMenu ? this.submitDelete : this.submit}>
					<Modal.Body>

						{/* <div className="edit-device-title">{ this.props.devices && this.props.devices[this.props.deviceId] ? this.props.devices[this.props.deviceId] : ""}</div> */}
						<div className="edit-device-title">{ this.props.deviceId ? this.props.deviceId : ""}</div>

						<DefaultSpinner show={this.state.activeRequests > 0} />

						{	this.state.deleteMenu || Object.keys(this.state.edits).length < 1 || this.state.activeRequests > 0 ? null :
							<>
								<div className="edits">
									<Form.Label><Lang en="Description" fi="Kuvaus" sv="Beskrivning"/></Form.Label>
									<Form.Control 
										type="text"
										placeholder={getLangString(this.props.lang, {en: "Description", fi: "Kuvaus", sv: "Beskrivning"})}
										value={this.state.edits.description}
										onChange={(e) => this.updateText("description", e.target.value)}
									/>
								</div>
								<div className="location">
									<div className="latitude">
										<Form.Label><Lang en="Latitude" fi="Leveysaste" sv="Latitud"/></Form.Label>
										<Form.Control 
											type="text"
											placeholder={getLangString(this.props.lang, {en: "Latitude", fi: "Leveysaste", sv: "Latitud"})}
											value={this.state.edits.lat}
											onChange={(e) => this.handleLocationChange(e, "lat")}
										/>
									</div>
									<div className="longitude">
										<Form.Label><Lang en="Longitude" fi="Pituusaste" sv="Longitud"/></Form.Label>
										<Form.Control 
											type="text"
											placeholder={getLangString(this.props.lang, {en: "Longitude", fi: "Pituusaste", sv: "Longitud"})}
											value={this.state.edits.long}
											onChange={(e) => this.handleLocationChange(e, "long")}
										/>
									</div>
								</div>
							</>
						}

						{	this.state.deleteMenu  && this.state.activeRequests < 1 ?
							<div className="delete-menu">
								<Form.Label><Lang fi="ID" en="ID" sv="ID"/></Form.Label>
								<Form.Control 
									type="text"
									placeholder={getLangString(this.props.lang, {fi: "ID", en: "ID", sv: "ID"})}
									value={this.state.deleteID}
									onChange={(e) => this.setState({deleteID: e.target.value})}
								/>
							</div>
							: null
						}
					</Modal.Body>
					<Modal.Footer>
						<Button className="mr-auto" variant={this.state.deleteMenu ? "danger" : "outline-danger"} onClick={this.toggleDeleteMenu}><Lang fi="Poista" en="Delete" sv="Ta bort"/></Button>
						<Button variant="secondary" onClick={this.closeModal}><Lang en="Cancel" fi="Peruuta" sv="Ångra" /></Button>
						<Button type="submit" variant="primary">OK</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		);
	}
}

function mapStateToProps(state) {
	const { lang, deviceData, customerId, selectedCustomerId} = state;
	return { lang, deviceData, customerId, selectedCustomerId };
}

const mapDispatchToProps = (dispatch) => {
	return {
		setVal: (prop, val) => dispatch({type: "SET_VAL", prop, val}),
		addDeviceData: (val) => dispatch({type: "ADD_DEVICE_DATA", val}),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDevice);