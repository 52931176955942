import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import { connect } from "react-redux";

class PageWrapper extends Component {
	render() {
		return (
			<div className="pageWrapper" style={{backgroundColor: "#F5F5F5", marginTop: this.props.token ? "40px": "0px", height: this.props.token ? "calc(100vh - 40px)" : "100vh"}}>
				{this.props.children}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { token } = state;
	return { token };
}

const mapDispatchToProps = (dispatch) => {
	return {
		setVal: (prop, val) => dispatch({type: "SET_VAL", prop, val})
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageWrapper));