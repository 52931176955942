import React, { Component } from 'react';
import {withRouter } from 'react-router-dom';
import {NavDropdown} from 'react-bootstrap';

class _NavDropdownRedirectItem extends Component{

    handleClick(){
        if(this.props.onClick){
            this.props.onClick();
        }
        this.props.history.push(this.props.to);
	}
	
    render() {
        return(
			<NavDropdown.Item
				className={"nav-dropdown-redirect-item"}
				onClick={this.handleClick.bind(this)}
			>
                {this.props.children}
            </NavDropdown.Item>
        );
    }
}

_NavDropdownRedirectItem.defaultProps = {
    className: "",
}

export const NavDropdownRedirectItem = withRouter(_NavDropdownRedirectItem);