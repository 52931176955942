import React, {Component} from "react";
import { connect } from "react-redux";
import { Accordion, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import {withRouter} from "react-router-dom";
import moment from "moment";

import {notificationsRef} from "../notifications";
import {QUANTITY_TRANSLATIONS} from "../language/translations";
import Requests from "../../utilities/requests";
import Lang from "../language/language";
import DefaultSpinner from "../spinner/defaultSpinner";

import "./tablet.css";
import waterdrop_icon from "./water_drop.png";
import weight_icon from "./weight.png";
import temp_icon from "./temp.png";
// import default_icon from "./default.png";
import missing_icon from "./missing.png";
import isDefined from "../../utilities/isDefined";

class Tablets extends Component {
	constructor() {
		super();
		this.getStyleData = this.getStyleData.bind(this);
	}

	getStyleData({devId, measIndex, deviceData, tabletViewProfiles}) {
		if (devId && measIndex !== undefined && deviceData && deviceData[devId] && tabletViewProfiles[deviceData[devId]] && tabletViewProfiles[deviceData[devId]][measIndex]) {
			return tabletViewProfiles[deviceData[devId]][measIndex];
		}

		// default
		return {startIndex: (measIndex % 2) * 6, segments: 6};
	}

	getTitle({devId, devices}) {
		if (devId !== undefined) {
			if (devices && devices[devId] !== undefined) {
				return devices[devId];
			}
			return devId;
		}
		return "";
	}

	getUnit({deviceData, devId, entryProfile}) {
		if (isDefined({deviceData, devId, entryProfile})) {
			if (entryProfile && entryProfile.unit) {
				return entryProfile.unit;
			} else if (entryProfile.measIndex && deviceData[devId].units && deviceData[devId].units[entryProfile.measIndex]) {
				return  deviceData[devId].units_short[entryProfile.measIndex];
			}
		}
		return null;
	}

	genTablets({devices, deviceData, tabletViewProfiles}) {
		if (devices !== undefined) {
			return Object.keys(devices).reduce((res, devId) => {
				if (deviceData[devId] !== undefined) {
					let boxes = null;

					if (deviceData && deviceData[devId] && deviceData[devId].profile && tabletViewProfiles[deviceData[devId].profile] && tabletViewProfiles[deviceData[devId].profile].layout) {
						boxes = tabletViewProfiles[deviceData[devId].profile].layout.map((entryProfile, i) => {
							return (
								<Box
									key={"box_" + i}
									quantity={entryProfile.measIndex !== undefined && deviceData && deviceData[devId] && deviceData[devId].quantities && deviceData[devId].quantities[entryProfile.measIndex] ? deviceData[devId].quantities[entryProfile.measIndex] : 0}
									unit_short={this.getUnit({deviceData, devId, entryProfile})}
									devId={devId}
									measIndex={entryProfile.measIndex}
									styleData={entryProfile}
								/>
							)
						});
					} else if (deviceData[devId] !== undefined && deviceData[devId].quantities !== undefined) {
						boxes = deviceData[devId].quantities.map((quantity, i) => {
							return (
								<Box
									key={"box_" + i}
									quantity={quantity}
									unit_short={deviceData[devId].units && deviceData[devId].units_short[i] ? deviceData[devId].units_short[i] : null}
									devId={devId}
									measIndex={i}
									styleData={this.getStyleData({devId, measIndex: i, deviceData, tabletViewProfiles})}
								/>
							)
						});
					}

					res.push(
						<div className="tablet-wrapper" key={"tablet_" + devId}>
							<Tablet title={this.getTitle({devId, devices})} devId={devId}>
								{boxes}
							</Tablet>
						</div>
					)
				}
				return res;
			}, []);
		}
		return null;
	}

	render() {
		return (
			<div className="tablets">
				{	
					this.genTablets({
						devices: this.props.tabletView_devices,
						deviceData: this.props.tabletView_deviceData,
						tabletViewProfiles: this.props.tabletViewProfiles
					})
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { tabletView_devices, tabletView_deviceData, tabletViewProfiles } = state;
	return { tabletView_devices, tabletView_deviceData, tabletViewProfiles};
}

const mapDispatchToProps = (dispatch) => {
	return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Tablets);



class _Tablet extends Component {
	constructor() {
		super();

		this.state = {
			activeRequests: 0,
		}
	}
	getTime(devId, measurements) {
		if (devId && measurements && measurements[devId] && measurements[devId].t) {
			const time = moment(measurements[devId].t[measurements[devId].t.length-1]  * 1000);
			return <span className="time"> <span className="minor">{time.format("HH:mm")}</span> <span className="major">{time.format("DD.MM.YYYY")}</span></span>
		}
		return null;
	}

	componentDidMount() {
		/* Getting measurements */
		const requestValues = {
			deviceIds: [this.props.devId],
		}

		this.setState({activeRequests: this.state.activeRequests + 1}, () => {
			Requests.getLatestMeasurements(requestValues)
			.then((data) => {
				this.setState({activeRequests: this.state.activeRequests - 1}, () => {
					if (data.status === "success") {
						this.props.addTabletViewMeasurements(data.measurements);
					} else {
						if (notificationsRef && notificationsRef.current)
							notificationsRef.current.addNotification({
								message: <Lang en="Failed to get measurements" fi="Mittausten haku epäonnistui" sv="Kunde inte hämta mätningar"/>,
								level: "warning"
							})
					}
				})
			})
			.catch((err) => {
				console.warn("Measurments fetch error: ", err)
			});
		})
	}

	render() {
		return (
			<Accordion
				id="tablet-accordion"
				className="tablet"
			>
				<Card>
					<Accordion.Toggle as={Card.Header} className="tablet-header">
						<OverlayTrigger
							key={"overlaytrigger_" + this.props.devId}
							overlay={
								<Tooltip id={"tooltip_" + this.props.devId}>
									{this.props.devId}
								</Tooltip>
							}
						>
							<span>{this.props.title !== undefined ? this.props.title : ""}{this.getTime(this.props.devId, this.props.tabletView_measurements)}</span>
						</OverlayTrigger>
					</Accordion.Toggle>
					<Accordion.Collapse>
						<Card.Body>
							<span className="content">{this.state.activeRequests > 0 ? <DefaultSpinner show/> : this.props.children}</span>
						</Card.Body>
					</Accordion.Collapse>
				</Card>
			</Accordion>
		)
	}
}

function Tablet_mapStateToProps(state) {
	const { tabletView_measurements } = state;
	return { tabletView_measurements };
}

const Tablet_mapDispatchToProps = (dispatch) => {
	return {
		addTabletViewMeasurements: (val) => dispatch({type: "ADD_TABLETVIEW_MEASUREMENTS", val}),
	};
}

const Tablet = connect(Tablet_mapStateToProps, Tablet_mapDispatchToProps)(_Tablet);


class _Box extends Component {
	constructor() {
		super();
		this.openDeviceView = this.openDeviceView.bind(this);
	}

	getStyle(styleData) {
		let style = {};
		if (styleData) {
			style["gridColumn"] = (styleData.startIndex + 1) + "/" + (styleData.segments + styleData.startIndex + 1);
			style["backgroundColor"] = styleData.backgroundColor ? styleData.backgroundColor : undefined;
			style["animation"] = styleData.animation ? styleData.animation : undefined;
		}
		return style;
	}

	getIconData(quantity) {
		switch(quantity) {
			case "temperature":
				return {image: temp_icon, name: "temp"};
			case "weight":
				return {image: weight_icon, name: "weight"};
			case "humidity":
				return {image: waterdrop_icon, name: "waterdrop"};
			default:
				return {image: missing_icon, name: "default"};
		}
	}

	getTitle(quantity) {
		if (quantity) {
			if (QUANTITY_TRANSLATIONS[quantity])
				return QUANTITY_TRANSLATIONS[quantity];
			return quantity;
		}
		return null;
	}

	getUnitShort(unit_short) {
		if (unit_short) {
			return unit_short === "C" ? " °C" : " " + unit_short;
		}
		return "";
	}

	openDeviceView(e) {
		if (this.props.devId && this.props.measIndex !== undefined) {
			this.props.setVal("deviceDailyView_devId", this.props.devId);
			this.props.setVal("deviceDailyView_measIndex", this.props.measIndex);
			this.props.history.push("/device-daily-view");
		}
	}

	getMeasIndex({measIndex, styleData}) {
		if (styleData && styleData.measIndex)
			return styleData.measIndex;
		else if (measIndex)
			return measIndex;
		else
			return 0;
	}

	render() {
		if (this.props.measIndex !== null) {
			const icon = this.getIconData(this.props.quantity)
			return (
				<div
					className="tablet-box"
					style={this.getStyle(this.props.styleData)}
					onClick={this.openDeviceView}
				>
					<div className="icon-wrapper"><img alt={"icon_" + icon.name} src={icon.image}/></div>
					<div className="main-wrapper">
						<div className="title">{this.getTitle(this.props.quantity)}</div>
						<div className="table-box-value">
							<PrimaryValue devId={this.props.devId} measIndex={this.props.measIndex}/>{this.getUnitShort(this.props.unit_short)}
						</div>
					</div>
					<div className="table-sub-value">
						<SecondaryValues devId={this.props.devId} measIndex={this.props.measIndex}/>
					</div>
				</div>
			);
		} else {
			return (
				<div
					className="tablet-box"
					style={this.getStyle(this.props.styleData)}
				>
					<div className="main-wrapper"></div>
				</div>
			);
		}
	}
}

function Box_mapStateToProps(state) {
	const { deviceDailyView_devId } = state;
	return { deviceDailyView_devId };
}

const Box_mapDispatchToProps = (dispatch) => {
	return {
		setVal: (prop, val) => dispatch({type: "SET_VAL", prop, val}),
	};
}

const Box = withRouter(connect(Box_mapStateToProps, Box_mapDispatchToProps)(_Box));



class _PrimaryValue extends Component {

	getValue({measurements, devId, measIndex}) {
		if (measurements && devId && measurements[devId] && measurements[devId].vals && measurements[devId].vals[measurements[devId].vals.length-1] && measurements[devId].vals[measurements[devId].vals.length-1][measIndex]) {
			let val = measurements[devId].vals[measurements[devId].vals.length-1][measIndex];
			if (typeof val === "number")
				val = Math.round(val * 10) / 10;
			return val;
			// value={measurements && measurements[devId] && measurements[devId].measurements && measurements[devId].measurements.vals && measurements[devId].measurements.vals[i] ? measurements[devId].measurements.vals[i] : null}
		}
		return "-";
	}

	render() {
		return <span className="primary-value">{this.getValue({measurements: this.props.tabletView_measurements, devId: this.props.devId, measIndex: this.props.measIndex})}</span>
	}
}

function PV_mapStateToProps(state) {
	const { tabletView_measurements } = state;
	return { tabletView_measurements };
}

const PV_mapDispatchToProps = (dispatch) => {
	return {};
}

const PrimaryValue = connect(PV_mapStateToProps, PV_mapDispatchToProps)(_PrimaryValue);



class _SecondaryValues extends Component {

	getValue({measurements, devId, measIndex}) {
		console.log(measurements, devId, measIndex)
		if (measurements && devId && measurements[devId] && measurements[devId].vals && measurements[devId].vals[measurements[devId].vals.length-1] && measurements[devId].vals[measurements[devId].vals.length-1][measIndex]) {
			return measurements[devId].vals[measurements[devId].vals.length-1][measIndex];
			// value={measurements && measurements[devId] && measurements[devId].measurements && measurements[devId].measurements.vals && measurements[devId].measurements.vals[i] ? measurements[devId].measurements.vals[i] : null}
		}
		return "-";
	}

	getMin({measurements, devId, measIndex}) {
		if (measurements && devId && measurements[devId] && measurements[devId].vals) {
			let min =  measurements[devId].vals.reduce((res, vals) => {
				if (vals[measIndex] !== undefined) {
					if (res === null || vals[measIndex] < res)
						res = vals[measIndex];
				}
				return res;
			}, null);
			return Math.round(min * 10) / 10;
		}
		return null;
	}

	getMax({measurements, devId, measIndex}) {
		if (measurements && devId && measurements[devId] && measurements[devId].vals) {
			let max = measurements[devId].vals.reduce((res, vals) => {
				if (vals[measIndex] !== undefined) {
					if (res === null || vals[measIndex] > res)
						res = vals[measIndex];
				}
				return res;
			}, null);
			return Math.round(max * 10) / 10;
		}
		return null;
	}

	getAvg({measurements, devId, measIndex}) {
		if (measurements && devId && measurements[devId] && measurements[devId].vals) {
			let avgData = measurements[devId].vals.reduce((res, vals) => {
				if (vals[measIndex] !== undefined) {
					if (vals[measIndex])
						res.total += vals[measIndex];
						res.count++;
				}
				return res;
			}, {total: 0, count: 0});
			return avgData.count > 0 ? Math.round((avgData.total / avgData.count) * 10) / 10 : null; 
		}
		return null;
	}

	render() {
		return (
			<span className="secondary-values">
				<span className="value">{this.getMin({measurements: this.props.tabletView_measurements, devId: this.props.devId, measIndex: this.props.measIndex})}</span>
				<span className="value">{this.getMax({measurements: this.props.tabletView_measurements, devId: this.props.devId, measIndex: this.props.measIndex})}</span>
				<span className="value">{this.getAvg({measurements: this.props.tabletView_measurements, devId: this.props.devId, measIndex: this.props.measIndex})}</span>
			</span>
		);
	}
}

function SV_mapStateToProps(state) {
	const { tabletView_measurements } = state;
	return { tabletView_measurements };
}

const SV_mapDispatchToProps = (dispatch) => {
	return {};
}

const SecondaryValues = connect(SV_mapStateToProps, SV_mapDispatchToProps)(_SecondaryValues);

// export class BoxValue extends Component {
// 	render() {
// 		// If value is string return as is
// 		if (typeof this.props.value === "string") {
// 			return (
// 				<div className="table-box-value">
// 					{this.props.value}
// 				</div>
// 			);

// 		// Default value with unit
// 		} else {
// 			return (
// 				<div className="table-box-value">
// 					{this.props.value ? this.props.value : "-"}{this.props.unit ? " " + this.props.unit : ""}
// 				</div>
// 			);
// 		}
// 	}
// }

// export class Subvalues extends Component {
// 	parseValues({subvalues}) {
// 		if (Array.isArray(subvalues)) {
// 			return subvalues.map((sub) =>  {
// 				return (
// 					<div className="tablet-subvalue">
// 						<div className="subvalue-title">{sub.title !== undefined ? sub.title : ""}</div>
// 						<div className="subvalue-title">{sub.value !== undefined && sub.value !== null ? sub.value : "-"}</div>
// 					</div>
// 				);
// 			});
// 		}

// 		return null;
// 	}

// 	render() {
// 		return (
// 			<div className="table-box-subvalues">
// 				{this.parseValues(this.props)}
// 			</div>
// 		);
// 	}
// }