import React, { Component } from 'react';
import { connect } from "react-redux";
import { Redirect, withRouter } from 'react-router-dom';
import {Form, Button, Carousel} from "react-bootstrap";
import { IoIosWarning } from "react-icons/io";

import Lang, {getLangString} from "../language/language";
import Requests from "../../utilities/requests";
import LanguageSelect from "../language/languageSelect";
import {siteVersion} from "../siteConfig";
import DefaultSpinner from "../spinner/defaultSpinner";

import "./loginWithCarousel.css";

class Login extends Component {
	constructor() {
		super();
		
		this.state = {
			failed: false,
			activeRequests: 0,
		}

		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleSubmit(e) {
		e.preventDefault();
		this.setState({activeRequests: this.state.activeRequests + 1}, () => {
			Requests.login({username: this.props.userEmail, password: this.props.password})
			.then((data) => {
				this.setState({activeRequests: this.state.activeRequests - 1}, () => {
					if (data.status === "success") {
						this.setState({failed: false}, () => {
							this.props.setLogin(data);
						});
					} else {
						this.setState({failed: true});
					}
				})
			})
			.catch((err) => {
				console.log("Login error.");
				console.log(err)
				this.setState({activeRequests: this.state.activeRequests - 1});
			});
		});
	}

	render() {
		if (this.props.token)
			return <Redirect to="/"/>;
		else
			return (
				<div className="login-with-carousel">
					<div className="left-side-segment">
						<Carousel>
							<Carousel.Item style={{color: "white"}}>
								<div className="content" style={{textAlign: "center", backgroundColor: "grey", width: "100%", height: "100vh"}}>
									<h3>
										<Lang fi="Sisältö" sv="Innehåll" en="Content"/>
									</h3>
								</div>
								<Carousel.Caption style={{color: "white"}}>
									<h3>1st</h3>
									<p>Caption</p>
								</Carousel.Caption>
							</Carousel.Item>
							<Carousel.Item style={{color: "white"}}>
							<div className="content" style={{textAlign: "center", backgroundColor: "dimgrey", width: "100%", height: "100vh"}}>
								<h3>
									<Lang fi="Sisältö" sv="Innehåll" en="Content"/>
								</h3>
							</div>
								<Carousel.Caption>
									<h3>2nd</h3>
									<p>Caption</p>
								</Carousel.Caption>
							</Carousel.Item>
							<Carousel.Item style={{color: "white"}}>
								<div className="content" style={{textAlign: "center", backgroundColor: "lightslategrey", width: "100%", height: "100vh"}}>
									<h3>
										<Lang fi="Sisältö" sv="Innehåll" en="Content"/>
									</h3>
								</div>
								<Carousel.Caption>
									<h3>3rd</h3>
									<p>Caption</p>
								</Carousel.Caption>
							</Carousel.Item>
						</Carousel>
					</div>
					<div className="right-side-segment">
						<div className="content-wrapper">
							<form
								className="loginForm"
								onSubmit={this.handleSubmit}
							>
								<div className="title">
									<Lang
										en="Login"
										fi="Kirjautuminen"
										sv="Inloggning"
									/>
								</div>
								<LoginFailed show={this.state.failed && this.state.activeRequests < 1}/>
								<DefaultSpinner show={this.state.activeRequests > 0}/>
								<Form.Control 
									type="text"
									placeholder={getLangString(this.props.lang, {fi: "Sähköposti", en: "E-mail", sv: "E-post"})}
									value={this.props.userEmail}
									onChange={(e) => this.props.setVal("userEmail", e.target.value)}
								/>
								<Form.Control
									type="password"
									placeholder={getLangString(this.props.lang, {fi: "Salasana", en: "Password", sv: "Lösenord"})}
									value={this.props.password}
									onChange={(e) => this.props.setVal("password", e.target.value)}
								/>
								<Button variant="dark" type="submit">
									OK
								</Button>
							</form>
							<div className="language-select">
								<LanguageSelect/>
							</div>
						</div>
					</div>
					<span className="version">v.{siteVersion}</span>
				</div>
			);
	}
}

function mapStateToProps(state) {
	const { userEmail, password, token, lang } = state;
	return { userEmail, password, token, lang };
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLogin: (vals) => dispatch({type: "LOGIN", vals}),
		setVal: (prop, val) => dispatch({type: "SET_VAL", prop, val})
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));


class LoginFailed extends Component {
	render() {
		if (this.props.show) {
			return 	(
				<div className="login-failed-msg">
					<div className="icon-wrapper"><IoIosWarning className="warningicon status-icon" color="rgb(255, 174, 0)" size="50px" /></div>
					<Lang en="Wrong username or password" fi="Väärä käyttäjänimi tai salasana" sv="Fel användarnamn eller lösenord"/>
				</div>
			);
		}
		return null;
	}
}