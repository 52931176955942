import React, {Component} from "react";
import { connect } from "react-redux";
import {Form, Button} from "react-bootstrap";

import Lang, {getLangString} from "../language/language";
import Requests from "../../utilities/requests";
import {notificationsRef} from "../notifications";
import Check from "./check";

import "./changePassword.css";

class ChangePassword extends Component {
	constructor() {
		super();
		this.state = {
			oldPass: "",
			firstPass: "",
			verificationPass: "",
		}
		
		this.submit = this.submit.bind(this);
	}

	submit(e) {
		e.preventDefault();
		if (this.state.oldPass && this.state.oldPass !== "" && this.state.firstPass.length >= 8 && /\d/.test(this.state.firstPass) && this.state.firstPass === this.state.verificationPass) {
			Requests.changePassword({oldPassword: this.state.oldPass, newPassword: this.state.firstPass})
				.then((data) => {
					if (data && data.status === "success") {
						this.setState({oldPass: "", firstPass: "", verificationPass: ""}, () => {
							if (notificationsRef && notificationsRef.current)
								notificationsRef.current.addNotification({
									message: <Lang en="Success. Password was set." fi="Onnistui. Salsana asetettu" sv="Lyckades. Lösenordet inställts."/>,
									level: "success"
								})
						});
					} else {
						if (notificationsRef && notificationsRef.current)
							notificationsRef.current.addNotification({
								message: <Lang en="Failed to set password." fi="Salasanan asetus epäonnistui." sv="Kunde inte inställa lösenordet."/>,
								level: "error"
							})
					}
				})
				.catch((err) => {
					console.log("Error, while setting password.");
					console.log(err);
					if (notificationsRef && notificationsRef.current)
							notificationsRef.current.addNotification({
								message: <Lang en="Something went wrong." fi="Jokin meni väärin." sv="Något gick fel."/>,
								level: "error"
							})
				});
			
		} else {
			this.setState({status: "fail"}, () => {
				if (notificationsRef && notificationsRef.current)
					notificationsRef.current.addNotification({
						message: <Lang en="Failed, please check the requirements." fi="Epäonnistui, tarkista vaatimukset." sv="Misslyckades, granska kraven."/>,
						level: "warning"
					})
			})
		}
	}

	render() {
		return (
			<div className="change-password">
				<div className="content-wrapper">
					<form
						className="change-password-form"
						onSubmit={this.submit}
					>
						<div className="title">
							<Lang en="Change password" fi="Salasanan vaihto" sv="Byte av lösenord" />
						</div>
						<div className="checks">
							<Check check={this.state.oldPass && this.state.oldPass !== ""}><Lang en="Current password" fi="Nykyinen salasana" sv="Nuvarande lösenord"/></Check>
							<Check check={this.state.firstPass.length >= 8}><Lang en="At least 8 characters" fi="Vähintään 8 merkkiä" sv="Mist 8 tecken"/></Check>
							<Check check={/\d/.test(this.state.firstPass)}><Lang en="Contains numbers" fi="Sisältää numeroita" sv="Innhåller nummer"/></Check>
							<Check check={this.state.firstPass.length > 0 && this.state.firstPass === this.state.verificationPass}><Lang en="Passwords matche" fi="Salasanat täsmäävät" sv="Lösenorden matchar"/></Check>
						</div>
						<span className="input-field">
							<Form.Label><Lang fi="Nykyinen" en="Current" sv="Nuvarande"/></Form.Label>
							<Form.Control 
								type="password"
								placeholder={getLangString(this.props.lang, {fi: "Nykyinen", en: "Current", sv: "Nuvarande"})}
								value={this.state.oldPass}
								onChange={(e) => this.setState({oldPass: e.target.value})}
							/>
						</span>
						<span className="input-field">
							<Form.Label><Lang fi="Uusi" en="New" sv="Ny"/></Form.Label>
							<Form.Control 
								type="password"
								placeholder={getLangString(this.props.lang, {fi: "Uusi", en: "New", sv: "Ny"})}
								value={this.state.firstPass}
								onChange={(e) => this.setState({firstPass: e.target.value})}
							/>
						</span>
						<span className="input-field">
							<Form.Label><Lang fi="Vahvistus" en="Verification" sv="Bekräftelse"/></Form.Label>
							<Form.Control 
								type="password"
								placeholder={getLangString(this.props.lang, {fi: "Vahvistus", en: "Verification", sv: "Bekräftelse"})}
								value={this.state.verificationPass}
								onChange={(e) => this.setState({verificationPass: e.target.value})}
							/>
						</span>
						<Button type="submit">
							OK
						</Button>
					</form>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { lang } = state;
	return { lang };
}

const mapDispatchToProps = (dispatch) => {
	return {
		setVal: (prop, val) => dispatch({type: "SET_VAL", prop, val})
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);