import moment from "moment";
import { createStore } from "redux";
import { LANGUAGE_CODES } from "../components/language/language";

import rfdc from "rfdc";
const rfdclone = rfdc();

const tabletViewProfiles = {
	twostretch: {
		layout: [
			{startIndex: 0, segments: 6, measIndex: 0, unit: null},
			{startIndex: 6, segments: 6, measIndex: 1, unit: null},
			{startIndex: 0, segments: 6, measIndex: 2, unit: null},
			{startIndex: 6, segments: 6, measIndex: 3, unit: null},
			{startIndex: 0, segments: 6, measIndex: 4, unit: null},
			{startIndex: 6, segments: 6, measIndex: 5, unit: null},
			{startIndex: 0, segments: 6, measIndex: 6, unit: null},
			{startIndex: 6, segments: 6, measIndex: 7, unit: null},
			{startIndex: 0, segments: 6, measIndex: 8, unit: null},
			{startIndex: 6, segments: 6, measIndex: 9, unit: null},
			{startIndex: 0, segments: 12, measIndex: 10, unit: null},
		]
	},
	three: {
		layout: [
			// {startIndex: 0, segments: 6, measIndex: 0, unit: null, backgroundColor: "#d0fcbd", },
			{startIndex: 6, segments: 6, measIndex: null, unit: null, backgroundColor: "#F5F5F5"},
			{startIndex: 0, segments: 5, measIndex: 1, unit: null, backgroundColor: "#bdfbfc"},
			{startIndex: 5, segments: 7, measIndex: null, unit: null, backgroundColor: "#F5F5F5"},
			{startIndex: 0, segments: 4, measIndex: 2, unit: null, backgroundColor: "#bdfbfc"},
			{startIndex: 4, segments: 8, measIndex: null, unit: null, backgroundColor: "#FFFFFF"},
			{startIndex: 0, segments: 4, measIndex: 3, unit: null, backgroundColor: "#d0fcbd"},
			{startIndex: 4, segments: 4, measIndex: 4, unit: null, backgroundColor: "#bdfbfc"},
			{startIndex: 8, segments: 4, measIndex: 5, unit: null, backgroundColor: "#f0bdfc", animation: "pulse_animation 2s linear infinite"},
		]
	}
}

const initState = {
	userEmail: "",
	password: "",
	token: undefined,
	lang: "fi",
	notificationSystem: undefined,
	customerId: undefined,
	userId: undefined,
	rights: [],

	devices: {},
	deviceData: {},

	users: {},
	userData: {},
	customers: {},
	customerData: {},

	searchText: "",
	selectedCustomerId: undefined,
	selectedDeviceIds: {},
	selectedTimeInterval: {start: moment().subtract(1, "days").valueOf(), end: moment().valueOf(), code: "day"},
	sidePanel_open: true,

	tabletView_devices: {},
	tabletView_deviceData: {},
	tabletView_measurements: {},
	tabletView_deviceProfiles: {},
	tabletViewProfiles,

	analyticsView_devices: {},
	analyticsView_deviceData: {},
	analyticsView_measurements: {},

	dailyView_devices: {},
	dailyView_deviceData: {},
	dailyView_measurements: {},
	dailyView_selectedDeviceIds: {},

	deviceDailyView_devId: undefined,
	deviceDailyView_measIndex: undefined,
}

const reducer = (state = initState, action) => {
	let newState = rfdclone(state);
	switch(action.type) {
		case "SET_VAL":
			if (action.prop !== undefined)
				return Object.assign({}, newState, {[action.prop]: action.val});
			else
				return state;
		case "SET_LANG":
			if (action.val !== undefined && LANGUAGE_CODES.findIndex(x => x === action.val) > -1)
				return Object.assign({}, newState, {lang: action.val});
			else
				return state;
		case "LOGIN":
			newState =  Object.assign(newState, {
				token: action.vals.token,
				customerId: action.vals.customerId,
				selectedCustomerId: action.vals.customerId,
				userId: action.vals.userId,
				rights: action.vals.rights,
			});
			action.vals.devices_module.forEach((device) => {
				Object.assign(newState.devices, device);
			});
			newState.password = "";
			return newState;
		case "LOGOUT": 
			newState.token = undefined;
			newState.username = "";
			return newState;
		case "ADD_USERS":
			newState.users = Object.assign(newState.users, action.vals);
			return newState;
		case "ADD_USER_DATA":
			newState.userData[action.val.id] = action.val.data;
			return newState;
		case "ADD_CUSTOMER_DATA":
			newState.customerData[action.val.id] = action.val.data;
			return newState;
		case "ADD_DEVICE_DATA":
			newState.deviceData[action.val.id] = action.val.data;
			return newState;
		case "ADD_VIEW_DEVICE_DATA":
			if (action.deviceId && action.propPrefix) {
				newState[action.propPrefix + "deviceData"][action.deviceId] = action.data;
			}
			return newState;
		case "ADD_TABLETVIEW_DEVICE_PROFILE_DATA":
			newState.tabletView_deviceProfiles[action.val.id] = action.val.data;
			return newState;
		case "ADD_TABLETVIEW_MEASUREMENTS":
			Object.assign(newState.tabletView_measurements, action.val);
			return newState;
		case "ADD_ANALYTICSVIEW_DEVICE_PROFILE_DATA":
			newState.analyticsView_deviceProfiles[action.val.id] = action.val.data;
			return newState;
		case "TOGGLE_SELECTED_DEVICE":
			const prop = action.privateVar ? action.privateVar : "selectedDeviceIds";
			if (newState[prop][action.val])
				delete newState[prop][action.val];
			else
				newState[prop][action.val] = true;
			return newState;
		case "CLEAR_VIEW":
			switch(action.val) {
				case "dailyView":
					newState.dailyView_devices = {};
					newState.dailyView_deviceData = {};
					newState.dailyView_measurements = {};
					newState.dailyView_selectedDeviceIds = {};
					break;
				default:
					console.warn("Redux CLEAR_VIEW was called. Nothing was cleard.");
			}
			return newState;
		case "ADD_MEASUREMENT":
			if (action.timestamp && action.deviceId && action.measurements && action.prop) {
				if (newState[action.prop][action.deviceId] !== undefined && newState[action.prop][action.deviceId].t && newState[action.prop][action.deviceId].vals) {
					newState[action.prop][action.deviceId].t.push(action.timestamp);
					newState[action.prop][action.deviceId].vals.push(action.measurements);
				}
			}
			return newState;
		default:
			return state;
	}
}

export default createStore(reducer);