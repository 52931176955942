import React, {Component} from "react";
import { connect } from "react-redux";
import {Modal, Button, Form} from "react-bootstrap";

import Lang, {getLangString} from "../language/language";
import Requests from "../../utilities/requests";
import UserPremissions from "./userPermissions";

import "./addUser.css";

class AddUser extends Component {
	constructor() {
		super();

		this.state = {
			modalOpen: false,
			newUserEmail: "",
			newUserNotificationEmail: "",
			newUserNotificationSMS: "",
			newUserRights: [],
		}

		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.submit = this.submit.bind(this);
		this.permissionChange = this.permissionChange.bind(this);
	}

	openModal() {
		this.setState({modalOpen: true});
	}

	closeModal() {
		this.setState({modalOpen: false});
	}

	submit(e) {
		e.preventDefault();
		this.closeModal();
		const requestValues = {
			customerId: this.props.selectedCustomerId,
			userName: this.state.newUserEmail,
			rights: this.state.newUserRights,
			notifSms: nullIfEmpty(this.state.newUserNotificationSMS),
			notifDeviceToken: null,
			notifEmail: nullIfEmpty(this.state.newUserNotificationEmail),
			hostname: window.location.hostname,
		};
		Requests.addNewUser(requestValues)
			.then((data) => {
				if (data && data.status === "success") {
					if (data.userId) {
						const id = data.userId;
						// Requesting data for user
						Requests.getUserData({userId: data.userId})
							.then((uData) => {
								if (uData.status === "success") {
									let userData = {
										customerId: uData.customerId,
										userName: uData.userName,
										rights: uData.rights,
										notifSms: uData.notifSms,
										notifDeviceToken: uData.notifDeviceToken,
										notifEmail: uData.notifEmail,
									}
									this.props.addUserData({id, data: userData});
								}
							})
							.catch((err) => {
								console.log("Error, while getting user data.");
								console.log(err);
							})

						this.props.addUsers({[id]: requestValues.userName});
					}
				} else { 
					console.log("Failed to add new user.");
				}
			});
	}

	permissionChange({rights}) {
		this.setState({newUserRights: rights});
	}

	render() {
		return (
			<div className="add-user-wrapper">
				<Button
					block
					className="control-button"
					variant="secondary"
					onClick={this.openModal}
					disabled={this.state.modalOpen || this.props.disabled}
				>
					<Lang en="Add user" fi="Lisää käyttäjä" sv="Lägg till användare" />
				</Button>
				<Modal show={this.state.modalOpen} onHide={this.closeModal}>
					<form
						onSubmit={this.submit}
						className="add-user-modal-form"
					>
						<Modal.Header closeButton>
							<Modal.Title><Lang en="Add user" fi="Lisää käyttäjä" sv="Lägg till användare"/></Modal.Title>
						</Modal.Header>
						<Modal.Body>
							
								<Form.Label><Lang fi="Käyttäjän sähköposti" en="Users e-mail" sv="Andändarens e-post"/></Form.Label>
								<Form.Control 
									type="email"
									placeholder={getLangString(this.props.lang, {fi: "Käyttäjän sähköposti", en: "Users e-mail", sv: "Andändarens e-post"})}
									value={this.state.newUserEmail}
									onChange={(e) => this.setState({newUserEmail: e.target.value})}
								/>

								<Form.Label><Lang fi="SMS ilmoitus numero" en="SMS notification number" sv="SMS meddelande nummer"/></Form.Label>
								<Form.Control 
									type="tel"
									placeholder={getLangString(this.props.lang, {fi: "SMS ilmoitus numero", en: "SMS notification number", sv: "SMS meddelande nummer"})}
									value={this.state.newUserNotificationSMS}
									onChange={(e) => this.setState({newUserNotificationSMS: e.target.value})}
								/>

								<Form.Label><Lang fi="Ilmoitus Sähköposti" en="NotificationE-mail" sv="Meddelande E-post"/></Form.Label>
								<Form.Control 
									type="email"
									placeholder={getLangString(this.props.lang, {fi: "Ilmoitus Sähköposti", en: "NotificationE-mail", sv: "Meddelande E-post"})}
									value={this.state.newUserNotificationEmail}
									onChange={(e) => this.setState({newUserNotificationEmail: e.target.value})}
								/>
							
								<UserPremissions onChange={this.permissionChange} editRights={this.state.newUserRights}/>

						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.closeModal}><Lang en="Cancel" fi="Peruuta" sv="Ångra" /></Button>
							<Button variant="dark" type="submit">OK</Button>
						</Modal.Footer>
					</form>
				</Modal>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { lang, users, userData, selectedCustomerId } = state;
	return { lang, users, userData, selectedCustomerId };
}

const mapDispatchToProps = (dispatch) => {
	return {
		addUsers: (vals) => dispatch({type: "ADD_USERS", vals}),
		addUserData: (val) => dispatch({type: "ADD_USER_DATA", val}),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);


const nullIfEmpty = (input) => {
	if (input === undefined || input === "")
		return null;

	return input;
}