import React, { Component } from "react";
import { connect } from "react-redux";
import { slide as Menu } from 'react-burger-menu';

import "./sidePanel.css";

class SidePanel extends Component {
	constructor() {
		super();

		this.toggle = this.toggle.bind(this);
	}

	toggle(open) {
		this.props.setVal("sidePanel_open", !open);
	}

	render() {
		return (
			<Menu
				bodyClassName="side-panel"
				isOpen={this.props.sidePanel_open}
				width={250}
				noOverlay
				disableAutoFocus
				onStateChange={ (obj) => {this.props.setVal("sidePanel_open", obj.isOpen)} }
			>
				{this.props.children}
			</Menu>
		);
	}
}

function mapStateToProps(state) {
	const { sidePanel_open } = state;
	return { sidePanel_open };
}

const mapDispatchToProps = (dispatch) => {
	return {
		setVal: (prop, val) => dispatch({type: "SET_VAL", prop, val}),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SidePanel);
