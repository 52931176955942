/*
	Props
	radio: allows only a single select, clears previous selection on select
	preSelect: selects the firts list item when there is no selection
	clearOnMount: clears previous selection(s) on mount
*/

import React , { Component } from "react";
import { connect } from "react-redux";
import { Table } from "react-bootstrap";

import Lang from "../language/language";
import Search from "../search/search";

import "./deviceSelect.css";
import isDefined from "../../utilities/isDefined";

class DeviceSelect extends Component {
	constructor() {
		super();

		this.state = {
			filteredDevices: undefined,
		}

		this.getFilteredDevices = this.getFilteredDevices.bind(this);
		this.onSelect = this.onSelect.bind(this);
	}

	componentDidMount() {
		if (this.props.clearOnMount)
			this.props.setVal(this.props.privateSelection ? this.props.privateSelection : "selectedDeviceIds", {});
	}

	componentDidUpdate(prevProps) {
		if (this.props.preSelect && this.props.selectedDeviceIds !== undefined && Object.keys(this.props.selectedDeviceIds).length < 1) {
			if (this.props.devices && Object.keys(this.props.devices).length > 0) {
				this.props.toggleSelectedDevice(Object.keys(this.props.devices)[0], this.props.privateSelection);
			}
		}
	}

	getTitle({devices, selectedDeviceId, deviceId}) {
		if (devices) {
			if (selectedDeviceId !== undefined) {
				return devices && devices[selectedDeviceId] !== undefined ? devices[selectedDeviceId] : "Error";
			} else if (deviceId !== undefined) {
				return devices && devices[deviceId] !== undefined ? devices[deviceId] : "Error";
			}
		}
		return <Lang en="Select devices" fi="Valitse laitteet" sv="Välj enheter"/>
	}

	getItems({devices, selectedDeviceIds}, filteredDevices) {
		let items = [];
		if ( isDefined({devices, selectedDeviceIds}) ) {
			if (devices || filteredDevices) {
				const usedDevices = filteredDevices ? filteredDevices : devices;
				const keys = Object.keys(usedDevices);
				items = keys.map((key) => {
					return (
						<tr key={"list-item_" + key}>
							<td
								className={selectedDeviceIds[key] ? "active list-item": "list-item"}
								onClick={() => this.onSelect(key)}
							>
									{usedDevices[key]}
							</td>
						</tr>
					);
				});
			}
		}
		return items;
	}

	getFilteredDevices(filteredDevices) {
		this.setState({filteredDevices});
	}

	onSelect(deviceId) {
		if (this.props.radio)
			this.props.setVal(this.props.privateSelection ? this.props.privateSelection : "selectedDeviceIds", {[deviceId]: true});
		else
			this.props.toggleSelectedDevice(deviceId, this.props.privateSelection);
	}

	render() {
		if (!this.props.hidden && Object.keys(this.props.devices).length > 0 ) {
			return (
				<div className="device-select">
					{	this.props.disableSearch ? null :
						<Search
							returnFiltered={this.getFilteredDevices}
							items={this.props.devices}
							placeholder={{en: "Device filter", fi: "Laitteiden suodatus", se: "Enhet filter"}}
						/>
					}
					<div className="table-header">
						{this.getTitle(this.props)}
					</div>
					<div className="table-wrapper">
						<Table
							className="device-table"
							onSelect={this.onSelect}
						>
							<tbody>
								{this.getItems(this.props, this.state.filteredDevices)}
							</tbody>
						</Table>
					</div>
				</div>
			);
		}
		return null;
	}
}

function mapStateToProps(state, props) {
	return { selectedDeviceIds: (props.privateSelection ? state[props.privateSelection] : state.selectedDeviceIds) };
}

const mapDispatchToProps = (dispatch) => {
	return {
		setVal: (prop, val) => dispatch({type: "SET_VAL", prop, val}),
		toggleSelectedDevice: (val, privateVar) => dispatch({type: "TOGGLE_SELECTED_DEVICE", val, privateVar}),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceSelect);