import React, {Component} from "react";
import { connect } from "react-redux";
import {Modal, Button, Form} from "react-bootstrap";

import Lang, {getLangString} from "../language/language";
import Requests from "../../utilities/requests";
import {notificationsRef} from "../notifications";
import Contacts, { parseContacts, contactsString } from "./contacts";

import "./addCustomer.css";

class AddCustomer extends Component {
	constructor() {
		super();

		this.state = {
			modalOpen: false,
			newCustomerName: "",
			newCustomerLoginAllowed: false,
			newCustomerContacts: parseContacts(""),
		}

		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.submit = this.submit.bind(this);
		this.toggleLoginAllowed = this.toggleLoginAllowed.bind(this);
		this.contactsUpdate = this.contactsUpdate.bind(this);
	}

	openModal() {
		this.setState({modalOpen: true});
	}

	closeModal() {
		this.setState({
			modalOpen: false,
			newCustomerName: "",
			newCustomerLoginAllowed: false,
			newCustomerContacts: parseContacts(""),
		});
	}

	submit(e) {
		e.preventDefault();
		this.closeModal();
		const requestValues = {
			name: this.state.newCustomerName,
			loginAllowed: this.state.newCustomerLoginAllowed ? 1 : 0,
			contacts: contactsString(this.state.newCustomerContacts),
		};
		Requests.addNewCustomer(requestValues)
			.then((data) => {
				if (data && data.status === "success") {
					if (notificationsRef && notificationsRef.current)
						notificationsRef.current.addNotification({
							message: <Lang en="Customer added" fi="Asiakas lisätty" sv="Kunden lades till"/>,
							level: "success"
						})

					// Requesting customers
					Requests.getCustomers()
						.then((cData) => {
							if (cData.status === "success" && cData.customers) {
								this.props.setVal("customers", cData.customers);
							}
						})
						.catch((err) => {
							console.warn("Error, while getting customers.");
							console.warn(err);
						})
				} else {
					if (notificationsRef && notificationsRef.current)
						notificationsRef.current.addNotification({
							message: <Lang en="Failed to add customer" fi="Asiakkaan lisäys epäonnistui" sv="Kunde inte lägga till kuden"/>,
							level: "warning"
						})
					else
						console.warn("Failed to add new customer.");
				}
			});
	}

	toggleLoginAllowed(e) {
		this.setState({newCustomerLoginAllowed: !this.state.newCustomerLoginAllowed});
	}

	contactsUpdate(contacts) {
		this.setState({newCustomerContacts: contacts});
	}

	render() {
		return (
			<div className="add-customer-wrapper">
				<Button
					block
					className="control-button"
					variant="secondary"
					onClick={this.openModal}
					disabled={this.state.modalOpen || this.props.disabled}
				>
					<Lang en="Add customer" fi="Lisää asiakas" sv="Lägg till kund" />
				</Button>
				<Modal show={this.state.modalOpen} onHide={this.closeModal} backdrop="static">
					<form
						onSubmit={this.submit}
						className="add-customer-modal-form"
					>
						<Modal.Header closeButton>
							<Modal.Title><Lang en="Add customer" fi="Lisää asiakas" sv="Lägg till kund"/></Modal.Title>
						</Modal.Header>
						<Modal.Body>
							
								<Form.Label><Lang fi="Asiakkaan nimi" en="Customer name" sv="Kundens namn"/></Form.Label>
								<Form.Control 
									type="text"
									placeholder={getLangString(this.props.lang, {fi: "Asiakkaan nimi", en: "Customer name", sv: "Kundens namn"})}
									value={this.state.newCustomerName}
									onChange={(e) => this.setState({newCustomerName: e.target.value})}
								/>

								<Form.Check
									className="login-allowed-checkbox"
									type="checkbox"
									label={<Lang en="Login allowed" fi="Saa kirjautua" sv="Får logga in"/>}
									checked={this.state.newCustomerLoginAllowed}
									onChange={this.toggleLoginAllowed}
								/>

								<Contacts contacts={this.state.newCustomerContacts} updateAncestor={this.contactsUpdate}/>

						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.closeModal}><Lang en="Cancel" fi="Peruuta" sv="Ångra" /></Button>
							<Button variant="dark" type="submit">OK</Button>
						</Modal.Footer>
					</form>
				</Modal>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { lang, customers } = state;
	return { lang, customers };
}

const mapDispatchToProps = (dispatch) => {
	return {
		setVal: (prop, val) => dispatch({type: "SET_VAL", prop, val}),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomer);