import React, { Component } from 'react';
import {withRouter } from 'react-router-dom';
import {Nav} from 'react-bootstrap';

class NavItemWithRouter extends Component{
    constructor() {
        super();

        this.isActive = this.isActive.bind(this);
    }

    handleClick(){
        if(this.props.onClick){
            this.props.onClick();
        }
        this.props.history.push(this.props.to);
    }

    isActive(){
        if(this.props.to === this.props.location.pathname)
            return true
        else
            return false;
    }

    render(){
        if (!this.props.disabled) {
            const active = this.isActive();
            return(
                <Nav.Item
                    className={"navitem " + this.props.className + (active ? " active" : "")}
                    onClick={this.handleClick.bind(this)}
                >
                    {this.props.children}
                </Nav.Item>
            );
        }
        return null;
    }
}

NavItemWithRouter.defaultProps = {
    className: "",
}

export const NavItem = withRouter(NavItemWithRouter);