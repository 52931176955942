import React , { Component } from "react";
import {connect} from "react-redux";
import moment from "moment";
import uuid from "uuid/v4";

import Requests from "../../utilities/requests";
import Lang from "../language/language";
import isDefined from "../../utilities/isDefined";
import {notificationsRef} from "../notifications";
import DefaultSpinner from "../spinner/defaultSpinner";
import DeviceSelect from "../deviceSelect/deviceSelect";
import {MSToS, getLastOclock} from "../../utilities/time";
import ChartBox from "./chartBox";
import SidePanel from "../sidePanel/sidePanel";

import "./dailyView.css";

class DailyView extends Component {
	constructor() {
		super();

		this.state = {
			activeRequests: 0,
		}

		this.deviceSelectUUID = uuid();
	}

	componentDidMount() {
		if (this.props.customerId) {
			this.setState({activeRequests: this.state.activeRequests + 1}, () => {
				Requests.getDevices({customerId: this.props.customerId})
				.then((data) => {
					this.setState({activeRequests: this.state.activeRequests - 1}, () => {
						if (data.status === "success") {
							let devices = {};
							data.devices.forEach((device) => {
								Object.assign(devices, device);
							});
		
							const ids = Object.keys(devices);
							let requestCount = ids.length;
								this.setState({activeRequests: this.state.activeRequests + 1}, () => {
									ids.forEach((id) => {
										// Requesting data for device
										Requests.getDeviceData({deviceId: id})
										.then((dData) => {
											requestCount--;
											if (requestCount < 1) {
												this.setState({activeRequests: this.state.activeRequests - 1}, () => {
													if (dData.status === "success") {
														let deviceData = {
															customerId: dData.customerId,
															description: dData.description,
															correctionValues: dData.correctionValues,
															colors: dData.colors,
															location: dData.location,
															quantities: dData.quantities,
															units: dData.units,
															units_short: dData.units_short,
														}
														this.props.addDeviceData({deviceId: id, data: deviceData});	
													}
												});
											} else {
												if (dData.status === "success") {
													let deviceData = {
														customerId: dData.customerId,
														description: dData.description,
														correctionValues: dData.correctionValues,
														colors: dData.colors,
														location: dData.location,
														quantities: dData.quantities,
														units: dData.units,
														units_short: dData.units_short,
													}
													this.props.addDeviceData({deviceId: id, data: deviceData});					
												}
											}
										})
										.catch((err) => {
											console.log("Error, while getting device data.");
											console.log(err);
										})
									})
								});
							
								this.props.setVal("dailyView_devices", devices);
						} else {
							if (notificationsRef && notificationsRef.current)
								notificationsRef.current.addNotification({
									message: <Lang en="Failed to get devices" fi="Laitteiden haku epäonnistui" sv="Kunde inte hämta enheter"/>,
									level: "warning"
								})
						}
					})
				})
				.catch(err => {
					console.warn("Devices fetch error: ", err)
				});
			});
		}
	}

	componentDidUpdate(prevProps) {
		if (
			this.props.selectedDeviceIds !== undefined && Object.keys(this.props.selectedDeviceIds).length > 0 &&
			(
				prevProps.selectedDeviceIds === undefined ||
				Object.keys(prevProps.selectedDeviceIds).length !== Object.keys(this.props.selectedDeviceIds).length ||
				Object.keys(prevProps.selectedDeviceIds)[0] !== Object.keys(this.props.selectedDeviceIds)[0]
			)
		) {
			/* Getting measurements */
			const requestValues = {
				deviceIds: Object.keys(this.props.selectedDeviceIds),
				amount: null,
				start: MSToS(getLastOclock(22)),
				end: MSToS(moment().valueOf()),
			}

			this.setState({activeRequests: this.state.activeRequests + 1}, () => {
				Requests.getMeasurements(requestValues)
				.then((data) => {
					this.setState({activeRequests: this.state.activeRequests - 1}, () => {
						if (data.status === "success") {
							this.props.setVal("dailyView_measurements", data.measurements);
						} else {
							if (notificationsRef && notificationsRef.current)
								notificationsRef.current.addNotification({
									message: <Lang en="Failed to get measurements" fi="Mittausten haku epäonnistui" sv="Kunde inte hämta mätningar"/>,
									level: "warning"
								})
						}
					})
				})
				.catch((err) => {
					console.warn("Measurments fetch error: ", err)
				});
			});
		}
	}

	componentWillUnmount() {
		this.props.clearView("dailyView");
	}

	getCharts({deviceId, deviceData}) {
		if (isDefined({deviceId, deviceData}, true)) {
			if (deviceData[deviceId] && deviceData[deviceId].quantities) {
				return deviceData[deviceId].quantities.map((quantity, i) => {
					return <ChartBox deviceId={deviceId} measIndex={i} key={"chart-box_" + deviceId + "_" + i}/>
				});
			}
		}
		return null;
	}

	render() {
		return (
			<div className="daily-view">
				<SidePanel>
					<DeviceSelect devices={this.props.dailyView_devices} preSelect radio privateSelection={"dailyView_selectedDeviceIds"}/>
				</SidePanel>
				<div className="main-panel">
					<h3 className="title"><Lang en="Daily view" fi="Päivänäkymä" sv="Dagsvy"/></h3>
					<div className={this.state.activeRequests > 0 ? "content loading" : "content"}>
						<DefaultSpinner show={this.state.activeRequests > 0} />
						{this.state.activeRequests > 0 ? null :
							this.getCharts({
								deviceId: this.props.selectedDeviceIds && Object.keys(this.props.selectedDeviceIds).length > 0 ? Object.keys(this.props.selectedDeviceIds)[0] : undefined,
								deviceData: this.props.dailyView_deviceData})
						}
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { dailyView_devices, dailyView_deviceData, dailyView_measurements, customerId, dailyView_selectedDeviceIds } = state;
	return { dailyView_devices, dailyView_deviceData, dailyView_measurements, customerId, selectedDeviceIds: dailyView_selectedDeviceIds };
}

const mapDispatchToProps = (dispatch) => {
	return {
		setVal: (prop, val) => dispatch({type: "SET_VAL", prop, val}),
		clearView: (val) => dispatch({type: "CLEAR_VIEW", val}),
		addDeviceData: ({deviceId, data}) => dispatch({type: "ADD_VIEW_DEVICE_DATA", propPrefix: "dailyView_", deviceId, data}),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(DailyView);