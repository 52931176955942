import React, { Component } from "react";
import { connect } from "react-redux";
import { Dropdown, DropdownButton, Spinner } from "react-bootstrap";

import Lang from "../language/language";
import { auth } from "../../utilities/auth";
import { notificationsRef } from "../notifications";
import Requests from "../../utilities/requests";
import Search from "../search/search";

import "./customerSelect.css";

class CustomerSelect extends Component {
	constructor() {
		super();

		this.state = {
			activeRequests: 0,
			filteredCustomers: undefined,
		}

		this.onSelect = this.onSelect.bind(this);
		this.getFilteredCustomers = this.getFilteredCustomers.bind(this);
	}

	componentDidMount() {
		if (Object.keys(this.props.customers).length < 1 && auth(["customers"], this.props.rights)) {
			this.setState({activeRequests: this.state.activeRequests + 1}, () => {
				// Requestin customers
				Requests.getCustomers()
				.then((data) => {
					this.setState({activeRequests: this.state.activeRequests - 1}, () => {
						if (data.status === "success" && data.customers !== undefined) {
							this.props.setVal("customers", data.customers);
						} else {
							if (notificationsRef && notificationsRef.current)
								notificationsRef.current.addNotification({
									message: <Lang en="Failed to get customers" fi="Asiakkaiden haku epäonnistui" sv="Kunde inte hämta kunder"/>,
									level: "warning"
								})
							else
								console.log("Failed to get customers.")
						}
					})
				})
				.catch((err) => {
					console.log("Error, while getting customers.");
					console.log(err)
				});
			});
		}
	}

	getTitle({customers, selectedCustomerId, customerId}) {
		if (customers) {
			if (selectedCustomerId !== undefined) {
				return customers && customers[selectedCustomerId] !== undefined ? customers[selectedCustomerId] : "Error";
			} else if (customerId !== undefined) {
				return customers && customers[customerId] !== undefined ? customers[customerId] : "Error";
			}
		}
		return <Lang en="Select customer" fi="Valitse asiakas" sv="Välj kund"/>
	}

	getItems({customers, selectedCustomerId, customerId}, filteredCustomers) {
		let items = [];
		if (customers || filteredCustomers) {
			const activeKey = selectedCustomerId !== undefined ? selectedCustomerId : customerId;
			const usedCustomers = filteredCustomers ? filteredCustomers : customers;
			const keys = Object.keys(usedCustomers);
			items = keys.map((key) => {
				return <Dropdown.Item eventKey={key} active={key === activeKey} key={key}>{usedCustomers[key]}</Dropdown.Item>
			});
		}
		return items;
	}

	onSelect(customerId) {
		this.props.setVal("selectedCustomerId", parseInt(customerId, 10));
	}

	getFilteredCustomers(filteredCustomers) {
		this.setState({filteredCustomers});
	}

	render() {
		if (this.state.activeRequests > 0) {
			return <div className="customer-select"><div className="loading-spinner-wrapper"><Spinner className="loading-spinner" animation="border" variant="primary" /></div></div>;
		} else {
			if (Object.keys(this.props.customers).length > 0 && auth(["customers"], this.props.rights)) {
				return (
					<div className="customer-select">
						{	this.props.disableSearch ? null :
							<Search
								returnFiltered={this.getFilteredCustomers}
								items={this.props.customers}
								placeholder={{en: "Customer filter", fi: "Asiakkaiden suodatus", se: "Kund filter"}}
							/>
						}
						<DropdownButton
							className="customer-dropdown"
							title={this.getTitle(this.props)}
							variant={"secondary"}
							id={"customer_select"}
							key={"customer-select"}
							onSelect={this.onSelect}
						>
							{this.getItems(this.props, this.state.filteredCustomers)}
						</DropdownButton>
					</div>
				);
			} else {
				return null;
		 	}
		}
	}
}

function mapStateToProps(state) {
	const { customers, selectedCustomerId, customerId, rights } = state;
	return { customers, selectedCustomerId, customerId, rights };
}

const mapDispatchToProps = (dispatch) => {
	return {
		setVal: (prop, val) => dispatch({type: "SET_VAL", prop, val}),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSelect);
