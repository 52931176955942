import React, {Component} from "react";
import { connect } from "react-redux";
import moment from "moment";
import {Dropdown, DropdownButton} from "react-bootstrap";

import Lang, { getStringFromLang } from "../language/language";

import "./timeSelector.css";

const CODE_TRANSLATIONS = {
	day: <Lang en="24h" fi="24h" sv="24h"/>,
	week: <Lang en="One week" fi="Viikko" sv="En vecka"/>,
	"2_weeks": <Lang en="Two weeks" fi="Kaksi viikkoa" sv="Två månader"/>,
	month: <Lang en="A month" fi="Kuukausi" sv="En månad"/>,
	"3_months": <Lang en="Three month" fi="Kolme kuukautta" sv="Tre månader"/>,
	ytd: <Lang en="Year to date" fi="Vuoden alusta" sv="Från början av året"/>,
	year: <Lang en="One year" fi="Vuosi" sv="Ett år"/>,
};

class TimeSelector extends Component {
	constructor() {
		super();

		this.getTitle = this.getTitle.bind(this);
		this.onSelect = this.onSelect.bind(this);
	}

	componentDidMount() {
		this.props.setVal("selectedTimeInterval", {start: moment().subtract(1, "days").valueOf(), end: moment().valueOf(), code: "day"});
	}

	getTitle({selectedTimeInterval, lang}) {
		return (selectedTimeInterval.code && CODE_TRANSLATIONS[selectedTimeInterval.code] ? getStringFromLang(CODE_TRANSLATIONS[selectedTimeInterval.code], lang) : "error" );
	}

	getInterval(code) {
		switch(code) {
			case "week":
				return {start: moment().subtract(1, "week").valueOf(), end: moment().valueOf(), code: "week"};
			case "2_weeks":
				return {start: moment().subtract(2, "weeks").valueOf(), end: moment().valueOf(), code: "2_weeks"};
			case "month":
				return {start: moment().subtract(1, "month").valueOf(), end: moment().valueOf(), code: "month"};
			case "3_months":
				return {start: moment().subtract(3, "month").valueOf(), end: moment().valueOf(), code: "3_months"};
			case "ytd":
				return {start: moment().startOf("year").valueOf(), end: moment().valueOf(), code: "ytd"};
			case "year":
				return {start: moment().subtract(1, "year").valueOf(), end: moment().valueOf(), code: "year"};
			default:
				return {start: moment().subtract(1, "days").valueOf(), end: moment().valueOf(), code: "day"};
		}
	}

	onSelect(code) {
		this.props.setVal("selectedTimeInterval", this.getInterval(code));	
	}

	getItems() {
		return Object.keys(CODE_TRANSLATIONS).map(key => {
			return <Dropdown.Item eventKey={key} key={"time-selector-item_" + key}>{CODE_TRANSLATIONS[key]}</Dropdown.Item>
		});
	}

	render() {
		return (
			<div className="time-selector">
				<DropdownButton
					className="time-dropdown"
					title={this.getTitle(this.props)}
					variant={"secondary"}
					id={"time_select"}
					key={"time-select"}
					onSelect={this.onSelect}
				>
					{this.getItems()}
				</DropdownButton>
			</div>
		);
	};
}


function mapStateToProps(state) {
	const { selectedTimeInterval, lang } = state;
	return { selectedTimeInterval, lang };
}

const mapDispatchToProps = (dispatch) => {
	return {
		setVal: (prop, val) => dispatch({type: "SET_VAL", prop, val}),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeSelector);